.sticky-sherpa-banner-container-mini {
  background-color: #f4f9f8;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  .sticky-sherpa-banner {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 20px;
    }
    &-image {
      margin-right: 10px;
      .pws-profile-img {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 3px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 4px 0 rgba(143, 143, 143, 0.5);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-clip: content-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #173e35;
        text-transform: uppercase;
      }
    }
    &-description {
      font-family: 'Gilmer-Medium';
      font-size: 11px;
      line-height: 24px;
      color: #2e2e2e;
      margin-right: 5px;
      .name {
        color: #173e35;
        font-family: 'Gilmer-Medium';
        font-size: 11px;
        line-height: 24px;
        text-decoration: underline;
        cursor: pointer;
        display: inline;
        margin-left: 5px;
      }
    }
  }
}
.sticky-sherpa-banner-box.sticky {
  position: fixed;
  top: 0;
  z-index: 1999;
  width: 100%;
}
.sticky-sherpa-banner-container-mini.hidden {
  display: none;
}
.sticky-sherpa-banner-container {
  background-color: #f4f9f8;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  .sticky-sherpa-banner {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    position: relative;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 20px;
      align-items: start;
    }
    .shop-other-site-link {
      position: absolute;
      top: 25px;
      right: 50px;
      span {
        text-decoration: underline;
        font-family: 'Gilmer-Medium';
        font-size: 12px;
        line-height: 17px;
        color: #173e35;
        margin-right: 5px;
      }
    }
    #sherpaClose {
      position: absolute;
      background: transparent;
      width: 20px;
      height: 20px;
      background-image: url('../images/close.svg');
      background-size: 100%;
      top: 25px;
      right: 0;
      border: none;
      @media screen and (max-width: 768px) {
        right: 20px;
      }
    }
    &-image {
      margin-right: 20px;
      .pws-profile-img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        border: 3px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 4px 0 rgba(143, 143, 143, 0.5);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-clip: content-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
        color: #173e35;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          font-size: 30px;
        }
      }
    }
    &-details {
      width: 100%;
      .ambassador {
        font-family: 'Gilmer-Medium';
        font-size: 10px;
        line-height: 22px;
        color: #333333;
        span {
          height: 22px;
          background-color: #e7eeee;
          display: inline-block;
          padding: 0 5px;
        }
      }
      .name {
        font-family: 'SchnyderS-Demi';
        font-size: 28px;
        line-height: 34px;
        color: #333333;
        margin: 5px 0;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 26px;
        }
      }
      .description-container {
        .description {
          font-family: 'Gilmer-Regular';
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.65px;
          color: #333333;
          width: 600px;
          display: inline;
          @media screen and (max-width: 768px) {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.49px;
            width: 100%;
          }
        }
        .sherpa-read-more,
        .sherpa-read-less {
          color: #173e35;
          font-family: 'Gilmer-Medium';
          font-size: 14px;
          text-decoration: underline;
          cursor: pointer;
          display: inline-block;
        }
      }
    }

    &-btn {
      float: right;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        float: left;
      }
      button {
        font-family: 'Gilmer-Bold';
        font-size: 12px;
        color: #2e2e2e;
        height: 32px;
        line-height: 32px;
        background-color: transparent;
        border: 1px solid #2e2e2e;
        padding: 0 15px;
        margin-left: 10px;
        text-transform: uppercase;
        border-radius: 0;
        @media screen and (max-width: 768px) {
          margin-left: 0px;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
    }
  }
}
.sticky-sherpa-banner-container.hidden {
  display: none;
}
.shk-checkbox-square .shk-checkbox-label {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  position: relative;
  vertical-align: middle;
  margin-left: 9px;
  display: inline-block;
}
.shk-green-checkbox input:checked + .shk-checkbox-label::before {
  background-color: #173e35;
}
.shk-checkbox-square .shk-checkbox-label::before {
  content: '';
  display: block;
  width: 8px;
  height: 5px;
  border-width: 0;
  border-style: solid;
  border-color: #fff;
  border-left-width: 2px;
  border-bottom-width: 2px;
  margin-top: 3px;
  margin-left: 3px;
  transform: rotate(-45deg);
}
.shk-green-checkbox.shk-checkbox-square input:checked + .shk-checkbox-label {
  border-color: #173e35;
  background-color: #173e35;
}
.shk-checkbox-square input {
  position: absolute;
  left: -9999px;
}

.pws-contact-me-popup {
  .modal-dialog {
    padding: 10px;
    .modal-content {
      height: auto;
      margin: auto;
      width: 886px;
      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
      .modal-body {
        overflow: auto;
      }
    }
  }

  .has-error .help-block {
    font-weight: 600;
    color: #ff0000;
    font-family: 'Gilmer-Regular';
    font-size: 12px;
    line-height: 15px;
  }
  #cboxLoadedContent {
    margin-top: 0;
  }
  #cboxLoadedContent {
    padding: 0;
  }
  #cboxTitle {
    display: none;
  }
  #cboxClose {
    width: 25px;
    height: 25px;
    background-image: url('../images/close.svg');
    background-size: 100%;
    top: 25px;
    right: 25px;
    span {
      display: none;
    }
  }
  .popup-contact-me {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &-left {
      width: 322px;
      background: #e7eeee;
      padding-bottom: 20px;
      @media (max-width: 768px) {
        width: 100%;
      }
      &-header {
        font-family: 'SchnyderS-Demi';
        font-size: 36px;
        text-align: center;
        padding: 20px;
        margin-top: 30px;
      }
      &-image {
        display: flex;
        justify-content: center;
        .pws-profile-img {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          border: 3px solid rgba(255, 255, 255, 0.2);
          box-shadow: 0 0 4px 0 rgba(143, 143, 143, 0.5);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          background-clip: content-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 50px;
          color: #173e35;
          text-transform: uppercase;
        }
      }
      &-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        .social-link-list {
          max-width: 171px;
          &-item {
            margin: 0 4px;
          }
        }
        .wg-content {
          line-height: initial;
          text-align: center;
          .wg-link {
            display: block;
          }
        }
        .ambassador {
          font-family: 'Gilmer Medium';
          font-size: 10px;
          line-height: 22px;
          color: #333333;
          span {
            height: 22px;
            background-color: #e7eeee;
            display: inline-block;
            padding: 0 5px;
          }
        }
        .name {
          font-family: 'SchnyderS-Demi';
          font-size: 28px;
          line-height: 34px;
          color: #333333;
          margin: 5px 0;
        }
        .address,
        .phone {
          font-family: 'Gilmer-Regular';
          font-size: 12px;
          line-height: 25px;
          letter-spacing: 0.49px;
          color: #333333;
          img {
            margin-right: 5px;
          }
        }
      }

      &-btn {
        display: flex;
        justify-content: center;
        button {
          font-family: 'Gilmer-Bold';
          font-size: 12px;
          color: #2e2e2e;
          width: 214px;
          height: 38px;
          line-height: 38px;
          background-color: transparent;
          border: 1px solid #2e2e2e;
          padding: 0 20px;
          margin: 25px auto 0 auto;
          border-radius: 0;
          text-transform: uppercase;
        }
      }
    }
    &-left.full-width {
      background: #ffffff;
      width: 100%;
    }
    &-right {
      width: calc(100% - 322px);
      padding: 60px 20px 20px 20px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .form-control::placeholder {
        color: #333333;
        font-size: 14px;
        font-family: 'Gilmer-Regular';
        opacity: 0.7;
      }
      &-message {
        font-family: 'Gilmer-Regular';
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.81px;
        color: #333333;
      }
      &-name {
        font-family: 'Gilmer-Regular';
        font-size: 14px;
        line-height: 33px;
        letter-spacing: 0.56px;
        margin: 10px 0;
        text-transform: capitalize;
        color: #333333;
      }
      .pws-basic-form-section {
        .pws-error-wrap,
        .pws-method-error-wrap {
          font-weight: 600;
          color: #ff0000;
          font-family: 'Gilmer-Regular';
          font-size: 12px;
          line-height: 15px;
        }
        .pws-error-wrap {
          display: block;
          margin-top: 5px;
          margin-bottom: 10px;
        }
        .pws-interest-select.default {
          .select-wrap {
            .select-text {
              opacity: 0.7;
            }
          }
        }
        .pws-interest-select {
          .option-wrap {
            .option-item.selected {
              background-color: #173e35 !important;
              color: #ffffff;
            }
          }
          .select-wrap {
            padding: 10px;
            border: 1px solid #ced4da;
          }
          color: #333333;
          font-size: 14px;
          font-family: 'Gilmer-Regular';
          border-radius: 0.25rem;
        }
        .contact-me-name-container {
          width: 234px;
        }
        .contact-me-name-container:nth-child(3) {
          margin-left: 10px;
        }
        .contact-me-popup-btn {
          color: #ffffff;
          background-color: #173e35;
          border-color: #173e35;
          border-width: 1px;
          font-family: 'Gilmer-Bold';
          font-size: 12px;
          line-height: 37.5px;
          letter-spacing: 0.55px;
          float: right;
          padding: 0 20px;
        }
        .contact-me-popup-required {
          color: #515151;
          font-family: 'Gilmer-Regular';
          font-size: 12px;
          letter-spacing: 0.55px;
          float: left;
          position: relative;
          top: 20px;
        }
        .form-group.contact-me-name {
          flex-direction: row !important;
        }
        .form-group {
          display: flex;
          @media (max-width: 768px) {
            flex-direction: column;
          }
          .input-with-count-limit {
            width: 100%;
            textarea.form-control {
              min-height: auto;
              resize: none;
              box-shadow: none;
            }
          }
          .contact-me-interest-container {
            width: 100%;
            .select2-container {
              .selection {
                width: 100%;
              }
            }
          }
          .contact-me-mobile-container,
          .contact-me-email-container,
          .pwp-contact-me-method-container {
            width: 100%;
          }
          .pwp-contact-me-method-container {
            display: flex;
            flex-direction: column;
            .checkbox-container {
              display: flex;
            }
            .pws-method-error-wrap {
              position: relative;
              top: -10px;
            }
          }

          label {
            white-space: nowrap;
            line-height: 40px;
            text-transform: initial;
            margin-right: 10px;
            color: #333333;
            font-family: 'Gilmer-Regular';
            font-size: 14px;
          }
        }
      }
    }
  }
}

.social-link-list {
  @media screen and (min-width: 768px) {
    float: left;
  }
  &-item {
    margin-right: 8px;
    line-height: 48px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.input-with-count-limit {
  position: relative;
  .count-limit {
    position: absolute;
    right: 10px;
    bottom: 13px;
    opacity: 0.84;
    color: #333333;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.41px;
    line-height: 19px;

    &.count-max {
      color: #f00;
    }

    &.pws-personalize-message-counter {
      bottom: 6px;
    }
  }
}
.wg-content {
  @media (min-width: 768px) {
    float: left;
    line-height: 48px;
  }
  font-weight: bold;
  display: inline-block;
  color: #173e35;
  font-family: Gilmer-Bold;
  font-size: 12px;
  letter-spacing: 0;
  .wg-link {
    text-decoration: underline;
    color: #173e35;
    font-family: Gilmer-Bold;
    font-size: 12px;
    cursor: pointer;
  }
  .wg-word {
    white-space: nowrap;
  }
}
