@import 'Variables';

html,
body,
#root,
.container-fluid,
.fluid-container {
  height: 100%;

  &>div {
    height: 100%;
  }
}

body {
  margin: 20px;
  color: $black;
}

.ha {
  display: none;
}

.animate-enter {
  opacity: 0.01;
  transform: translate(100%, 0);
}

.animate-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 500ms ease-in;
}

.animate-exit {
  position: absolute;
  left: 0;
  opacity: 0.01;
  transform: translate(0, 0);
}

.animate-exit-active {
  opacity: 0.2;
  transform: translate(-100%, 0);
  transition: transform 500ms ease-in;
}

.back {
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  pointer-events: none;
  height: 95px;
  padding: 0 40px;
  width: 100vw;
  margin: 0 auto;
  flex-shrink: 0;

  &.inherit-back {
    background: none;
    pointer-events: none;
  }

  &.inherit-back.kids {
    background: inherit !important;
  }

  &.kids {
    .brand {
      height: 70px;
      margin: 5px 40px 0 0;
      width: 170px;
    }
  }

  .brand {
    width: 132px;
    height: 32px;
  }

  .btn-back {
    padding: 0 !important;
    background: none;
    border: none;
    box-shadow: none;
    height: 30px;
    outline: none;
    pointer-events: auto;

    &:focus {
      border: 2px solid #000;
    }

    @include focus-indicator();
    img {
      width: 32px;
      height: 27px;
    }
  }
}

.quiz-page {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.trans-group > div:first-of-type {
    height: 100%;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .page {
    position: relative;
    height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    padding-bottom: 138px;
    align-items: center;

    .loading-header {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      &.prenatal,
      &.kids {
        img {
          width: 140px;
        }
      }
      &.prenatal {
        padding-top: 20px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .questionnaire {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 1020px;
      padding-bottom: 40px;

      .answer-wrap {
        >.answer {
          &:first-child {
            .error-message {
              pointer-events: inherit !important;
            }
          }
        }
      }

      &.bp_reading {
        .question {
          width: 100%;
        }

        .answer-wrap {
          >.answer {
            .error-message {
              @include errorMessage;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: 38px;
              width: 100%;
              pointer-events: none;

              .arrow {
                @include errorMessageUpArrow;
              }
            }
          }
        }
      }

      &.lab_results {
        .question {
          width: 100%;
        }

        .answer-wrap {
          width: 100%;
          max-width: 800px;

          .label {
            position: relative;
            width: 275px;
            font-size: 24px;
            font-family: SuisseIntl-Light, sans-serif;
            font-weight: 700;

            .q-tooltip {
              height: 30px !important;
              width: 15px;
              font-size: 0;
              padding: 0;
              margin: 4px 0 0 2px;
              position: relative;
            }
          }

          >.answer {
            margin: 0 auto;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: flex-end;
            padding: 40px 0 0 0;

            .error-message {
              @include errorMessage;
              position: absolute;
              left: 32%;
              transform: translateX(-50%);
              top: 49px;
              width: 100%;
              max-width: 230px;
              pointer-events: none;
              z-index: 1;

              .arrow {
                @include errorMessageUpArrow;
                top: -16px;
                height: 16px;
                width: 16px;
                transform: translate(-50%, 50%) rotate(-46deg);
              }
            }

            >.answer {
              margin: 0;
              padding: 0;
              position: relative;

              &:nth-child(1) {
                position: relative;
                flex: 1;
                margin-right: 35px;
                height: 50px;

                .text {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  border-bottom: 1px solid #000;
                  display: flex;
                  align-items: center;
                  font-size: 24px;
                  font-family: SuisseIntl-Light, sans-serif;
                  cursor: text;
                  letter-spacing: 0.5px;
                  color: #765d57;

                  span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }

                  em {
                    color: #000;
                    font-style: normal;
                    margin-right: 5px;
                  }
                }
              }

              .text-field {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0 40px 0 0;
                border-bottom-width: 1.5px;

                &::placeholder {
                  color: #765d57;
                }

                &.hide {
                  opacity: 0;
                }
              }

              .dropdown {
                margin: 0;
                max-width: 200px;

                &-toggle {
                  color: #765d57;
                  font-size: 24px;
                  min-width: fit-content;

                  &.has-unit {
                    color: #000;
                    border-bottom-width: 1.5px;
                  }
                }
              }
            }

            &:last-child {
              >.answer {
                &:last-child {
                  width: 100%;
                  max-width: 200px;
                }
              }
            }
          }
        }
      }

      &.core_measurement {
        .label {
          margin: 65px 0 0;
          width: 270px;
          font-size: 24px;
          font-family: SuisseIntl-Light, sans-serif;
          font-weight: 700;
        }

        .answer {
          .text-field {
            margin: 57px 0 0 0;
            width: 320px;
            border-bottom-width: 1.5px;
          }

          .dropdown {
            width: 215px;

            &-toggle {
              color: #765d57;
              font-size: 24px;

              &.has-unit {
                color: #000;
                border-bottom-width: 1.5px;
              }
            }
          }
        }
      }

      &.kids_info {
        .answer.flex-start {
          justify-content: flex-start !important;
        }
      }

      &.kids_info+.button-container {
        position: fixed !important;
      }

      &.height_and_weight {
        .answer {
          .dropdown {
            &-toggle {
              font-size: 24px;
            }
          }
        }
      }

      .input-icon-add-btn {
        padding: 0;
        text-decoration: underline;
        font-size: 20px;
        margin: 20px auto;
        display: block;

        &:focus-visible {
          @include focus-indicator();
        } 
      }
    }

    .questionnaire.kids_info {
      padding-bottom: 80px;
    }

    .question {
      width: 900px;
      margin: 0 auto 15px auto;

      .page-title,
      .question-title {
        font-size: 36px;
        white-space: pre-line;
        line-height: 46px;
        text-align: center;
        margin-bottom: 25px;
      }

      .question-sub-title {
        opacity: 0.5;
        font-family: $font_suisse_regular;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        color: $black;
        max-width: 800px !important;
      }

      .question-check {
        font-family: $font_suisse_book;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        margin-top: 30px;
      }

      .modal-text {
        font-family: $font_suisse_regular;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        margin-top: 30px;
        text-decoration: underline;

        &-modal {
          cursor: pointer;
          width: fit-content;
          margin: 0 auto;
        }
      }
    }

    input.text-field {
      @include textFieldDesktop;

      &:focus {
        box-shadow: none;
      }
    }

    .btn-next {
      padding: 20px 0 45px;
      border-radius: 0;
      max-width: 100%;
      pointer-events: auto;
    }

    .radio,
    .check-box {
      display: none;
    }

    .round-label {
      display: inline-block;
      line-height: 20px;
      margin: 20px 0;

      &.col-lg-6 {
        max-width: 360px;
      }

      &.col-lg-7 {
        max-width: 450px;
        display: table-row;
      }

      span.label {
        font-family: $font_suisse_book;
        font-size: 16px;
        cursor: pointer;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        text-align: left;
        width: fit-content;
        position: relative;
        align-items: center;
        padding-left: 45px;
        min-height: 45px;
      }

      span.label:before,
      span.label:after {
        content: '';
        display: block;
        flex: 0 0 41px;
        width: 41px;
        height: 41px;
        position: absolute;
        left: 0;
        top: 0;
      }

      span.label:before {
        background: url(https://images.shaklee.com/meology/icons/small_oval.svg) no-repeat 1px 7px;
      }

      span.label:after {
        background: url(https://images.shaklee.com/meology/icons/white_oval.svg) no-repeat 0 5px;
        transition: 200ms;
        opacity: 0;
      }

      input:checked+span.label:after {
        opacity: 1;
      }
    }

    .dropdown {
      margin: 60px 40px 0 40px;
      width: 250px;

      .dropdown-menu {
        width: 100%;
        min-width: inherit;
      }

      button {
        background: url(https://images.shaklee.com/meology/icons/mobile/down_arrow.png) no-repeat right;
        padding: 5px 25px 5px 5px;
        font-family: SuisseIntl-Light, sans-serif;
        width: 100%;
        text-align: left;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px $black;
        outline: none;
        color: $black;

        &,
        &.active:focus {
          box-shadow: none;
        }

        &:after {
          display: none;
        }

        &:active {
          background-color: transparent;
          border-color: black;
          color: black;
        }
      }
    }

    .answer {
      justify-content: center;
      margin: 0 auto;

      &.height_and_weight {
        margin: 0 160px;
        justify-content: left;

        .text-field {
          width: 250px;
        }
      }

      .file-drop {
        width: 100%;
      }

      .upload-drag {
        border: 1px dashed $black;
        padding: 0 5%;
        max-width: 400px;

        .upload-container {
          text-align: center;
          padding: 70px 0;

          &-input {
            display: none;
          }

          .upload-btn {
            all: unset;
            margin: 35px auto;
            width: 175px;
            height: 50px;
            border: 2px solid $black;
            border-radius: 2px;
            padding: 0;
            font-size: 18px;
            cursor: pointer;
            line-height: 50px;

            &:disabled {
              color: $grey;
              border-color: $grey;
            }
          }

          .tip {
            color: #3e3e3e;
            line-height: 28px;
          }

          .upload-file-container {
            position: relative;

            .error-message {
              @include errorMessage;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: 80px;
              width: 100%;

              .arrow {
                @include errorMessageUpArrow;
              }
            }

            .upload-file {
              padding: 15px;
              height: 75px;
              border-radius: 2px;
              box-shadow: 0 1px 4px 0 #c1958b;
              display: flex;
              align-items: center;
              justify-content: space-between;

              div {
                margin: 0;
              }

              &-left {
                display: flex;
                align-items: center;
                padding-right: 15px;
                flex: 1;
                min-width: 0;

                .information {
                  flex: 1;
                  min-width: 0;
                  padding-left: 20px;
                  text-align: left;

                  .name,
                  .size {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .loader {
                  border-radius: 50%;
                  width: 41px;
                  height: 41px;
                  text-indent: -9999em;
                  border-top: 0.3em solid rgba(255, 255, 255, 0.2);
                  border-right: 0.3em solid rgba(255, 255, 255, 0.2);
                  border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
                  border-left: 0.3em solid #000000;
                  -webkit-transform: translateZ(0);
                  -ms-transform: translateZ(0);
                  transform: translateZ(0);
                  -webkit-animation: load8 1.1s infinite linear;
                  animation: load8 1.1s infinite linear;

                  @keyframes load8 {
                    0% {
                      transform: rotate(0deg);
                    }

                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }
              }

              &-right {
                .close {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .btn-container {
        margin: 0 30px;

        button {
          width: 11em;
          border-radius: 104px;
          height: 11em;
          border: solid 1px $black;
          display: flex;
          padding: 0;
          justify-content: center;
          align-items: center;
          background: transparent;
          color: $black;

          &.selected {
            background: $white;
            border-width: 2px;
            color: $black;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      &.checkbox-container,
      &.radio-container {

        //margin-left: calc(48%);
        //transform: translateX(-40%);
        &.options-3 {
          width: 360px;
        }

        &.options-4-plus {
          margin-left: 65px;
        }

        &.options-tiny {
          margin-left: 140px;
        }

        &.health_goals_priority label {
          max-width: 240px;
        }

        &.healthy_fats label {
          max-width: 255px;
        }

        .round-label {
          .text {
            display: inline-block;
            vertical-align: middle;
            width: fit-content;
            min-width: 85px;
          }

          &.col-lg-6 {
            max-width: 325px;
            width: 325px;
            margin-left: 50px;
          }

          &.col-lg-4 {
            display: inline-block;
            line-height: 20px;
            max-width: 27%;
            width: 27%;
            margin: 18px 30px 18px 25px;
          }

          &.col-lg-8 {
            max-width: 350px;
            width: 350px !important;
            margin: 18px 50%;
            transform: translate(-8%);
          }
        }

        .margin-3 {
          margin-left: 100px;
        }
      }

      &.healthcare_concern,
      &.medications,
      &.supplements_goals {
        justify-content: flex-start;
      }
    }

    .button-container {
      display: flex;
      /*position: fixed;*/
      bottom: 0;
      right: 0;
      left: 0;
      align-self: center;
      height: 150px;
      justify-content: center;
      text-align: center;

      .question-buttons {
        margin-top: 15px;

        .btn-next {
          background: transparent;
          padding: 14px 0;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
          outline: none;
          width: 358px;
          border: 2px solid $black;
          border-radius: 2px;
          display: block;
          height: 58px;
          color: $black;
          &:focus {
            outline: blue solid 2px;
            border-color: 2px solid #fff;
          }

          &:disabled {
            color: $grey;
            border-color: $grey;
          }
        }

        .btn-skip {
          border: none;
          background-color: transparent;
          font-size: 20px;
          font-weight: bold;
          margin-top: 12px;
          padding: 0;
          color: $black;
          visibility: hidden;

          &.show-skip {
            visibility: visible;
          }
        }
      }
    }

    .transition {
      @include hide-scrollbar;
      background: $white;
      flex: 1;
      overflow-y: auto;
      width: 100%;

      .content {
        padding: 10% 10% 0;
        height: 100%;
        overflow-y: auto;

        &.transition_basics,
        &.prenatal_transition_basics {
          height: 100%;
        }

        .desc {
          height: auto;
          overflow: hidden;

          h2 {
            font-family: $font_suisse_book;
            font-size: 28px;
            color: $title;
            margin: 0;
            word-wrap: break-word;
          }

          p {
            font-family: $font_suisse_light;
            font-size: 18px;
            color: $black;
            letter-spacing: 0.5px;
            line-height: 30px;
            margin: 22px 0 32px;
          }

          .first-transition-privacy {
            color: $black;
            font-family: $font_suisse_book;
            font-size: 14px;
            line-height: 18px;

            a {
              text-decoration: underline;
              color: $black;
            }
          }
        }

        .button-container {
          bottom: 0;
          left: 0;
          position: relative;
          background: linear-gradient(to top,
              #fff 80%,
              rgba(0, 0, 0, 0) 66%) !important;
          height: auto;
          justify-content: left;
          margin-top: 15px;

          .question-buttons {
            margin: 0;
          }
        }

        &.transition_to_regular {
          .button-container {
            margin: 15px 0 0 0;
            align-self: flex-start;
          }
        }

        &.transition_optional_dna {
          overflow: scroll;
          height: 100%;

          .btn-skip-section {
            border: none;
            background-color: transparent;
            font-size: 20px;
            font-weight: bold;
            margin: 12px 0 25px 26%;
            padding: 0 10%;
            color: $black;
          }
        }
      }

      .image {
        padding: 0;

        .banner {
          flex: 1;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        &.transition_optional_dna {
          .banner {
            &.desktop {
              background-position: 100% 100%;
            }
          }
        }
        &.transition_to_prenatal > div {
          background-position: center bottom;
        }
      }

      .summary-link {
        margin: 25px 0 37px 0;

        a,
        span {
          text-decoration: none;
          font-family: $font_suisse_medium;
          font-size: 16px;
          text-align: left;
          color: $black;
          border-bottom: solid 1px #000;
          padding-bottom: 7px;
          cursor: pointer;
        }
      }

      // The interstitial page of transition to Meology Prenatal
      .transition_to_prenatal {
        .btn-next {
          all: unset;
          margin: 0;
          cursor: pointer;
          border-bottom: 1px solid #000;
          margin-top: 16px;
          padding-bottom: 0 !important;
          width: fit-content !important;
          display: inline-block;

          &:hover {
            border-bottom: 0;
          }
        }
      }
    }

    &.is-transition-page {
      padding-bottom: 0;

      .back {
        position: relative;
        z-index: 10;
        margin-bottom: -95px;
      }
    }

  }

  .email {
    height: 100%;
    .content {
      padding: 6% 10% 0;
      height: 100%;
      overflow: auto;

      .question {
        padding: 0;
        margin: 0;
      }

      .question-title {
        font-family: $font_suisse_book;
        font-size: 32px;
        line-height: 40px;
      }

      .question-sub-title {
        font-family: $font_suisse_light;
        font-size: 18px;
        color: $black;
        letter-spacing: 0.5px;
        line-height: 30px;
        margin: 20px 0 35px 0;
      }

      .answer {
        margin-top: 26px;
        input.text-field {
          @include textFieldDesktop;
          margin-top: 0;
          padding: 20px 0 20px 15px;
          box-shadow: none;
        }
      }

      .question-buttons {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .btn-get-results {
          font-size: 20px;
          text-align: center;
          width: 100%;
          padding: 15px;
          margin-top: 50px;
          border: 2px solid $black;
          background: none;
          border-radius: 2px;
          height: 58px;

          &.disabled-btn {
            border-color: $very_light_grey;
            pointer-events: none;
          }
          &:focus {
            border: 5px solid blue;
          }
        }

        .error-message {
          @include errorMessage;
          position: absolute;

          .arrow {
            @include errorMessageUpArrow;
          }
        }
      }

      .email-terms {
        font-family: $font_suisse_light;
        font-size: 10px;
        text-align: left;
        line-height: 18px;
        opacity: 0.5;
        margin: 10px 0 0;
        z-index: 0;
      }

      .sign-in-link {
        margin: 30px 0 10px 0;
        font-family: $font_suisse_book;
        font-size: 20px;

        a {
          color: $black;
          text-decoration: underline;
          margin-left: 4px;
        }
      }

      .we-understand {
        margin: 26px 0;
        font-family: Gilmer-Medium;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.67;
        letter-spacing: 0.5px;
        color: #000;
      }
    }

    .image {
      padding: 0;

      .banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &.prenatal-trying {
          background-position: 100% 100%;
        }

        &.prenatal-pregnant,
        &.prenatal-postpartum {
          background-position: 0 0;
        }
      }
    }
  }
  #email-capture-intro a{
	color: black;
	text-decoration: underline;
  }
  .bottom-container {
    .question-progress {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      pointer-events: none;
      z-index: 2;

      .percentage {
        padding: 5px 0;
        font-family: $font_suisse_book;
        font-size: 14px;
        color: #000000;
        letter-spacing: 1px;
      }

      .progress {
        background-color: $white;
        height: 6px;
        border-radius: 0;
      }

      .progress-bar {
        background-color: $black;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        margin: 0 !important;
      }
    }
  }

  .question:nth-last-child {
    border-right: none;
  }

  .loading {
    height: 100%;
    align-items: center;

    .progress {
      width: 600px;
      height: 600px;
      line-height: 300px;
      background: none;
      position: relative;
      overflow: inherit;

      .sun {
        position: absolute;
        top: 50%;
        left: 50%;

        .sun-light {
          height: 2px;
          display: block;
          position: absolute;
          background: $white;
          transform-origin: left center;
        }
      }

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid $very_light_grey;
        position: absolute;
        top: 0;
        left: 0;
      }

      &>span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
      }

      .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 2px;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: $black;
      }

      .progress-left {
        left: 0;

        .progress-bar {
          left: 100%;
          border-top-right-radius: 300px;
          border-bottom-right-radius: 300px;
          border-left: 0;
          transform-origin: center left;
          animation: loading-2 3s linear forwards 1.8s;
        }
      }

      .progress-right {
        right: 0;

        .progress-bar {
          left: -100%;
          border-top-left-radius: 300px;
          border-bottom-left-radius: 300px;
          border-right: 0;
          transform-origin: center right;
          animation: loading-1 1.8s linear forwards;
        }
      }

      .progress-value {
        width: 85%;
        display: flex;
        font-size: 32px;
        line-height: 44px;
        text-align: center;
        flex-direction: column;
        align-self: center;
        font-family: $font_suisse_book;
        margin: 0 auto;

        .lighter {
          font-family: $font_suisse_light;
          font-size: 18px;
          color: $very_light_grey;
          padding-top: 20px;
        }
      }
    }

    .kids_email.progress {
      &::after {
        border: 2px solid $white;
      }

      .progress-value {
        max-width: 315px;

        span {
          &:first-child {
            margin-bottom: 30px;
          }
        }
      }
    }

    @keyframes loading-1 {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes loading-2 {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(180deg);
      }
    }
  }

  &-opt-in {
    margin-top: 18px;
  }

  &.prenatal {
    .page {
      padding: 0;
    }
  }

  &.email-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;

    .back {
      margin-bottom: -95px;
      z-index: 10;
    }

    .email {
      @include hide-scrollbar;
      flex: 1;
      overflow-y: auto;
      width: 100%;

      .content {
        overflow-y: auto;
      }
    }
  }

  &.kids_email {
    .page {
      padding-bottom: 0;

      .loading-header {
        &.kids {
          position: absolute;
          right: 20px;
        }
      }
    }
  }

  .managing-wrap {
    z-index: 10;
    position: relative;
  }
}

.nextBtn-container {
  position: relative;

  .error-message {
    @include errorMessage;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 62px;

    .arrow {
      @include errorMessageUpArrow;
    }
  }
}

/* Begin SummaryPage styling for Desktop and Larger Screen of width > 768px */
.summary {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  .row {
    margin: 0;
  }

  .col {
    padding: 0;
  }

  .header {
    background-color: $light_brown;

    .back.inherit-back {
      background: none;
      display: flex;
      align-items: center;
      padding: 20px;
    }

    .btn-back,
    .brand {
      margin: 0;
    }

    h1 {
      text-align: left;
      font-size: 36px;
      margin: 53px 0 0 15%;
    }

    p {
      font-family: $font_suisse_light;
      letter-spacing: 1.5px;
      text-align: left;
      font-size: 18px;
      line-height: 26px;
      margin: 23px 15% 55px 15%;
    }
  }

  /* End header */

  .content {
    padding-right: 172px;
    padding-left: 172px;

    @supports (padding-right: unquote('max(172px, 15%)')) {
      padding-right: unquote('max(172px, 15%)');
      padding-left: unquote('max(172px, 15%)');
    }

    >div {
      border-bottom: 1px solid $very_light_grey;
    }

    .btn-toolbar {
      border: none;
      padding: 25px 0;
      justify-content: center;
    }
  }

  .questions {
    color: $light_grey;
    font-family: $font_suisse_light;
    font-size: 16px;
    padding: 26px 0 0 0;
  }

  .answers {
    font-size: 24px;
    padding: 10px 0 26px 0;
    display: flex;
    justify-content: space-between;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  .pencil-icon {
    height: 36px;
    width: 36px;
    background-image: url(https://images.shaklee.com/meology/icons/edit.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: 0;
  }

  .see-results-btn.btn-light {
    border: 2px solid $black;
    font-family: $font_suisse_book;
    font-size: 16px;
    height: 58px;
    text-align: center;
    width: 358px;
    border-radius: 0;
    background: transparent;

    &:hover,
    &:active,
    &:focus {
      background: none;
      box-shadow: none;
      border-color: inherit;
    }
  }
}

/* End Summary Page Style */
.edit-question {
  .modal-dialog {
    height: auto;
  }

  .modal-content {
    height: auto;
    padding: 75px 0;

    .modal-header {
      padding-right: 0;
      top: 0;
      right: 1.5rem;
    }
  }

  .modal-body {
    .quiz-page {
      overflow: auto;

      .page {
        padding-bottom: 0;
        height: 100%;

        .questionnaire {
          align-self: flex-start;
          padding-bottom: 60px;
          margin: 0 auto;

          .question {
            margin-bottom: 10px;
          }

          &.core_measurement {
            .answer-wrap {
              .label {
                width: 245px;
              }
            }
          }

          &.lab_results {
            .answer-wrap {
              padding: 0;
            }
          }

          .answer-wrap {
            >.answer {
              .text-field {
                &::placeholder {
                  color: #757575;
                }
              }

              >.answer:nth-child(1) {
                .text {
                  color: #757575;
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-footer {
    padding: 10px 0 !important;

    .btn-save {
      margin-top: 0;

      &:disabled {
        border-color: $grey;
        color: $grey;
      }
    }

    .error-message {
      @include errorMessage;

      .arrow {
        @include errorMessageUpArrow;
      }
    }
  }
}

// Instructions Modal
.instructions-modal {
  &.reviewPage {
    background-color: #f2ebe0;
  }

  .modal-dialog {
    height: auto;
  }

  .modal-content {
    max-width: fit-content;
    padding: 0 100px;
    margin: 0 auto;
    height: auto;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
  }

  .modal-body-title {
    text-align: center;
    font-size: 30px;
    padding-bottom: 20px;
  }

  .modal-body-text {
    max-width: 550px;
    text-align: center;
    font-size: 14px;
    padding-bottom: 50px;

    ol li {
      padding: 0 0 5px 0;
    }
  }

  &.dna_id .modal-body-text {
    text-align: left;
  }

  &.core_measurement {
    .modal-body-text {
      img {
        display: block;
        margin: 40px auto 20px;
        width: 400px;
        max-width: 100%;
      }
    }
  }

  &-back {
    all: unset;
    padding: 10px;
    border: 2px solid #000;
    font-size: 16px;
    color: #000;
    text-transform: capitalize;
    min-width: 200px;
    text-align: center;

    @include focus-indicator();
  }

  &-back.disabled {
    color: #888888;
    border-color: #888888;
  }
}

// Meology Kids answer

.input-icon-add-btn {
  all: unset;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }

  @include focus-indicator();
}

.kids-answer {
  $root: &;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 940px;

  .error-message {
    @include errorMessage;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% - 55px);
    width: 120%;
    pointer-events: none;

    .arrow {
      @include errorMessageUpArrow;
    }
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px !important;
    width: 215px;
    height: 280px;
    border: solid black 2px;
    border-radius: 15px;
    padding: 28px;
    position: relative;

    .kids-answer-indicator {
      transform: translate(-25%, -25%);
    }

    .error-message {
      bottom: 70px;
      width: fit-content;
    }

    &.has-indicator {
      .close {
        display: none;
      }

      #{$root}-indicator {
        visibility: visible;
      }

      #{$root}-icon {
        cursor: default;
      }
    }

    .close {
      opacity: 1;
      padding: 0 !important;
      margin: 5px;
      position: absolute;
      right: 10px;
      top: 10px;

      span {
        font-size: 0;
        background: url(https://images.shaklee.com/meology/icons/mobile/close_button.png) no-repeat;
        width: 23px;
        height: 23px;
        display: block;
      }
    }
  }

  &-indicator {
    @include kids-indicator();
    text-decoration: none;
  }

  &-icon {
    width: 154px;
    height: 154px;
    padding: 35px;
    text-align: center;
    border: solid 1px #2e2e2e;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      position: absolute;
      text-transform: capitalize;
      font-size: 20px;
    }

    img {
      display: block;
      width: 105px;
      height: 105px;
    }
  }

  &-input {
    all: unset;
    width: 100%;
    margin-top: 30px;
    text-align: center;
    border-bottom: 1px solid #000;
    font-size: 24px;

    &.spanish {
      font-size: 18px;
    }
  }
}

// Meology Kids Icons Modal
.icon-modal {
  .modal-container {
    text-align: center;
  }

  .modal-icon {
    max-width: 760px;

    .icon {
      margin: 10px;
      padding: 20px;
      width: 124px;
      height: 124px;
      cursor: pointer;
      box-shadow: 0 0 0 1px #dadada;
      border-radius: 50%;

      &.active {
        border-radius: 50%;
        box-shadow: 0 0 0 3px #173e35;
        position: relative;

        &::before {
          content: ' ';
          background-image: url('https://images.shaklee.com/meology/icons/green_check_mark.svg');
          background-size: contain;
          width: 22px;
          height: 22px;
          display: block;
          position: absolute;
          right: 4px;
          bottom: 10px;
        }
      }

      .radio {
        position: fixed;
        opacity: 0;
        pointer-events: none;

        &:checked+span.label img {
          border: solid black 2px;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .btn.cancel {
    color: $black;
    @include focus-indicator();
  }
}

// Blood Test Tooltip
.blood-test-tooltip {
  height: 0;
}

.prenatal_due_date_picker {
  .react-datepicker {
    border: 1px solid #bcb5b9;
    padding: 37px 58px 26px 56px;
    background-color: #fff;
    border-radius: 0;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__day,
    .react-datepicker__day--outside-month {
      width: 40px;
      height: 38px;
      line-height: 38px;
      font-family: Gilmer-Medium;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .react-datepicker__day {
      color: #111;
    }
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month {
      color: #e1e1e1;
    }

    .react-datepicker__week {
      padding-top: 8px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day:hover,
    .react-datepicker__day--selected {
      width: 30px;
      height: 30px;
      margin: 0 5px;
      border-radius: 20px;
      line-height: 30px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
      background-color: #2e2e2e;
      color: #fff;
      font-family: SchnyderS-Demi;
    }

    .react-datepicker__header {
      background-color: transparent;

      .react-datepicker__day-name {
        width: 40px;
        padding: 3px 0;
        opacity: 0.7;
        font-family: Gilmer-Medium;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: center;
        color: #6c6c6c;
      }

      .custom-date-picker-header {
        margin-bottom: 24px;
        white-space: nowrap;

        .react-datepicker__navigation-icon::before {
          border-color: #111;
          border-width: 2px 2px 0 0;

        }

        .react-datepicker__navigation-icon {
          top: -8px;
          font-size: 10px;
          padding: 0 6px;
          cursor: pointer;
        }

        .month-and-year {
          display: inline-block;
          width: 178px;
          height: 25px;
          margin: 0 16px;
          font-family: SFProDisplay;
          font-size: 21px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          text-align: center;
          color: #000;
        }
      }
    }

  }
  .react-datepicker-popper {
    z-index: 3;
  }
  
  .prenatal_due_date_picker_popper_container {
    width: 100%;
    .react-datepicker__tab-loop {
      position: relative;
      width: 100%;
    }
  }
}

// Calc Due Date Popup
.prenatal_due_date {
  &.modal {
    .modal-body-title {
      font-family: 'Gilmer-Regular';
      color: #2e2e2e;
      font-size: 36px;
    }

    .modal-body-text {
      font-family: 'Gilmer-Medium';
      font-size: 18px;
      margin: 0 auto;
      color: #4A4A4A;
      padding-bottom: 75px;
    }

    .modal-dialog {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .modal-content {
      padding: 0;
      height: 100%;
    }

    .modal-container {
      max-height: 100vh;
      overflow-y: scroll;

      &-content {
        max-width: 100%;
        padding: 0 20px;
      }
    }
  }
}

.due-date-step {
  margin: 0;
  padding: 0 40px;
  list-style-type: none;
  display: flex;
  font-family: 'Gilmer-Medium';
  gap: 60px;

  >li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 267px;

    >p {
      margin: 0;
      font-size: 20px;
      padding: 20px 0;
      text-align: center;
    }
  }

  &-img {
    width: 115px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .prenatal_due_date_picker {
    .react-datepicker-popper {
      width: 100%;
      max-width: 390px;
      min-width: 215px;
      z-index: 3;
    }

    .react-datepicker-popper>div {
      width: 100%;
      display: flex;
      margin: 0;

      .react-datepicker {
        width: 100%;
        margin: 0;
        padding: 5% 10%;

        .react-datepicker__triangle {
          display: none;
        }

        .react-datepicker__month-container {
          float: unset;

          .react-datepicker__header .custom-date-picker-header .month-and-year {
            height: unset;
            width: unset;
          }

          .react-datepicker__week {
            padding-top: 4%;
          }

          .react-datepicker__header .react-datepicker__day-names,
          .react-datepicker__week {
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            .react-datepicker__day-name {
              width: unset;
              padding: unset;
            }

            .react-datepicker__day:hover,
            .react-datepicker__day--selected,
            .react-datepicker__day--outside-month,
            .react-datepicker__day {
              height: 1.5rem;
              width: 1.5rem;
              line-height: 1.5rem;
            }

            .react-datepicker__day:hover,
            .react-datepicker__day--selected {
              margin: 0 auto;
            }
          }
        }

      }
    }
    .react-datepicker {
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day:hover,
      .react-datepicker__day--selected {
        margin: 0 auto;
      }
    }
  }

  .edit-question {
    .modal-dialog {
      padding: 20px;

      .quiz-page {
        .page {
          .questionnaire {
            height: 100%;
            overflow: auto;

            .question {
              max-height: fit-content;
              margin-top: 0;
            }

            .answer {
              .btn-container {
                button {
                  width: 7em;
                  height: 7em;
                }
              }

              height: 46%;
              width: 100%;
              padding: 0 5%;

              .upload-drag .upload-container {
                padding: 20px 0;

                .upload-file {
                  padding: 10px;
                  height: auto;

                  &-left {
                    .loader {
                      width: 25px;
                      height: 25px;
                    }

                    img {
                      display: block;
                      width: 25px;
                    }

                    .information {
                      padding-left: 10px;

                      .name,
                      .size {
                        font-size: 14px;
                      }
                    }
                  }

                  &-right {
                    img {
                      display: block;
                      width: 15px;
                    }
                  }
                }
              }

              &.input-unit {
                >.answer {
                  width: auto;
                }
              }
            }

            .radio-container {
              padding: 35px 30px !important;
            }

            &.core_measurement {
              .answer-wrap {
                .label {
                  width: 105px;
                }
              }
            }

            &.lab_results {
              .answer-wrap {
                .answer:last-child>.answer {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }

      .modal-footer {
        position: static;
        padding-bottom: 20px;
        left: 0;

        .btn-save {
          width: calc(100% - 80px) !important;
        }

        .btn-cancel {
          margin-bottom: 0;
        }
      }
    }
  }

  .quiz-page {
    &.noSkip {
      .page {
        padding-bottom: 50px;

        .questionnaire {
          >.answer {
            padding-bottom: 100px;
          }
        }
      }

      &.hasInput {
        .page {
          padding-bottom: 100px;

          .questionnaire {
            >.answer {
              padding-bottom: 40px;
            }
          }
        }
      }
    }

    &.hasInput {
      display: flex;
      flex-direction: column;
      padding-top: 0;

      .page {
        .questionnaire {
          .answer-wrap {
            padding-bottom: 50px;
          }

          >.answer {
            padding-bottom: 20px;
          }
        }
      }
    }

    .back {
      height: 60px;
      padding: 0 20px;

      &.inherit-back {
        background: inherit;
      }

      .btn-back {

        img {
          width: 25px;
          height: 20px;
        }
      }

      .brand {
        width: 93px;
        height: 22px;
      }
    }

    .back.kids {
      align-items: center;

      .btn-back {
        margin: 0 0 0 22px;

        img {
          width: 25px;
          height: 20px;
        }
      }

      .brand {
        margin: 0 22px 0 0;
        width: 110px;
        height: 70px;
      }
    }

    .page {
      overflow: hidden;

      .loading-header.prenatal,
      .loading-header.kids {
        img {
          width: 93px;
          height: 22px;
        }
      }
      
      .questionnaire {
        overflow-x: hidden;
        overflow-y: auto;
        padding: 5% 3%;
        flex: 1;
        display: block;

        &.kids_info {
          .answer {
            flex-direction: column;
            align-items: center;
          }
        }

        &.dna_id {
          .answer {
            margin-bottom: 150px;
          }
        }

        &.lab_results {
          .answer-wrap {
            padding: 0 30px 90px 30px;

            .label {
              font-size: 16px;

              .q-tooltip {
                margin-top: -4px;
              }
            }

            >.answer {
              >.answer {
                padding: 8px 0 0 0;

                &:nth-child(1) {
                  height: 47px;
                  margin-right: 10px;

                  .text,
                  .text-field {
                    font-size: 16px;
                    letter-spacing: 0.35px;
                    max-width: 115px;
                  }
                }

                .text-field {
                  max-width: 145px;
                  margin: 0 15px 0 0;
                  font-size: 16px;
                }

                .dropdown {
                  width: 70px;
                  min-width: 80px;

                  &-toggle {
                    font-size: 16px;
                  }
                }

                .error-message {
                  width: 230px;
                }
              }

              &:last-child {
                >.answer {
                  margin-right: 20px;

                  .text-field {
                    width: 70px;
                  }

                  &:last-child {
                    width: 0;
                  }
                }
              }
            }
          }
        }

        &.bp_reading {
          .answer {
            padding: 0;

            input.text-field {
              margin-top: 45px;
              padding: 0 5px;
            }
          }
        }

        &.height_and_weight {
          .answer {
            .dropdown {
              margin-top: 65px;

              &-toggle {
                font-size: 22px;
              }

              &-item {
                padding: 0 16%;
              }
            }

            &.height_and_weight {
              margin-top: -35px;
            }
          }
        }

        &.core_measurement {
          .label {
            width: 155px;
            font-size: 16px;
          }

          .answer {
            input.text-field {
              margin: 50px 0 0 0;
              width: 60px;
              font-size: 16px;
            }

            .dropdown {
              margin: 53px 0 0;
              width: 60px;

              .dropdown-toggle {
                font-size: 16px;
              }
            }
          }
        }
      }

      .question {
        padding: 0 30px;
        height: auto;
        overflow: visible;
        width: 100%;
        margin-bottom: 10px;

        .page-title,
        .question-title {
          font-size: 26px;
          text-align: left;
          line-height: 32px;
          margin-bottom: 15px;
        }

        .question-sub-title {
          font-size: 14px;
          text-align: left;
          line-height: 20px;
          margin-bottom: 10px;
        }

        .question-check {
          text-align: left;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .transition {
        .content {
          .desc {
            height: auto;
            text-align: left;

            h2 {
              font-size: 22px;
              line-height: 34px;
            }

            p {
              font-size: 16px;
              line-height: 24px;
            }
          }

          .button-container {
            .question-buttons {
              padding: 0;

              .btn-next {
                margin: 0 0 15px 0;
              }
            }
          }

          .summary-link {
            text-align: center;

            a {
              border: none;
            }
          }

          &.transition_optional_dna {
            margin-bottom: 5%;

            .btn-skip-section {
              margin: 0 0 10px 24%;
              font-size: 16px;
            }
          }
        }

        .image {
          min-height: 25vh;
        }
      }

      .answer {
        padding: 0 28px;
        justify-content: center;

        .kids-answer {
          max-width: 235px;
        }

        &.height_and_weight {
          margin: 0 auto;
          justify-content: center;

          .text-field {
            width: 100%;
            font-size: 22px;
          }
        }

        &.input-unit {
          justify-content: space-between;
          align-content: center;

          input.text-field {
            width: 145px;
            padding: 0;
          }

          .answer {
            padding: 0;

            .dropdown-menu {
              width: fit-content;
            }
          }
        }

        input.text-field {
          width: 100%;
          font-size: 22px;
          letter-spacing: 0.35px;
          line-height: 24px;
          padding: 0 0 0 20px;
          margin: 100px 0 0;

          &::placeholder {
            font-size: 16px;
            opacity: 0.5;
          }
        }

        .btn-container {
          padding: 10px 6px 0;
          width: 50%;
          margin: 0;

          button {
            width: 7em;
            height: 7em;
            margin: auto;
          }
        }

        .round-label {
          min-height: 50px;

          .text {
            overflow: visible;
            display: flex !important;
            align-items: center;
          }

          span.label:after {
            bottom: 5px;
            height: 44px;
          }
        }

        &.checkbox-container,
        &.radio-container {
          margin: 0;
          padding-top: 15px;
          padding-bottom: 30px;
          width: 100%;

          &.options-4-plus,
          &.options-tiny {
            margin-left: 0;
          }

          .round-label {
            margin: 0 0 15px !important;
            padding-left: 0;
            width: 100% !important;
            max-width: 100% !important;
            transform: none !important;
          }
        }

        .dropdown {
          margin: 100px 5px 0;
          width: auto;
          width: fit-content;

          button {
            font-size: 22px;
          }
        }
      }

      .button-container {
        height: auto;
        width: 100%;
        overflow: visible;
        left: 0;
        bottom: 0;
        pointer-events: none;
        position: fixed;
        z-index: 2;

        &.fix-static {
          position: static;

          .question-buttons {
            margin: 0 25px !important;
            padding: 5px 0 0 !important;
          }
        }

        .question-buttons {
          width: 100%;
          padding: 40px 0;
          margin: 0 10%;

          button {
            pointer-events: auto;
          }

          .btn-next {
            font-size: 16px;
            width: 100%;
            padding: 11px 0;
            pointer-events: auto;

            &.disabled-btn,
            &.is-scroll {
              pointer-events: none;
            }
          }

          .btn-skip {
            font-size: 16px;
            margin: 10px 0 0;
            pointer-events: auto;

            &.is-scroll {
              pointer-events: none;
            }
          }

          .btn-white {
            width: 100%;
          }
        }
      }

      &.is-transition-page {
        .back {
          margin-bottom: -60px;
        }
      }
    }

    .email {
      flex-direction: column-reverse;
      justify-content: flex-end;

      .image {
        display: none;
      }

      .content {
        padding: 75px 30px;
        margin: 0;

        .question-title {
          font-size: 26px;
        }

        .question-sub-title {
          font-size: 14px;
          line-height: 20px;
        }

        .question-buttons {
          .btn-get-results {
            white-space: nowrap;
            padding: 15px 5px;
            font-size: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .email-terms {
          font-size: 10px;
          font-family: $font_suisse_regular;
        }

        .sign-in-link {
          font-size: 16px;
        }

        .we-understand {
          margin: 19px 0;
          font-family: Gilmer-Regular;
          font-size: 14px;
          line-height: 1.43;
        }

        .answer {
          input.text-field {
            font-size: 16px;
            width: 100%;
          }
        }
      }
    }

    .bottom-container {
      margin: 0 !important;

      .question-progress {
        .percentage {
          font-size: 10px;
          text-align: center;
          margin-left: 0 !important;
        }
      }
    }

    .loading {
      .progress {
        width: 300px;
        height: 300px;
        line-height: 150px;

        .progress-left {
          .progress-bar {
            border-top-right-radius: 150px;
            border-bottom-right-radius: 150px;
          }
        }

        .progress-right {
          .progress-bar {
            border-top-left-radius: 150px;
            border-bottom-left-radius: 150px;
          }
        }

        .progress-value {
          font-size: 16px;
          line-height: 24px;

          .lighter {
            font-size: 14px;
          }
        }
      }
    }

    &.email-page {
      .back {
        margin-bottom: -60px;
      }
    }
  }

  .nextBtn-container {
    .error-message {
      padding-top: 6px;
      font-size: 11px;
      bottom: 62px;
    }
  }

  /* Begin SummaryPage for Mobile and Tablet with Screen width < 768px */
  .summary {
    .header {
      .back.inherit-back {
        background: inherit;
        z-index: 1;
      }

      .btn-back {
        img {
          width: 25px;
          height: 20px;
        }
      }

      .brand {
        width: 93px;
        height: 22px;
      }

      .summary-header {
        padding-top: 45px;

        h1 {
          font-size: 26px;
          line-height: 30px;
          margin: 42px 30px 0 30px;
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
        margin: 19px 30px 31px 30px;
      }
    }

    /* End header */

    .questions {
      font-size: 14px;
      margin-top: 26px;
      padding: 10px 0 0 0;
    }

    .answers {
      font-size: 18px;
      margin-bottom: 20px;
      padding: 10px 0 0 0;
    }

    .pencil-icon {
      height: 20px;
      padding: 0 22px 29px 0;
      width: 20px;
    }

    .content {
      padding: 0 10%;
    }
  }

  /* End Summary Page Style */

  // Meology Kids Icons Modal
  .icon-modal {
    .modal-icon {
      .icon {
        width: 68px;
        height: 68px;
        padding: 10px;

        &.active {
          box-shadow: 0 0 0 2px #173e35;

          &::before {
            width: 14px;
            height: 14px;
            bottom: 0;
            right: 8px;
          }
        }
      }
    }
  }

  .tooltip {
    z-index: 1;
  }

  // Calc Due Date Popup
  .prenatal_due_date {
    &.modal {
      .modal-body-title {
        font-size: 26px;
        line-height: 30px;
      }

      .modal-body-text {
        font-size: 14px;
        line-height: 26px;
        padding-bottom: 40px;
      }

      .modal-dialog {
        padding: 0;
      }
    }
  }

  .due-date-step {
    flex-direction: column;
    gap: 40px;
    padding: 0;

    >li {
      width: 100%;

      >p {
        padding: 10px 20px;
      }
    }
  }
}

/* ipad styles */
/* ipad portrait */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .quiz-page {
    .page {
      .answer {

        &.checkbox-container,
        .radio-container {
          height: 70%;
        }
      }

      .transition {

        .content,
        .image {
          max-width: 100%;
        }

        .content {
          &.transition_optional_dna {
            .btn-skip-section {
              margin: 0 0 0 38%;
            }
          }
        }
      }
    }
  }
}

/* ipad landscape */
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .quiz-page {
    .page {
      .round-label {
        margin: 10px 0;
      }

      .answer {

        &.radio-container,
        &.checkbox-container {
          width: 100%;
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  .quiz-page {
    .page {
      .questionnaire {
        width: 100%;

        .question {
          width: 100%;
        }

        .answer {
          .round-label {
            width: 40%;
            min-width: 40%;
          }
        }
      }

      .transition {
        flex-direction: column-reverse;
        flex-wrap: nowrap;

        >.image {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .content {
          padding: 30px;
          min-height: 40vh;
          max-height: 70vh;
          overflow: scroll;

          .desc {
            p {
              padding-top: 20px;
              margin: 0;
            }
            & > p:last-child {
              padding-bottom: 30px;
            }
          }

          .button-container {
            margin-top: 0;
            min-height: 14%;

            .question-buttons {
              width: 100%;

              .btn-next {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .edit-question {
    .modal-dialog {
      .quiz-page {
        .page {
          .question {
            width: 100%;
            padding: 0 5%;

            .question-title,
            .question-sub-title {
              text-align: left;
            }
          }

          .questionnaire {
            width: 100%;
          }
        }
      }
    }
  }

  .instructions-modal {
    .modal-dialog {
      padding: 20px;
    }

    .modal-content {
      padding: 0 20px;
    }

    .modal-body-title {
      font-size: 26px;
    }

    .modal-body-text {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .quiz-page {
    .page {
      .answer {
        .kids-answer {
          max-width: 470px;
        }
      }
    }
  }
}
