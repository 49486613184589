$background: #EEEEEE;
$white: #FFFFFF;
$black: #000000;
$grey: #888888;
$light_brown:#F2EBE0;
$green: #96c0b8;
$green2: #006A53;
$red: red;
$shaklee_green:#173e35;
  /* Learn More Page */
$color_1: black;
$color_2: rgba(0, 0, 0, 0.5);
$light_grey: #4A4A4A;
$very_light_grey: #c9c7c7;
$font_family_1: Arial, Helvetica, sans-serif;
$font_family_2: Arial, sans-serif;
$font_family_3: OpenSans-Bold, sans-serif;
$font_suisse_regular: SuisseIntl-Regular, sans-serif;
$font_suisse_light: SuisseIntl-Light, sans-serif;
$font_suisse_book: SuisseIntl-Book, sans-serif;
$font_suisse_medium: SuisseIntl-Medium, sans-serif;

/* Transition Page */
$title: #046853;

/* reco page */
$summary_prenatal_bg: #2a4a43;
$reco_content_bg: #f9f7f5;

$xxs-width: 359px;

@mixin textFieldDesktop {
  background-color: transparent !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $black;
  border-radius: 0;
  padding: 20px 0;
  color: $black;
  width: 500px;
  font-family: $font_suisse_light;
  font-size: 24px;
  letter-spacing: 0.5px;
  margin-top: 50px;
}
@mixin errorMessage {
  position: relative;
  background-color: $white;
  border: $red solid 1px;
  margin-bottom: 10px !important;
  padding: 8px 15px;
  border-radius: 5px;
  color: $red;
  text-align: left;
  display: inline-block;
  max-width: 350px;
  overflow: visible;
  font-size: 12px;
}
@mixin errorMessageUpArrow {
  background-color: $white;
  display: block;
  height: 18px;
  width: 18px;
  border: inherit;
  position: absolute;
  bottom: 0;
  left: 50%;
  clip-path: polygon(100% 0, 100% 100%, 0% 0%);
  transform: translate(-50%, 50%) rotate(135deg);
}
/* Accordion Styles */
@mixin accordion-style {
  /* accordion style section */
  .accordion {
      .toggle {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0);
          border-bottom: 1px solid black;
      }
      .accordion-footer {
          height: 20px;
          display: block;
      }
      .accordion-title {
          font-size: 36px;
          color: #000000;
          line-height: 48px;
          position: relative;
          padding: 35px 0;
          transition: all 0.4s ease;
          .title {
              cursor: pointer;
              width: fit-content;
          }
          .icon {
              cursor: pointer;
              position: absolute;
              display: block;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              background-image: url('https://images.shaklee.com/meology/images/plus-solid.svg');
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              transition: all 0.6s ease;
              border: 0;
              background-color: transparent;
          }
      }
      .accordion-content {
          color: #000;
          /* Fallback color for older browsers */
          color: rgba(0, 0, 0, 0.5);
          overflow-y: hidden;
          transition: all 0.4s ease;
          .table-container{
              box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.25);
          }
          .table-title,
          .table-footer,
          .table-otherIngredients {
              background: white;
              color: #3E3E3E;
              p {
                  margin-bottom: 0;
              }
              p:first-child {
                  font-size: 24px;
                  color: #000000;
                  line-height: 31px;
              }
          }
          .table-title {
              padding: 40px 20px 20px 20px;
          }
          .table-footer {
              padding: 20px;
          }
          .table-otherIngredients {
              padding: 20px 20px 40px 20px;
              .title {
                  font-weight: bold;
              }
          }
          .table-responsive {
              background: #FFFFFF;
              border-radius: 4px;
              padding: 0 20px;
              .table thead th {
                  border-top: 0;
              }
              .table {
                  border-top: 1px solid black;
                  border-bottom: 1px solid black;
              }
          }
      }

      .accordion-content.Labels {
          .nav-link {
              font-size: 20px;
              color: #2E2E2E;
              letter-spacing: 0.3px;
              line-height: 26px;
              text-decoration: underline;
              padding: 0 0 50px 0;
          }
      }
      
      .toggle.open .accordion-title {
          .icon {
              transform: translateY(-50%) rotate(180deg);
              background-image: url('https://images.shaklee.com/meology/images/minus-solid.svg');
          }
      }
  }
}
