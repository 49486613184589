@import 'Variables.scss';
@font-face {
  font-family: 'SuisseIntl-Regular';
  src: url(fonts/SuisseIntl/SuisseIntl-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'SuisseIntl-Book';
  src: url(fonts/SuisseIntl/SuisseIntl-Book.otf) format('opentype');
}
@font-face {
  font-family: 'SuisseIntl-Medium';
  src: url(fonts/SuisseIntl/SuisseIntl-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'SuisseIntl-Light';
  src: url(fonts/SuisseIntl/SuisseIntl-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Shaklee-Verlag-Light';
  src: url(fonts/Verlag/Shaklee-Verlag-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Shaklee-Verlag-Book';
  src: url(fonts/Verlag/Shaklee-Verlag-Book.otf) format('opentype');
}
@font-face {
  font-family: 'Shaklee-Verlag-Black';
  src: url(fonts/Verlag/Shaklee-Verlag-Black.otf) format('opentype');
}
@font-face {
  font-family: 'Shaklee-Verlag-Bold';
  src: url(fonts/Verlag/Shaklee-Verlag-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Gilmer-Bold';
  src: url(fonts/Gilmer/Gilmer-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Gilmer-Light';
  src: url(fonts/Gilmer/Gilmer-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Gilmer-Medium';
  src: url(fonts/Gilmer/Gilmer-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Gilmer-Regular';
  src: url(fonts/Gilmer/Gilmer-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'SchnyderS-Bold';
  src: url(fonts/Schnyder/SchnyderS-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'SchnyderS-BoldItalic';
  src: url(fonts/Schnyder/SchnyderS-BoldItalic.otf) format('opentype');
}
@font-face {
  font-family: 'SchnyderS-Demi';
  src: url(fonts/Schnyder/SchnyderS-Demi.otf) format('opentype');
}
@font-face {
  font-family: 'SchnyderS-DemiItalic';
  src: url(fonts/Schnyder/SchnyderS-DemiItalic.otf) format('opentype');
}
@font-face {
  font-family: 'SchnyderS-Light';
  src: url(fonts/Schnyder/SchnyderS-Light.otf) format('opentype');
}
@font-face {
  font-family: 'SchnyderS-LightItalic';
  src: url(fonts/Schnyder/SchnyderS-LightItalic.otf) format('opentype');
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('fonts/glyphicons/glyphicons-halflings-regular.eot'); // IE
  src: url('fonts/glyphicons/glyphicons-halflings-regular.ttf') format('truetype');
}

@mixin glyphicons {
  font-family: 'Glyphicons Halflings' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

// ADA focus indicator style
@mixin focus-indicator() {
  &:focus-visible {
    outline: 2px solid rgb(9, 105, 218);
    outline-offset: -2px;
    box-shadow: none;
  }
}

.glyphicon::before {
  @include glyphicons;
}

.glyphicon-user::before {
  content: '\e008';
}

.glyphicon-shopping-cart::before {
  content: '\e116';
}

.glyphicon-log-in::before {
  content: '\e161';
}

.glyphicon-remove::before {
  content: '\e014';
}

.glyphicon-link::before {
  content: '\e144';
}

body {
  -webkit-font-smoothing: inherit;
  font-family: SuisseIntl-Regular, sans-serif;
}

.no-scroll {
  width: 100%;
  width: 100vw;
  height: 100% !important;
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.container-fluid,.fluid-container {
  padding: 0;

  .footer {
    background-color: $white;
    font-size: 16px;
    font-family: 'Shaklee-Verlag-Book', sans-serif;

    @media screen and (max-width: 768px) {
      text-align: center;
    }

    &-list1 a,
    &-list3 a {
      text-decoration: none;
      position: relative;

      &:hover {
        &::before {
          visibility: visible;
          transform: scaleX(1);
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #3e3e3e;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }
    }

    &-list1,
    &-list2,
    &-list3 {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &-title {
      font-family: 'Shaklee-Verlag-Black', sans-serif;
      font-size: 14px;
      height: 34px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin: 0;

      @media screen and (max-width: 768px) {
        justify-content: center;
        margin-top: 30px;
      }
    }

    &-list1 {
      li {
        padding: 10px 0;
      }
    }

    &-list2 {
      display: flex;
      @media screen and (max-width: 768px) {
        justify-content: center;
        padding-top: 10px;
      }
      li:not(:last-child) {
        margin-right: 20px;
      }
    }

    &-list3 {
      display: flex;

      @media screen and (max-width: 768px) {
        justify-content: center;
        padding: 10px 0;
      }

      li:not(:last-child) {
        display: flex;
        align-items: center;
        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 16px;
          background-color: #fff;
          margin: 0 10px;
        }
      }
    }

    .row {
      margin: 0;
    }

    .row:nth-child(1) {
      padding: 60px 80px;
      color: #3e3e3e;

      @media screen and (max-width: 768px) {
        padding: 30px 30px 60px;
      }

      a {
        color: #3e3e3e;

        &::before {
          background-color: #3e3e3e;
        }
      }
    }

    .row:nth-child(2) {
      padding: 20px 80px;
      background-color: #000;
      color: $white;
      @media screen and (max-width: 768px) {
        font-size: 12px;
        padding: 30px 10px;
      }

      a {
        color: $white;
        &::before {
          background-color: #fff;
        }
      }
    }

    &-copyright {
      &-ca {
        sup {
          top: .15em;
          font-size: 150%;
        }
      }
      @media screen and (max-width: 768px) {
        padding: 10px 0;
      }
    }
  }
}
.modal {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0;
    padding: 65px;
  }
  .modal-header {
    border: none;
    button.close {
      opacity: 1;
      padding: 0;
      margin: 5px;
      span {
        font-size: 0;
        background: url(https://images.shaklee.com/meology/prenatal/icon_close.svg) 50% no-repeat;
        width: 26px;
        height: 26px;
        display: block;
      }
    }
  }
  .modal-content {
    height: 100%;
    background: #FFFFFF;
    box-shadow: 1px 1px 6px 0 rgba(0,0,0,0.20);
    border-radius: 0;
    .modal-body {
      padding: 0;
    }
    .modal-header {
      position: absolute;
      right: 0;
      z-index: 1;
    }
    .modal-footer {
      padding: 0;
      border-top: none;
      flex-flow: column;
      .btn {
        font-family: SuisseIntl-Book, sans-serif;
      }
      .btn-save {
        border: 2px solid $black;
        border-radius: 2px;
        width: 315px;
        padding: 12px 0;
        background: $white;
        color: $black;
      }
      .btn-cancel {
        border: none;
        background-color: $white;
        color: $black;
      }
    }
  }

  &-button-white, &-button-no-bg {
    all: unset;
    font-size: 1rem;
    min-width: 250px;
    height: 50px;
    line-height: 100%;
    text-align: center;
    color: #000;

    &:disabled {
      border-color: #aaa;
      color: #aaa;
      cursor: not-allowed;
    }
  }

  &-button-white {
    border-radius: 2px;
    border: solid 1px #000;
  }

  &-button-close {
    all: unset;
    width: 26px;
    height: 26px;
    font-size: 0;
    background-image: url('https://images.shaklee.com/meology/icons/mobile/close_button.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 20px;
    top: 20px;
    border: 10px solid transparent;

    @include focus-indicator();
  }
}
.modal-backdrop {
  background: #F2EBE0;
  &.show {
    opacity: 0.94;
  }
}

.animate-checkbox {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #000;
  display: flex;
  @media only screen and (min-width: 768px) and (max-width: 1080px) {
    font-size: 11px;
  }
  input {
    display: none;
  }
  svg {
    margin-right: 7px;
    flex-shrink: 0;
    flex-grow: 0;
    > rect {
      fill-opacity: 0;
      transition: fill-opacity .2s ease-in-out;
    }
    > path {
      stroke-dashoffset: 24;
      stroke-dasharray: 24;
      transition: stroke-dashoffset .3s ease-in;
    }
  }
  p {
    line-height: 16px;
    margin: 0;
    padding: 0;
  }

  // checked
  &.checked {
    svg {
      > rect {
        fill-opacity: 1;
      }
      > path {
        stroke-dashoffset: 0;
      }
    }
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  -webkit-overflow-scrolling: auto;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0,0,0,.4);
}

@mixin kids-indicator() {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 74px;
  height: 74px;
  text-decoration: underline;
  background-color: rgba(2, 104, 84, 0.8);
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0, -25%);
  border-radius: 50%;
  word-wrap: break-word;
  padding: 0 5px;
  visibility: hidden;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 9px;
  }
}

.copy-page-link {
  border: 0;
  background-color: transparent;
  display: block;
  margin: 0 auto 0 -600px;
  font-size: 16px;
  text-align: right;
  width: 1200px;
  position: absolute;
  left: 50%;
  z-index: 1000;

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    left: 0;
    margin-left: 0;
    padding-right: 10px !important;
  }

  &:focus {
	outline: none;
  }

  .glyphicon {
    margin-right: 5px;
    &::before {
      vertical-align: middle;
    }
  }

  &-landing-page {
	color: $shaklee_green;
	padding: 30px 30px 0 0;
  }

  &-marketing-page {
	color: #000;
	padding-right: 30px;
  }
}

// Call to Action button
.cta-button {
  $root: &;
  font-family: 'Gilmer-Bold', sans-serif;
  font-weight: bold;
  border: 0;
  border-radius: 3px;
  color: $white;
  background-color: $shaklee_green;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  font-size: 18px;
  padding: 15px 0;

  &:focus {
    outline: #000 solid 2px;
    border: 2px solid #fff;
  }
  &.retake {
    background-color: transparent;
    color: $shaklee_green;
    border-bottom: 2px solid $shaklee_green;
    width: auto;
    border-radius: 0;
    height: 23px;
    margin-bottom: 20px !important;
    font-family: 'Gilmer-Regular', sans-serif;
    min-width: 180px;
    padding: 0;
  }

  .btn-container & {
    margin: 10px 0;
  }

  &-wrap {
    display: flex;
    justify-content: center;

    &1 {
      padding: 20px 0 70px;
    }

    &2 {
      width: 50%;
      padding: 60px 0;
    }

    &3 {
      padding: 100px 0 0;
    }

    &4 {
      position: absolute;
      width: 100%;
      bottom: 50px;
    }

    &-kids {
      #{$root} {
        width: 220px;
        height: 44px;
        font-size: 14px;
      }
    }

    &5 {
      position: absolute;
      bottom: 110px;
      left: 20px;
    }

    &6 {
      position: absolute;
      width: 100%;
      bottom: 180px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: 46px;
    font-size: 14px;

    &-wrap {
      #{$root} {
        width: 315px;
        height: 62px;
        font-size: 16px;
        padding: 0 15px;
      }

      &1 {
        padding: 20px 0 50px;
      }

      &2 {
        width: 100%;
        padding: 40px 0;
      }

      &3 {
        padding: 50px 0 0;
      }

      &4 {
        left: 0;
        bottom: 25px;
      }

      &-kids {
        #{$root} {
          width: 220px;
          height: 44px;
          font-size: 14px;
        }
      }

      &5 {
        width: 100%;
        left: 0;
        bottom: 50px;
      }

      &6 {
        bottom: 100px;
      }
    }
  }
}
.cta-text-button {
  border: 0;
  padding: 0;
  background-color: transparent;
  font-family: 'Gilmer-Bold', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #2e2e2e;
  display: flex;
  align-items: center;
  letter-spacing: .65px;

  &::after {
    content: '';
    display: block;
    width: 53px;
    height: 53px;
    background-image: url('https://images.shaklee.com/meology/icons/circel-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 10px;
  }
}

.one {
  .cta-button {
    background-color: #fff;
    color: #173e35;
    margin: 0 8px 15px;
    text-transform: uppercase;
  }
}

.btn-white {
  background: transparent;
  padding: 14px 0;
  font-size: 20px;
  font-weight: 700;
  outline: none;
  width: 358px;
  min-width: 100%;
  border: 2px solid #000;
  border-radius: 2px;
  display: block;
  height: 58px;
  color: #000;
  &:focus {
    border: 2px solid blue;
  }
}

.button-link {
  display: inline;
  border: 0;
  margin: 0;
  padding: 0 !important;
  background-color: transparent;
  color: currentColor;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

// Radio with image
.radio-image-list {
  all: unset;
  list-style-type: none;
  display: flex;
  gap: 30px;
  margin: 50px auto 0;
  flex-wrap: wrap;
  justify-content: center;

  li:not(:last-child) {
    border: 2px solid #2e2e2e;
    border-radius: 10px;
    width: 282px;
    height: 324px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.on {
      background-color: #fff;
    }

    label {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 0 70px;
      cursor: pointer;
    }
  }

  li:focus-visible {
    @include focus-indicator();
  } 

  input {
    visibility: hidden;
  }

  li:last-child {
    flex: none;
  }



  &-img {
    width: 96px;
    height: 114px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 56px !important;

    img {
      display: block;
    }
  }
}

// Prenatal Loading Animation
@keyframes heart {
  0% {
    stroke-dasharray: 0 2000;
  }

  100% {
    stroke-dasharray: 2000 0;
  }
}

.prenatal-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &-path {
     stroke-dashoffset: 1986;
     animation: heart 3s linear reverse forwards;
  }

  &-text {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    font-size: 1.6667vw;
    font-family: 'Gilmer-Regular';
    text-align: center;

  }

  &-content {
    width: 32.0313vw;
    height: 28.125vw;
    position: relative;

    > svg {
      &:nth-child(2) {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

// Checkbox button
.checkbox-button {
  background-color: $shaklee_green;
  border: 0;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  border-radius: 0.25rem;
  margin: 0;
  padding: 0;
  border: 1px solid $shaklee_green;

  &:focus {
    outline: 2px solid #000;
    border: 2px solid #fff;
  }

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    filter: brightness(90%);
  }

  &.selected {
    background-color: #fff;
    color: $shaklee_green;

    &::after {
      @include glyphicons;
      content: "\e089";
      margin-left: 5px;
    }
  }

  &.disabled {
    background-color: #D1D9D7;
    border-color: #D1D9D7;
    color: #fff;
    cursor: not-allowed;

    &:hover,
    &:active {
      filter: inherit;
    }
  }
}

.meology-text-button {
  background: transparent;
  border: none;
}

// Registered Sign
@mixin registered-sign($size: 0.8em) {
  &::after {
    content: '\00AE';
    font-size: $size;
    vertical-align: super;
  }
}

// Video
.video {
  &-wrap {
    position: relative;
    padding: 56.25% 0 0 0; /* 16:9 */

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

// Citation
.citation {
  font-size: 16px;
  color: #3e3e3e;
  margin-bottom: 26px;
}

@media only screen and (max-width: 768px) {
  .modal {
    .modal-content {
      overflow: hidden;
      .modal-body .quiz-page {
        .answer {
          height: 50%;

          .btn-container {
            width: 50%;
          }
        }
        .answer.prenatal_due_date_picker {
          height: initial;
        }
      }
      .modal-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }

  .radio-image-list {
    flex-direction: column;
    align-items: center;

    li:not(:last-child) {
      height: 236px;
    }

    &-img {
      margin-bottom: 20px !important;
    }
  }

  .prenatal-loading {
    &-text {
      font-size: 4.2667vw;
    }
    &-content {
      width: 92.8vw;
      height: 81.6vw;
    }
  }

  .cta-button {
    min-width: 100%;
    padding: 15px 5px;
  }
}

// Hide scroll bar
@mixin hide-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.skip-to-content {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  color: #0072ee;

  &:focus {
    background: white;
    clip: auto;
    height: auto;
    left: 10px;
    padding: 1rem;
    position: fixed;
    top: 0px;
    width: auto;
    z-index: 1001;
  }
}

// Utility Class
mb-0 {
  margin-bottom: 0;
}