.learn-more-modal {

	.modal-dialog {
	  width: 842px;
	  height: auto;
	  margin: auto;
	}
	&__title {
	  padding: 46px 22% 10px;
	  line-height: 1.14;
	  text-align: center;
	  font-family: SchnyderS-Demi;
      font-size: 36px;
	  color: #333;
	  span {
		display: inline-block;
		width: 390px;
	  }
	}
	.green-border {
	  width: 15%;
	  height: 4px;
	  background: #173e35;
	  z-index: 10000;
	  display: flex;
	  align-self: center;
	  margin-bottom: 39px;
	}

 .accordion-customized {
	.accordion-title {
	  display: flex;
	  border-top: 1px solid #a8a8a8;
	  align-items: flex-start;
	  padding: 20px 20px;

	  .left-icon {
		display: inline-block;
		width: 44px;
		height: 44px;
		background-color: #cccccc;
		border-radius: 50%;
		margin-right: 10px;
	  }

	  .title {
		flex: 1;
		cursor: pointer;
		padding-right: 16px;
		font-family: SchnyderS-Demi;
		font-size: 18px;
		color: #333;
		line-height: 27px;
	  }

	  .icon {
		cursor: pointer;
		border: 0;
		background-color: transparent;
		width: 20px;
		height: 20px;
		background-image: url('https://images.shaklee.com/meology/images/plus-solid.svg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		transition: all 0.6s ease;
		margin-top: 4px;
	  }
	}

	.toggle {
	  &.open {
		.accordion-title {
		  .icon {
			transform: rotate(180deg);
			background-image: url('https://images.shaklee.com/meology/images/minus-solid.svg');
		  }
		}
	  }
	}

	.accordion-content {
	  padding: 20px;
	  font-family: 'Gilmer-Regular';
	  font-size: 16px;
	  line-height: 1.75;
	  color: #3e3e3e;

	  a {
		color: #0072ee;
	  }
	}
  }
}
@media only screen and (max-width: 768px) {
  .learn-more-modal {

	.modal-dialog {
		padding: 16px 8px 16px 5px;
	}

	.learn-more-modal__title {
	  padding: 70px 13px 10px;
	  font-size: 26px;
	}

	.accordion-customized {
	  .accordion-title {
		height: auto;
		padding: 10px 15px;
	  }
	  .title {
		font-size: 16px;
	  }
	  .accordion-content {
		font-size: 14px;
		span {
		  word-wrap: break-word;
		}
	  }
	}
  }
}
