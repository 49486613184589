.rsw-step-tag {
  display: inline-block;
  min-width: 89px;
  min-height: 36px;
  padding: 7px 12px 9px 16px;
  border-radius: 3px;
  background-color: rgba(123, 156, 145, 0.8);
  font-family: Gilmer-Bold;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: 0.4px;
  color: #fff;
}

.rsw-hide {
  display: none !important;
}

.rsw-completed-tag {
  display: inline-block;
  height: 40px;
  width: 40px;
  padding: 10px 9px 12px 9px;
  border-radius: 20px;
  background-color: #173e35;

  &::after {
    display: block;
    content: '';
    width: 22px;
    height: 14px;
    transform: rotate(-45deg);
    border-left: solid 4px #fffaf5;
    border-bottom: solid 4px #fffaf5;
    border-bottom-left-radius: 3px;
  }

  &.simple {
    height: 20px;
    width: 20px;
    background: transparent;
    border: solid 1px #173e35;
    padding: 5px 0 0px 4px;

    &::after {
      width: 10px;
      height: 6px;
      border-left: solid 2px #173e35;
      border-bottom: solid 2px #173e35;
      border-bottom-left-radius: 1px;
    }
  }
}

.rsw-product-detail-link {
  font-family: Gilmer-Bold;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.62;
  letter-spacing: 0.34px;
  color: #000;
  cursor: pointer;

  &::after {
    display: block;
    content: '';
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.rsw-card-title {
  font-family: Gilmer-Medium;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: 0.53px;
  color: #000;
}

.rsw-card-sub-title {
  font-family: Gilmer-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.44;
  letter-spacing: 0.24px;
  color: #2e2e2e;
}

.rsw-card-text {
  font-family: Gilmer-Regular;
  font-size: 14px;
  line-height: 0.86;
  letter-spacing: 0.21px;
  color: #2e2e2e;
}

.rsw-promo-card-container {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 290px;
  min-height: 460px;
  padding: 20px 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(185, 185, 185, 0.5);
  background: #fff;
  text-align: left;

  .step-tag-position {
    position: absolute;
    top: 0;
    left: 0;
  }

  .completed-tag-position {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .promo-card-img {
    min-height: 210px;
    min-width: 210px;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
      max-width: 260px;
      max-height: 260px;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  .promo-card-content {
    padding: 0 15px 40px;

    .text-item {
      display: block;
      padding-bottom: 8px;
    }

    .select-component-container {
      font-family: Gilmer-Medium;
      color: #4a4a4a;

      & > div {
        margin-bottom: 12px;
      }
    }
  }

  .detail-link-position {
    position: absolute;
    bottom: 20px;
    left: 15px;
  }
}

.rsw-step-card-container {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  background: #fff;
  text-align: left;

  .step-card-content {
    width: 100%;
    padding: 70px 120px 16px 45%;
    position: relative;
    min-height: 390px;
  }
  .step-card-img {
    position: absolute;
    width: 45%;
    height: 100%;
    padding: 30px 30px 60px;
    top: 0;
    left: 0;

    &,
    .swiper-img {
      display: flex;
      align-items: center;
      justify-items: center;
      text-align: center;
    }
    .swiper-container,section {
      height: 100%;
      width: 100%;
    }

    &.step-card-img-reco {
      padding: 0;

      & > div {
        margin: 0 auto;
        padding: 0;
      }
      .sachet-image {
        width: 225px;
        height: 264px;
        padding: 20px 15px 0 36px;
        background-size: contain;
      }

      .sachet-powder img {
        height: 292px;
      }
    }

    .swiper-img > img,
    & > img {
      width: 100%;
      height: 100%;
      max-width: 310px;
      max-height: 310px;
      margin: 0 auto;
      object-fit: contain;
    }
  }
  .step-card-text {
    font-family: Gilmer-Regular;
    font-size: 18px;
    letter-spacing: 0.39px;
    color: #000;
  }

  .rsw-step-tag,
  .step-card-content > p {
    margin-bottom: 12px;
  }

  .select-component-container {
    font-family: Gilmer-Medium;
    color: #4a4a4a;
    
    & > div {
      margin-bottom: 15px;
      max-width: 275px;
      display: block;
    }
  }

  .detail-link-position {
    position: absolute;
    bottom: 26px;
    left: 32px;
  }
}

.rsw-pack-link-container {
  width: 100%;
  display: block;
  background-color: rgba(42, 74, 67, 0.05);
  border-radius: 2px;
  padding: 17px 0 16px 32px;
  cursor: pointer;

  .pack-link {
    position: relative;
    display: inline;

    &::after {
      position: absolute;
      left: 0;
      bottom: -6px;
    }
  }

  .link-icon {
    position: relative;
    display: inline;

    &::before {
      position: absolute;
      content: '+';
      color: #000;
      right: -20px;
      top: 0;
      font-size: 24px;
      line-height: 16px;
      transition: all 0.6s ease;
      font-weight: bold;
      font-family: Gilmer-Bold;
    }

    &.expand::before {
      top: 3px;
      transform: rotate(180deg);
      content: '-';
    }
  }
}

.rsw-pack-pills {
  background-color: #fff;
  padding: 0;
  .pill-container {
    .pill-line {
      margin: 10px 30px 0;
      background-color: rgba(62, 62, 62, 0.2);
    }
  }
  .pill-info {
    box-shadow: none;

    .product-info-right {
      width: 50%;
      padding-left: 25px;
    }
    .product-info-left {
      padding-right: 25px;
    }

    .product-footer {
      margin-top: 0;
    }

    .product-info-left-picture {
      width: 88px;
    }
    .prenatal-essential-1 {
      max-width: 280px !important;
    }
    .product-info-left-desc {
      width: 100%;
    }
  }
}

.rsw-no-result {
  margin: 60px auto;
  font-size: 36px;
  color: #c0c0c0;
  font-family: Gilmer-Medium;
  text-align: center;
}

.rsw-join-flow-container {
  padding: 54px 16px 36px 18px;
  background-color: rgba(255, 248, 242, 0.4);
  .rsw-step-card-container {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.rsw-top-step-banner-container {
  width: 100vw;
  display: flex;
  height: 70px;
  padding: 0 calc(50% - 570px);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #eef3ea;
  align-items: center;

  .rsw-log {
    display: inline-block;
    padding-top: 8px;
    margin-right: 6px;
    width: 86px;
    height: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.06;
    color: #fff;
    text-align: center;
    font-family: Roboto, Helvetica, sans-serif;
    background-color: #7e9a90;
  }

  .step-items {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}

.res-banner-step-tag {
  width: 100%;
  max-width: 254px;
  font-family: Gilmer-Bold;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.06;
  letter-spacing: 0.36px;
  text-align: center;
  color: #173e35;
  background: transparent;

  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    margin-bottom: 4px;

    & > span {
      margin-left: 8px;
    }
  }

  &.active {
    padding: 7px 8px 8px;
    border-radius: 3px;
    border: solid 1px #173e35;
  }
}

.product-attributes-list-container {
  & > img {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    display: inline-block;
  }
}

.boost-deatil-content {
  padding: 60px 0;
  color: #000;

  &, 
  & + .recommend-title {
    background-color: #f9f7f5;
  }
  .boost-img {
    img {
      height: 100%;
      width: 100%;
      max-width: 175px;
      max-height: 175px;
      margin-bottom: 16px;
      object-fit: contain;
      object-position: left;
    }
  }
  .boost-title {
    font-family: Gilmer-Medium;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36;
    letter-spacing: 0.61px;
  }
  .boost-short {
    font-family: Gilmer-Regular;
    font-size: 22px;
    line-height: 1.23;
  }
  .boost-long {
    font-family: Gilmer-Light;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    letter-spacing: 0.5px;
  }
}

.boost-deatil-list-container {
  .recommend-title,
  .recommend-content {
    padding: 0 !important;
  }
}

.top-fixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1003;
  transition: all ease-in-out .3s;
}

@media only screen and (max-width: 1200px) {
  .rsw-top-step-banner-container {
    padding: 0 30px;
  }
}


@media only screen and (max-width: 768px) {
  .rsw-card-title {
    font-size: 22px;
    line-height: 1.41;
    letter-spacing: 0.49px;
  }
  .rsw-card-text {
    font-size: 13px;
    line-height: 0.92;
    letter-spacing: 0.2px;
  }
  .rsw-product-detail-link {
    font-size: 12px;
    line-height: 1.27;
    letter-spacing: 0.09px;

  }

  .rsw-pack-link-container {
    padding: 16px 13px 12px 14px;
    display: flex;
    justify-content: space-between;

    .link-icon::before {
      right: 0;
    }

    .pack-link {
      &::after {
        bottom: -2px;
      }
    }
  }

  .rsw-step-card-container {
    .step-card-content {
      padding: 30px 13px 16px 14px;
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
    }
    .step-card-img {
      position: relative;
      width: 100%;
      padding: 0 0 12px ;
      order: 4;

      &.step-card-img-reco {
        padding: 12px 0;
        .sachet-image {
          width: 191px;
          height: 224px;
          padding: 20px 15px 0 26px;
          background-size: contain;
        }
      }

     .swiper-img > img,
      & > img {
        max-width: 270px;
        max-height: 270px;
      }
    }
    .rsw-step-tag {
      order: 1;
      display: inline-block;
    }
    .rsw-card-title {
      order: 2;
    }
    .step-card-text {
      order: 3;
      margin-bottom: 0;
    }
    .select-component-container {
      width: 100%;
      order: 5;
      padding-bottom: 40px;

      & > div {
        max-width: unset;
      }
    }
    .detail-link-position {
      left: 13px;
    }
  }

  .rsw-pack-pills {
    .pill-container {
      .pill-line {
        margin: 10px 20px 0;
      }
    }
    .pill-info {
      .product-info-right {
        width: 100%;
        padding-left: 0;
      }

      .product-info-left {
        padding-right: 0;
      }
    }
  }

  .rsw-top-step-banner-container {
    height: 60px;
    padding: 0;

    .rsw-log {
      width: 70px;
      font-size: 14px;
    }
  }

  .rsw-top-banner-postion  {
    height: 60px;
  }

  .res-banner-step-tag {
    font-size: 11px;
    line-height: 1.18;
    letter-spacing: 0.24px;

    & > span {
      line-height: 11px;
      margin-bottom: 3px;

      & > span {
        margin-left: 4px;
      }
    }

    &.active {
      padding: 5px 8px 6px;
    }
  }

  .rsw-completed-tag {
    &.simple {
      height: 14px;
      width: 14px;
      padding: 3px 0 0 2px;

      &::after {
        width: 8px;
        height: 5px;
      }
    }
  }

  .boost-deatil-content {
    .boost-img {
      text-align: center;
      img {
        max-width: 142px;
        max-height: 142px;
        object-position: center;
      }
    }
    .boost-title {
      letter-spacing: 0.49px;
    }

    .boost-long {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.24px;
      color: #3e3e3e;
    }
  }

  .rsw-qa-page-container {
     .back {
      height: 57px;
      margin-bottom: -57px;
      &.fixed {
        padding-bottom: 20px;
      }
      .btn-back {
        margin: 0 0 0 22px;
      }
      .brand {
        margin: 0 22px 0 0;
      }
    }
    .rsw-qa-page {
      overflow: scroll;
      padding-bottom: 138px;
      height: 100%;

      &.is-transition-page {
        padding-bottom: 90px;

        .back {
          margin-bottom: -57px;

          .btn-back {
            padding: 0;
          }
        }

        .transition {
          position: static;
        }
      }

      & > .questionnaire  {
        height: auto;
        overflow-y: visible;
        &.prenatal_reproductive_status {
          padding-bottom: 90px;
        }
      }
      & > .transition {
        height: auto;
        min-height: calc(100vh - 60px);
        .content.prenatal_transition_basics {
          height: auto;
          max-height: inherit;
        }
        .image.prenatal_transition_basics {
          .banner {
            min-height: 30vh
          }
        }
      }
    }

    &.email-page {
      .email {
        .content {
          padding: 75px 30px 0;
        }
      }
    }
    
  }
  .radio-with-image.rsw-qa-page-container {
    .rsw-qa-page {
      .back {
        margin-bottom: 0;
      }
      .questionnaire {
        height: initial;
        min-height: calc(100vh + 400px);
        overflow: hidden;
      }
    }
  }
}

