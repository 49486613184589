.coming-soon {
  display: flex;
  font-family: 'Shaklee-Verlag-Book', sans-serif;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }

  &-title {
    font-family: 'Shaklee-Verlag-Black', sans-serif;
    font-size: 66px;

    @media (max-width: 991px) {
      font-size: 34px;
      line-height: 36px;
    }
  }

  &-subtitle {
    font-size: 34px;
    line-height: 44px;
    padding: 15px 0;

    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &-content {
    flex: 100 0 56.5%;
    padding: 10% 5% 20% 10%;
    font-size: 22px;
    line-height: 26px;

    @media (max-width: 991px) {
      padding: 10% 5% 5%;
      font-size: 16px;
      line-height: 30px;
    }
  }

  &-image {
    flex: 77 0 43.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    @media (max-width: 991px) {
      min-height: 250px;
    }
  }
}
