.kids-edit-modal {
  font-family: 'Gilmer-Regular', sans-serif;
	
	.modal-dialog {
		height: auto;

		.modal-content {
			padding: 50px;
			align-items: center;
		}
	}

	&-title {
    font-size: 36px;
		width: 100%;
		text-align: center;
  }

	&-intro {
		font-size: 16px;
		max-width: 80%;
		padding: 10px 0;
		text-align: center;
		color:  #3e3e3e;

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&-btn {
		display: flex;
		flex-direction: column;
		padding-top: 60px;
		position: relative;

		> button {
			@include focus-indicator();
			&:nth-child(2) {
				margin-top: 10px;
			}
		}
	}

	.input-icon-add-btn {
		margin: 20px auto 0;
		@include focus-indicator();
	}

	+ .modal-backdrop.show {
		z-index: 1050;
	}

	.error-message {
		@include errorMessage;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: calc(100% - 55px);
		width: 120%;
		pointer-events: none;
		.arrow {
			@include errorMessageUpArrow;
		}
	}
}

.kids-promotion-text {
	color: #3e3e3e;
	margin: 7px 0 0 0;
	text-align: center;
	font-size: 18px;
}

@media (max-width: 768px) {
	.kids-edit-modal {
		.modal-dialog {
			padding: 10px;
		}
	}

	.kids-promotion-text {
		font-size: 14px;
	}
}
