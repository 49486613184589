.market-container {
  padding: 0 !important;
  .brand-container {
    background: $white;
    position: sticky;
    top: 0;
    z-index: 999;
    .brand {
      display: grid;
      justify-content: center;
      width: 1200px;
      margin: 0 auto;
      padding: 20px 30px;
      position: relative;
      grid-template-rows: 100px;
      grid-template-columns: 260px 1fr 260px;
      align-items: center;
      &-icon {
        border: 0;
        padding: 0;
        background-color: transparent;
        background-image: url('https://images.shaklee.com/meology/icons/arrow_back.svg');
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: contain;
        width: 40px;
        height: 60px;
      }

      &-title {
        justify-self: center;
        img {
          width: 220px;
        }

        &.brand-logo-text {
          position: relative;
           img:last-of-type {
            display: none;
           }
        }
        &.brand-logo-text::before {
          content: attr(data-text);
          display: inline-block;
          position: absolute;
          top: 165%;
          left: 50%;
          white-space: nowrap;
          transform: translate(-50%, 6px);
          font-family: 'Gilmer-Bold', sans-serif;
          font-size: 20px;
          color: #2e2e2e;
        }
      }
      .btn-container {
        // position: absolute;
        // right: 30px;
        // top: 50%;
        // transform: translate(0, -50%);
        display: grid;
        justify-self: flex-end;

        .home-button-1,
        .home-button-3,
        .home-button-2 {
          justify-self: center;
          margin: 10px 0;
          border-radius: 3px;
          background-color: #173e35;
          width: 260px;
          height: 50px;
          line-height: 50px;
          color: $white;
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          font-family: 'Gilmer-Bold', sans-serif;
          border: 0;
        }
      }
    }
  }

  .brand-container + .meology-toggle {
    display: none;
  }
  .marketing-btn-container {
    background: $white;
    .marketing-btn {
      display: grid;
      justify-content: center;
      width: 1200px;
      margin: 0 auto;
      padding: 20px 40px;
      position: relative;
      align-items: center;

      .btn-container {
        display: grid;
        justify-self: flex-end;

        .home-button-1,
        .home-button-3,
        .home-button-2 {
          justify-self: center;
          margin: 10px 0;
          border-radius: 3px;
          background-color: #173e35;
          width: 260px;
          height: 50px;
          line-height: 50px;
          color: $white;
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          font-family: 'Gilmer-Bold', sans-serif;
          border: 0;
        }
      }
    }
  }
  .banner-container {
    position: relative;
    background: linear-gradient(
      to bottom,
      $white,
      $white 90px,
      rgba(2, 104, 84, 0.4) 90px,
      rgba(2, 104, 84, 0.4) 570px,
      $white 570px,
      $white
    );
    .banner {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .title {
        width: 730px;
        font-family: 'SchnyderS-Demi', sans-serif;
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.27px;
        color: #333333;
        padding-left: 90px;
      }
      .media {
        width: 1200px;
        margin: 0 auto;
        height: 585px;
        background: url('https://images.shaklee.com/meology/marketing/video-1.gif?v=1')
          no-repeat center center;
        background-size: contain;
      }
      .sub_title {
        font-family: 'SchnyderS-Demi', sans-serif;
        font-size: 80px;
        line-height: 82px;
        letter-spacing: -1.27px;
        position: relative;
        padding-left: 90px;
        margin-top: 50px;
        span, h2 {
          display: inline-block;
          &:nth-child(1) {
            width: 740px;
          }
          &:nth-child(2) {
            width: 100%;
          }
        }
        h2 {
          font-size: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        }
        .desc {
          width: 330px;
          position: absolute;
          top: 10px;
          right: 65px;
          font-family: 'Gilmer-Regular', sans-serif;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.73px;
          color: #2e2e2e;
        }
      }
      .how-it-works-text {
        position: relative;
        background-color: $white;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        padding: 50px 0;

        .section {
          color: #333333;
          border: solid 1px #979797;
          padding: 50px 25px;
          text-align: center;
          .step {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 22px;
            line-height: 32px;
            letter-spacing: 0.89px;
            text-transform: uppercase;
            padding-bottom: 10px;
          }
          .num {
            font-family: 'SchnyderS-Demi', sans-serif;
            font-size: 141px;
            line-height: 0.86;
            letter-spacing: -1.99px;
            text-align: center;
          }
          .text {
            font-family: 'SchnyderS-Demi', sans-serif;
            font-size: 51px;
            line-height: 51px;
            position: relative;
            margin-bottom: 20px;
            &::before {
              content: ' ';
              display: block;
              position: absolute;
              width: 100px;
              height: 2px;
              background-color: #979797;
              bottom: -20px;
              left: 50%;
              margin-left: -50px;
            }
          }
          .sub_text {
            margin-top: 40px;
            font-family: 'Gilmer-Medium', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
          }
        }
      }
    }
  }
  .banner-container.kids {
    background: linear-gradient(
      to bottom,
      $white,
      $white 90px,
      #d6efbe 90px,
      #d6efbe 570px,
      $white 570px,
      $white
    );
    .banner {
      width: 1140px;
      .title {
        width: 1100px;
      }
    }
    .all-in-one-container {
      margin-top: 70px;
      .all-in-one {
        width: 1140px;
        margin: 0 auto;
        position: relative;
        .text {
          position: absolute;
          width: 700px;
          font-family: 'SchnyderS-Demi';
          font-size: 83px;
          line-height: 90px;
          letter-spacing: -1.27px;
          color: #333333;
        }
        .bg {
		  position: relative;
		  top: -27px;
		  width: 1120px;
		  z-index: 0;
        }
      }
    }
    .blue-bg-container {
      //background-color: #e6f2f7;
      .blue-bg {
        width: 1140px;
        margin: 0 auto;
        position: relative;
        img {
          width: 100%;
        }
        .text {
          position: absolute;
          top: 215px;
          right: 0;
          .title {
            width: 665px;
            height: 130px;
            margin: 0 0 1px;
            font-family: 'SchnyderS-Demi', sans-serif;
            font-size: 65px;
            line-height: 55px;
            letter-spacing: -1.27px;
            text-align: center;
            color: #333;
          }
          .sub_title {
            width: 396px;
            height: 196px;
            margin: 1px 96px 0 115px;
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: 0.73px;
            text-align: center;
            color: #2e2e2e;
          }
        }
      }
    }
  }
  .mkt-section-title {
    font-family: SchnyderS-Demi;
    font-size: 90px;
    line-height: 0.94;
    letter-spacing: -1.27px;
    color: #333;
    text-align: center;
    padding-top: 30px;

    @media screen and (max-width: 1200px) {
      font-size: 65px;
    }

    @media screen and (max-width: 767px) {
        line-height: 1.02;
        text-align: left;
        letter-spacing: -0.91px;
        padding-left: 8px;
    }

    @media screen and (max-width: $xxs-width) {
      font-size: 50px;
    }
  }
  .mkt-section-sub-title {
    font-family: SchnyderS-Demi;
    font-size: 50px;
    line-height: 1.02;
    letter-spacing: -1.04px;
    color: #333;

    @media screen and (max-width: 767px) {
      font-size: 45px;
    }

    @media screen and (max-width: 450px) {
      font-size: 36px;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: $xxs-width) {
      font-size: 20px;
    }
  }
  .banner-container.prenatal {
    margin-top: 32px;
    position: relative;
    background: linear-gradient(
      to bottom,
      $white,
      $white 90px,
      #fff8f2 90px,
      #fff8f2 570px,
      $white 570px,
      $white
    );
    .brand-logo-text {
      display: none;
    }
    .copy-page-link-marketing-page {
      max-width: 1200px;
      width: 100%;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
    }
    .banner {
      .title {
        width: unset;
      }
      .media {
        background-image: url(https://images.shaklee.com/meology/prenatal/mkt_p_b_s_4.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        padding: 90px 0 64px 45px;
        margin-top: 16px;
        .media-detail {
          max-width: 365px;
          height: 100%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          align-items: center;
          p {
            padding: 0 24px;
            margin: 0;
            font-family: Gilmer-Regular;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.69;
            letter-spacing: 0.65px;
            text-align: center;
            color: #2e2e2e;
          }
          .prenatal-logo {
            height: 185px;
            width: 185px;
            background-image: url(https://images.shaklee.com/meology/prenatal/mkt_p_b_i_4.jpg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin-top: 16px;
          }
        }
      }

      .thumbs-container{
        margin-top: 68px;
        display: flex;
      }
      .thumbs-text {
        flex: 1;
        .mkt-section-title {
          text-align: left;
        }
        .th-detail {
          font-family: Gilmer-Regular;
          font-size: 16px;
          line-height: 1.69;
          letter-spacing: 0.65px;
          color: #2e2e2e;
        }
      }  
    }
    .expert-container {
      min-height: 250px;
      margin-top: 60px;
      background: url('https://images.shaklee.com/meology/prenatal/mkt_prenatal_b_3.jpg') no-repeat center center;
      background-size: cover; 
      display: flex;
      align-items: center;
      justify-content: center;

      .expert-text{
        width: 460px;
        margin-right: 60px;
        p:last-of-type {
          text-align: center;
          font-family: Gilmer-Regular;
          font-size: 16px;
          line-height: 1.69;
          color: #2e2e2e;
        }
      }
      .expert-logo{
        background: url('https://images.shaklee.com/meology/prenatal/mkt_prenatal_b_4.png') no-repeat;
        background-size: 100% 100%; 
        height: 210px;
        width: 210px;
      }
    }
  }
  .mkt-prenatal-step-container {
    padding: 60px 0;
    background-color: #fff8f2;
    .mkt-content-container {
      margin: 0 auto;
      max-width: 1200px;

      .mkt-step-header {
        p:last-of-type {
          margin: 26px auto;
          font-family: Gilmer-Regular;
          font-size: 16px;
          line-height: 1.69;
          color: #2e2e2e;
          text-align: center;
        }

        & + .prenatal-step-wrap .active .prenatal-step-title{
          margin-bottom: 16px;
        }
      }
      
      .mkt-step-detail {
        display: flex;
        flex-flow: column;
        position: relative;
        margin-top: 60px;

        & > .step-imgs-container {
          position: absolute;
          width: 50%;
          max-height: 780px;
          display: flex;
          flex-flow: column;
          align-items: center;
          img {
            object-fit: contain;
            width: 100%;
          }
          &> div {
            position: relative;
            max-height: 320px;
            width: 100%;
            img:last-of-type {
              display: none;
            }
            &::after {
              position:absolute;
              content: '';
              display: block;
              width: 90px;
              height: 90px;
              background: url(https://images.shaklee.com/meology/prenatal/plus.png) center center no-repeat;
              background-size: contain;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            } 
          }
          & > img{
            max-width: 420px;
            width: 65%;
          }
          & > img:last-of-type{
            display: none;
          }
        }

          &-items {
            width: 56%;
            align-self: flex-end;
          }
          &-top {
            padding: 46px 20px 16px 18px;
            .pill-img img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          &-pills {
            border-radius: 18px;
            border: solid 1px #2e2e2e;
            padding: 27px 20px 21px 18px;
            position: relative;
            z-index: 1;

            .mkt-pill-item-container {
              margin-bottom: 30px;
            }

            &::before,
            &::after {
              position: absolute;
              content: '';
              display: block;
              top: 55%;
              background: #2e2e2e;
              transform: translateY(-50%);
              left:-25%
            }
            &::after {
              width: 25%;
              height: 1px; 
            }
            &::before {
              height: 16px;
              width: 16px;
              border-radius: 8px;
            }

          }
          .mkt-step-meology {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            p, h4 {
              font-family: Gilmer-Regular;
              font-size: 16px;
              line-height: 1.69;
              color: #2e2e2e;
              margin: 0;
              text-align: center;
              padding: 0 12px;
            }
            h4 {
              font-size: 21px;
              font-weight: bold;
            }
            p {
              margin: 16px 0 32px 0;
            }

            .mkt-learn-more-btn + img {
              max-width: 800px;
              max-height: 660px;
              display: block;
              object-fit: cover;
              margin-top: 16px;
            }
            & > img:last-of-type {
              display: none;
            }
          }
          .mkt-addons {
           display: flex;
           justify-content: space-around;
           align-items: flex-start;

           .mkt-pill-item-container {
            max-width: 350px;

            .pill-img {
              width: 100px;
              height: 100px;

              img {
                height: 100%;
                width: 100%;
              }
            }
           }
          }
      }
      .mkt-division {
        position: relative;
        margin: 30px 0;
        font-family: Gilmer-Medium;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.69;
        letter-spacing: 1.42px;
        color: #2e2e2e;
        text-align: center;
        &::after,
        &::before {
          content: '';
          display: block;
          position: absolute;
          height: 2px;
          background-image: linear-gradient(to right, #2e2e2e 0%, #2e2e2e 20%, transparent 50%);
          background-size: 8px 2px;
          background-repeat: repeat-x;
          width: 40%;
          top: 50%
        }
        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }
      
      .mkt-step-slider-content {
        .mkt-step-detail {
          display: none;
        }
        &.swiper-slide-active {
          .mkt-step-detail {
            display: flex;
            transition: all 0.6s ease-in;
          }
        }
      }
    }
  }
  .mkt-learn-more-btn {
    cursor: pointer;
    span {
      font-family: 'Gilmer-Bold', sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      letter-spacing: 0.65px;
      color: #2e2e2e;
      margin-right: 10px;
    }
  }
  .mkt-pill-item-container {
    width: 100%;
    display: flex;
    .pill-img {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        width: 70px;
        height: 70px;
        object-fit: contain;
      }
      img:last-of-type {
        display: none;
      }
    }
    .pill-text {
      flex: 1;
      padding-left: 30px;
      p, h4 {
        font-family: Gilmer-Regular;
        font-size: 16px;
        line-height: 1.69;
        color: #2e2e2e;
        margin: 0;
      }
      h4 {
        font-size: 21px;
        font-weight: 500;
      }
      span {
        font-size: 12px;
        line-height: 1.22;
        color: rgba(46,46,46,0.8)
      }
    }
    .pill-icons {
      display: flex;
      margin-top: 8px;
      align-items: center;
      i {
        display: inline-block;
        height: 26px;
        width: 26px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 8px;
        background-position: center;
      }
      .i-large {
        height: 36px;
        width: 36px;
      }
    }
  }

  .mkt-display-item-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    .display-img {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        width: 90px;
        height: 90px;
        object-fit: contain;
      }
    }
    .display-text {
      margin-top: 16px;
      p {
        font-family: Gilmer-Regular;
        font-size: 16px;
        line-height: 1.44;
        color: #2e2e2e;
        margin: 0;
        text-align: center;
      }
      p:first-of-type {
        font-weight: bold;
        font-family: Gilmer-Bold;
      }
    }
  }
  .mkt-as-seen-in-container,
  .mkt-signin-container {
    min-height: 370px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .mkt-as-seen-in-container {
    background: url('https://images.shaklee.com/meology/prenatal/mkt-as-seen-in.png') no-repeat center top;
    background-size: cover;
    .as-seen-in-text {
      font-family: Gilmer-Medium;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 10px;
      text-align: center;
      color: #1e1e1e;
      position: relative;
      margin-bottom: 30px;
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 70%;
        display: block;
        background-color: #2e2e2e;
        bottom: -16px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .as-seen-in-imgs img {
      max-height: 90px;
      &:first-of-type{
        margin-right: 90px;
      }
    }
  }
  .mkt-signin-container {
    background: url('https://images.shaklee.com/meology/prenatal/mkt-singnin.jpg') no-repeat center top;
    background-size: cover;
    position: relative;
    p {
      max-width: 1200px;
      object-fit: contain;
      font-family: SchnyderS-Demi;
      font-size: 40px;
      line-height: 1.3;
      letter-spacing: -0.56px;
      text-align: center;
      color: #173e35;
    }
    .sign {
      position: absolute;
      content: '';
      display: block;
      width: 290px;
      height: 140px;
      right: calc(50% - 600px);
      bottom: 0;
      transform: translateY(50%);
    }
  }
  .mkt-vitamin-container {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: url('https://images.shaklee.com/meology/prenatal/mkt_vitamin.jpg') no-repeat center center;
    background-size: cover;
    .mkt-vitamin-text {
      width: 50%;
      text-align: center;
      padding-right: 24px;
      & > div {
        max-width: 600px;
      }
      img {
        margin-bottom: 16px;
      }
      p {
        text-align: center;
      }
      .mkt-section-sub-title + p{
        font-family: Gilmer-Regular;
        font-size: 16px;
        line-height: 1.69;
        color: #2e2e2e;
      }
    }
  }
  .mkt-display-container {
    &.expert {
      background-color: #efedde;
      min-height: 340px;
      
    }
    .mkt-display-items {
      max-width: 1200px;
      margin: 0 auto;
      padding-top: 50px;
      display: grid;
      &.expert-items {
        grid-column-gap: 30px;
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
  .mkt-nutrition-container {
    max-width: 1200px;
    height: 85em;
    margin: 0 auto;
    padding: 116px 0 11.875em;
    position: relative;
    background: url(https://images.shaklee.com/meology/prenatal/baby_s_home_x1.jpg) no-repeat center center;
    background-size: contain;
    background-origin: content-box;
    .mkt-section-title {
      position: absolute;
      width: 50%;
      text-align: left;
      top: 25%;
      padding-left: 20px;
    }
    .mkt-section-sub-title {
      & + p {
        font-family: Gilmer-Regular;
        font-size: 16px;
        line-height: 1.69;
        letter-spacing: 0.65px;
        color: #2e2e2e;
        max-width: 345px;
      }
    }
    .text-1,
    .text-2,
    .text-3 {
      position: absolute;
      width: 30%;
    }
    .text-1 {
      right: 0;
      top: 21.25em;

      .mkt-section-sub-title {
        @media screen and (max-width: $xxs-width) {
          width: calc(100vw - 30px);
        }
      }
    }
    .text-2 {
      left: calc(50% - 2.5em);
      transform: translateX(-50%);
    }
    .text-3 {
     top: 57.5em;
     left: 3.75em;
    }

    .cta-button {
      position: absolute;
      bottom: 3em;
      left: 50%;
      transform: translateX(-50%);
      min-width: auto;
      width: fit-content;
      padding: 15px 30px;
    }
  }
  .mkt-review-container {
    max-width: 1200px;
    margin: 0 auto;
    .mkt-review-detail {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      margin: 0 auto;
      padding: 90px;
      justify-content: center;
      align-items: center;
      .left {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        img {
          width: 370px;
          height: 430px;
          object-fit: contain;
        }
      }
      .right  {
        text-align: center;
        img {
          height: 50px;
          object-fit: contain;
        }
        p {
          margin-top: 16px;
          font-family: Gilmer-Regular;
          font-size: 24px;
          line-height: 1.73;
          text-align: center;
          color: #2e2e2e;
        }
      }
    }
  }
  .custom-slider-container {
    height: 100%;
    display: grid;
    grid-template-columns: 110px 1fr;
    grid-column-gap: 20px;
    margin-right: 30px;

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .custom-slider-prevew {
      display: block;
      .view-item {
        filter: opacity(0.45);
        cursor: pointer;
        height: 110px ;
        width: 110px ;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        img {
         height: 100% ;
         width: 100% ;
       }
      }
      .view-item-active {
        filter: opacity(1);
        cursor: default;
      }
    }
    .custom-slider-current {
      max-width: 585px;
      .swiper-container {
        height: 100%;
      }
      .curent-item {
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
  .mkt-review-container,
  .mkt-prenatal-step-container,
  .banner-container.prenatal {
    .swiper-button-next {
      right: 10px !important;
    }
    .swiper-button-prev {
      left: 10px !important;
    }
    .swiper-button-prev::after, 
    .swiper-button-next::after {
       background-image: url(../images/button.svg);
       background-size: contain;
    }
    .swiper-button-prev, 
    .swiper-button-next {
        background: #0000006b;
        border-radius: 50%;
        backdrop-filter: blur(2px);
    } 
    
  }
  .swiper-kids-container {
    margin: 60px auto;
    width: 1140px;
    .slide-pic {
      height: 560px;
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 40% 60%;
      background-color: #eef8e5;
      .img {
        img {
          width: 371px;
        }
      }
      .text {
        .star {
          img {
            height: 50px;
          }
        }
        .description {
          margin-top: 45px;
          font-family: 'Gilmer-Regular';
          font-size: 24px;
          line-height: 40px;
          max-width: 470px;
        }
        .name {
          font-size: 20px;
          font-weight: 500;
          line-height: 50px;
          letter-spacing: 0.71px;
        }
      }
    }
  }
  .personal-container {
    background-color: #e6e5dd;
    .personal {
      width: 1140px;
      margin: 0 auto;
      padding: 100px 0 50px;
      display: grid;
      justify-items: center;
      .title {
        font-family: 'SchnyderS-Demi', sans-serif;
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.27px;
        text-align: center;
        color: #333333;
      }
      .sub_title {
        margin: 30px 0;
        font-family: 'Gilmer-Regular', sans-serif;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.73px;
        text-align: center;
        color: #2e2e2e;
        width: 700px;
      }
      .img-container {
        width: calc(100% - 60px);
        img {
          width: 100%;
        }
      }
    }
    .real-container {
      background: url('https://images.shaklee.com/meology/marketing/lets-get-real.png')
        no-repeat bottom center #c3edda;
      background-size: contain;
      padding-top: 160px;
      .real {
        width: 1140px;
        margin: 0 auto;
        height: 640px;
        .text {
          width: 50%;
          display: grid;
          justify-items: center;
          .title {
            font-family: 'SchnyderS-Demi', sans-serif;
            font-size: 90px;
            line-height: 90px;
            letter-spacing: -1.27px;
            text-align: center;
            color: #333333;
          }
          .sub_title {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.98px;
            text-align: center;
            color: #2e2e2e;
            margin: 24px 0;
          }
          .context {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0.73px;
            text-align: center;
            color: #2e2e2e;
          }
        }
      }
    }
  }
  .life-time-container {
    background: linear-gradient(
      to bottom,
      #ffffff,
      #ffffff 283px,
      #e7f2f7 283px,
      #e7f2f7
    );
    .life-time {
      width: 1140px;
      margin: 0 auto;
      padding: 100px 0 80px 0;
      .banner {
        background: url('https://images.shaklee.com/meology/marketing/lifetime-proven-benefits.png?v=1');
        background-size: cover;
        height: 327px;
        font-family: 'SchnyderS-Demi';
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.27px;
        text-align: center;
        color: #333333;
        padding: 0 50px;
        display: flex;
        align-items: center;
      }
      .text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 40px;
        .left {
          padding-right: 20px;
          .desc {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.65px;
            color: #2e2e2e;
          }
          .learn_more {
            margin-top: 20px;

            span {
              font-family: 'Gilmer-Regular', sans-serif;
              font-size: 16px;
              font-weight: bold;
              line-height: 30px;
              letter-spacing: 0.65px;
            }
          }
        }
        .right {
          .card {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            border: 0;
            border-radius: 0;
            &-item {
              padding: 30px 10px;
              &-percentage {
                font-family: 'SchnyderS-Demi', sans-serif;
                font-size: 36px;
                line-height: 36px;
                text-align: center;
                color: #333333;
              }
              &-text {
                margin-top: 13px;
                font-family: 'Gilmer-Medium', sans-serif;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.49px;
                text-align: center;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
  .good-stuff-bg-container {
    background-color: $white;
    .good-stuff-container {
      width: 1140px;
      padding: 60px 0 100px 0;
      margin: 0 auto;
      justify-items: center;
      display: grid;

      .good-stuff-title {
        width: 750px;
        font-family: 'SchnyderS-Demi';
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.27px;
        text-align: center;
        color: #333333;
      }
      .good-stuff-section {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        width: 1000px;
        justify-items: center;
        grid-row-gap: 90px;
        margin-top: 60px;
        align-items: flex-start;
        &-item {
          display: grid;
          justify-items: center;
          max-width: 160px;
          &-img {
            display: grid;
            align-items: center;
            justify-items: center;
            img {
              width: 150px;
            }
          }

          &-context {
            margin-top: 27px;
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: #2e2e2e;
          }
        }
      }
    }
  }
  .packaged-container {
    .packaged {
      margin: 0 auto 60px;
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1140px;
      height: 1238px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-image: url('https://images.shaklee.com/meology/images/adults_marketing_en_desktop_packaged_with_purpose.jpg');
      .title {
        font-family: 'SchnyderS-Demi', sans-serif;
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.27px;
        text-align: center;
        color: #2e2e2e;
      }
      .sub_title {
        text-align: center;
        margin-top: 18px;
        font-family: 'Gilmer-Medium', sans-serif;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.73px;
        color: #2e2e2e;
      }
    }
    &.spanish .packaged {
      background-image: url('https://images.shaklee.com/meology/images/adults_marketing_es_desktop_packaged_with_purpose.jpg');
    }
  }
  .packaged-container.kids {
    .packaged {
      background: url('https://images.shaklee.com/meology/images/kids_marketing_en_desktop_packaged_with_purpose.jpg')
        no-repeat center top #ebebeb;
      background-size: contain;
      height: 982px;
    }
    &.spanish {
      .packaged {
        background-image: url('https://images.shaklee.com/meology/images/kids_marketing_es_desktop_packaged_with_purpose.jpg');
      }
    }
  }
  .packaged-container.prenatal {
    .packaged {
      background-image: url(https://images.shaklee.com/meology/images/prenatal_marketing_en_desktop_packaged_with_purpose.jpg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
      height: 76em;

      @media screen and (max-width: 767px) {
          background-image: url('https://images.shaklee.com/meology/images/prenatal_marketing_en_mobile_packaged_with_purpose.jpg');
          background-position: center 80px;
          height: 960px;
      }
      
      @media screen and (max-width: $xxs-width) {
        background-position: 50% 100%;
      }

      .cta-button {
        margin-top: 30px;
        min-width: unset;
        width: unset;
        padding: 15px 30px;
      }
    }
    &.spanish {
      .packaged {
        background-image: url('https://images.shaklee.com/meology/images/prenatal_marketing_es_desktop_packaged_with_purpose.jpg');
      }
    }
  }
  .safe-proven-container {
    .safe-proven {
      width: 100%;
      height: 90em;
      margin: 0 auto;
      background: url('https://images.shaklee.com/meology/images/landing/desktop/meology-difference.png');
      background-position: center;
      background-size: cover;
      padding: 60px 0 100px 0;
      position: relative;

      .title {
        line-height: 90px;
        font-family: SchnyderS-Demi;
        font-size: 90px;
        color: #2e2e2e;
        text-align: center;
      }
      .safe-proven-text {
        position: relative;
        margin-top: 20px;
        .section {
          position: absolute;
          .header {
            font-family: 'SchnyderS-Demi';
            font-size: 50px;
            line-height: 50px;
            color: #000000;
          }
          .context {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.65px;
            color: #2e2e2e;
            max-width: 21.875em;
            margin-top: 10px;
          }
          &:nth-child(1) {
            right: 60px;
            width: 350px;
            top: 100px;
          }
          &:nth-child(2) {
            left: 200px;
            width: 315px;
            top: 210px;
          }
          &:nth-child(3) {
            right: 50px;
            width: 15.625em;
            top: 28.125em;
          }
          &:nth-child(4) {
            left: 100px;
            width: 12.5em;
            top: 45em;
          }
          &:nth-child(5) {
            left: calc(50% + 100px);
            width: 23.4375em;
            top: 62.5em;
          }
        }
      }
      .cta-button-wrap4 {
        bottom: 20px;
        &.prenatal {
          .cta-button {
            min-width: unset;
            width: unset;
            padding: 15px 30px;
          }
        }
      }
    }
  }
  .difference-container {
    .difference {
      width: 1140px;
      height: 1140px;
      margin: 0 auto;
      background: url('https://images.shaklee.com/meology/marketing/meology-difference-kids.png')
        no-repeat bottom center;
      background-size: cover;
      padding: 60px 0 100px 0;
      position: relative;

      .title {
        line-height: 90px;
        font-family: SchnyderS-Demi;
        font-size: 90px;
        color: #2e2e2e;
        text-align: center;
      }
      .difference-text {
        position: relative;
        margin-top: 20px;
        .section {
          position: absolute;
          .header {
            font-family: 'SchnyderS-Demi', sans-serif;
            font-size: 50px;
            line-height: 50px;
            color: #000000;
          }
          .context {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.65px;
            color: #2e2e2e;
            max-width: 350px;
            margin-top: 10px;
          }

          &:nth-child(1) {
            left: 200px;
            width: 250px;
            top: 50px;
          }
          &:nth-child(2) {
            right: 150px;
            width: 270px;
          }
          &:nth-child(3) {
            left: 70px;
            width: 300px;
            top: 300px;
          }
          &:nth-child(4) {
            right: 30px;
            width: 300px;
            top: 300px;
          }
        }
      }
    }
  }
  .more-container {
    background-color: #ebe9de;
    .more {
      width: 1140px;
      height: 385px;
      margin: 0 auto;
      display: grid;
      justify-items: center;
      align-content: center;
      .title {
        font-family: 'SchnyderS-Demi';
        font-size: 84px;
        line-height: 84px;
        text-align: center;
        color: #2e2e2e;
        position: relative;
        img {
          position: absolute;
          right: -272px;
          top: -80px;
        }
      }
      .learn_more {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: .65px;
        line-height: 1.5;
      }
    }
  }
  .more-container.kids {
    background-color: #fffbe5;
  }
  .more-container.prenatal {
    background-color: #d4d8c4;
  }
  .social-container {
    .social {
      width: 1140px;
      margin: 0 auto;
      display: grid;
      justify-items: center;
      align-content: center;
      .title {
        font-family: 'SchnyderS-Demi';
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.27px;
        text-align: center;
        color: #333333;
        margin: 75px 0;
        &.prenatal-title {
          position: relative;

          @media screen and (max-width: 767px) {
            font-size: 55px;
          }

          @media screen and (max-width: $xxs-width){
            font-size: 40px;
          }

          &::before {
            content: attr(data-text);
            display: inline-block;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 6px);
            font-family: 'Gilmer-Medium', sans-serif;
            font-size: 18px;
            line-height: 1.5;
            letter-spacing: 0.57px;
            color: #2e2e2e;

            @media screen and (max-width: 767px) {
              font-size: 16px;
              width: 100%;
            }
          }

        }
      }
      .pic-container {
        display: grid;
        grid-template-columns: repeat(4, 270px);
        grid-template-rows: repeat(2, 270px);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        justify-items: center;
        align-items: center;
        &-item {
          img {
            width: 270px;
            height: 270px;
          }
        }
      }
    }
  }
  .yummy-container {
    background-color: #f4faf6;
    .yummy {
      width: 1140px;
      margin: 0 auto;
      padding: 88px 0;
      display: grid;
      justify-items: center;
      .title {
        width: 700px;
        font-family: 'SchnyderS-Demi', sans-serif;
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.87px;
        text-align: center;
        color: #333333;
      }
      .sub_title {
        margin-top: 17px;
        font-family: 'Gilmer-Regular', sans-serif;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: normal;
        text-align: center;
        color: #2e2e2e;
      }
      .img-container {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .stack-container {
    .stack {
      width: 1140px;
      margin: 0 auto;
      display: grid;
      justify-items: center;
      .title {
        width: 900px;
        font-family: 'SchnyderS-Demi';
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.87px;
        text-align: center;
        color: #333333;
        margin-top: 71px;
      }
      .sub_title {
        font-family: 'Gilmer-Regular', sans-serif;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: normal;
        text-align: center;
        color: #2e2e2e;
        margin: 30px 0;
      }
      .img-container {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .disclaimer {
        margin-top: 40px;
        text-align: center;
        p {
          color: #2e2e2e;
        }
      }
    }
  }
  .fun-container {
    /*margin: 60px auto;
    background: url('https://images.shaklee.com/meology/marketing/kids-hands-with-sachet.jpg?v=1')
      no-repeat center;
    background-size: cover;
    height: 810px;
    display: flex;
    .all {
      width: 50%;

      .title {
        font-family: 'SchnyderS-Demi', sans-serif;
        font-size: 11.5vh;
        line-height: 0.93;
        color: #2e2e2e;
        overflow: hidden;
      }
      .list {
        list-style: none;
        font-family: 'Gilmer', sans-serif;
        font-size: 4vh;
        line-height: 2;
        letter-spacing: 0.45px;
        text-align: right;
      }
      &.fun {
        text-align: right;
        padding: 10% 23% 0 0;
        .title {
          color: #026854;
        }
        .list {
          color: #026854;
          margin: 30px 2px 0 0;
        }
      }
      &.fuss {
        padding: 10% 0 0 23%;
        .list {
          color: #2e2e2e;
          text-align: left;
          margin: 30px 2px 0 0;
          padding: 0;
        }
      }
    }*/
    img {
      width: 100%;
    }
  }
  .extra-bg-container {
    .extra-container {
      width: 1140px;
      padding: 60px 0 100px 0;
      margin: 0 auto;
      justify-items: center;
      display: grid;

      @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 60px 20px;
      }

      @media screen and (max-width: $xxs-width) {
        padding: 60px 10px;
      }

      .extra-title {
        width: 750px;
        font-family: 'SchnyderS-Demi';
        font-size: 90px;
        line-height: 1;
        letter-spacing: -1.27px;
        text-align: center;
        color: #333333;

        @media screen and (max-width: 1200px) {
          font-size: 60px;
          width: 100%;
        }

        @media screen and (max-width: $xxs-width) {
          font-size: 40px;
        }
      }
      .extra-section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        width: 1000px;
        justify-items: center;
        grid-row-gap: 90px;
        margin-top: 60px;

        @media screen and (max-width: 1200px) {
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-row-gap: 20px;
        }

        &-item {
          max-width: 230px;
          &-img {
            img {
              height: 80px;
              display: block;
              margin: 0 auto;
              object-fit: contain;

              @media screen and (max-width: 480px) {
                height: 60px;
              }
            }
          }
          &-header {
            font-family: 'Gilmer-Bold', sans-serif;
            font-size: 20px;
            line-height: 1.5;
            letter-spacing: 1.25px;
            text-align: center;
            color: #000000;
            margin-top: 23px;
            text-transform: uppercase;

            @media screen and (max-width: $xxs-width) {
              font-size: 16px;
            }
          }
          &-context {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            color: #3e3e3e;
            margin-top: 14px;

            @media screen and (max-width: $xxs-width) {
              font-size: 14px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .disclaimer-container {
    .disclaimer {
      width: 1140px;
      margin: 10px auto;

      p {
        font-family: 'Gilmer-Regular', sans-serif;
        font-size: 14px;
        line-height: 1.86;
        letter-spacing: 0.21px;
        color: #2e2e2e;
        text-align: left;
        a {
         color: #0072ee;
        }
      }
    &.prenatal-dcm {
      p {
        padding: 0 12px;
      }
      p:first-of-type {
        border: solid 1px #979797;
      }
    }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .market-container {
    .marketing-btn-container {
      .marketing-btn {
        width: 100%;
      }
    }
    .brand-container {
      .brand {
        width: 100%;
      }
    }
    .banner-container {
      margin-top: 40px;
      background: linear-gradient(
        to bottom,
        $white,
        $white 65px,
        rgba(2, 104, 84, 0.4) 65px,
        rgba(2, 104, 84, 0.4) 400px,
        $white 400px,
        $white
      );
      .banner {
        width: 100% !important;
        .title {
          width: 100%;
          font-size: 65px;
          line-height: 65px;
          padding: 0 20px;
          max-width: 420px;
        }
        .media {
          width: 100% !important;
          height: 185px;
		      margin: 23px auto;
        }
        .sub_title {
          font-size: 50px;
          line-height: 50px;
          padding: 0 20px;
          margin-top: 20px;
          span, h2 {
            display: inline;
            &:nth-child(1) {
              width: 100%;
            }
            br {
              display: none;
            }
          }
          .desc {
            position: initial;
            width: 100%;
            font-size: 16px;
            margin-top: 18px;
          }
        }
        .how-it-works-text {
          // &::before {
          //   width: 171px;
          //   height: 345px;
          //   top: 0;
          //   bottom: initial;
          //   background-image: url('https://images.shaklee.com/meology/images/landing/mobile/mobile_hand.png');
          // }
          background-color: $white;
          display: grid;
          grid-template-rows: repeat(3, auto);
          grid-template-columns: repeat(1, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 20px;
          width: 100%;
          justify-items: center;
          padding: 30px 20px;
          .section {
            width: 100%;
            max-width: 415px;
          }
        }
      }
    }
    .banner-container.kids {
      background: linear-gradient(
        to bottom,
        $white,
        $white 65px,
        #d6efbe 65px,
        #d6efbe 400px,
        $white 400px,
        $white
      );
      .banner {
        .title {
          width: 100%;
          max-width: none;
        }
      }
      .all-in-one-container {
        padding: 0 20px;
        margin-top: 20px;
        .all-in-one {
          width: 100%;
          text-align: center;
          padding-bottom: 100px;
          .text {
            width: 100%;
            font-size: 50px;
            line-height: 50px;
          }
        }
      }
      .blue-bg-container {
        .blue-bg {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          img {
            align-self: flex-start;
            width: 100%;
            //max-width: 418px;
          }
          .text {
            padding: 0 20px;
            display: block;
            position: static;
            width: 100%;
			height: 300px;
            .title {
              margin-top: 55px;
              font-family: 'SchnyderS-Demi', sans-serif;
              font-size: 50px;
              line-height: 50px;
              letter-spacing: -0.77px;
              color: #333333;
              width: 100%;
              text-align: center;
            }
            .sub_title {
              margin: 40px auto 0;
              font-family: 'Gilmer', sans-serif;
              font-size: 16px;
              line-height: 23px;
              letter-spacing: 0.65px;
              color: #2e2e2e;
              width: 90%;
              text-align: center;
            }
          }
        }
      }
    }
    .banner-container.prenatal {
      background: linear-gradient(to bottom, #FFFFFF, #FFFFFF 65px, #fff8f2 65px, #fff8f2 400px, #FFFFFF 400px, #FFFFFF);
    }
    .swiper-kids-container {
      width: 100%;
      margin: 0;
      .swiper-button-next,
      .swiper-button-prev {
        width: 52px;
        height: 52px;
        &::after {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          background-size: cover;
          opacity: 0.85;
        }
      }
      .swiper-button-prev {
        &::after {
          box-shadow: -1px -1px 5px #888888;
        }
        left: 0 !important;
      }
      .swiper-button-next {
        &::after {
          box-shadow: 1px 1px 5px #888888;
        }
        right: 0 !important;
      }
      .slide-pic {
        grid-template-columns: repeat(1, 1fr);
        height: auto;
        padding: 64px 20px;
        .img {
          img {
            width: 186px;
          }
        }
        .text {
          .star {
            display: flex;
            justify-content: center;
            img {
              height: 35px;
              margin-top: 20px;
            }
          }
          .description {
            text-align: center;
            font-size: 16px;
            line-height: 27px;
            max-width: 100%;
          }
          .name {
            text-align: center;
            font-size: 16px;
            line-height: 27px;
          }
        }
      }
    }
    .personal-container {
      .personal {
        width: 100%;
        padding: 54px 0px;
        .title {
          font-size: 65px;
          line-height: 65px;
        }
        .sub_title {
          font-size: 16px;
          line-height: 20px;
          width: 100%;
          padding: 0 20px;
        }
        .img-container {
          width: 90%;
        }
      }
      .real-container {
        background: url('https://images.shaklee.com/meology/marketing/mobile-lets-get-real-personal.png')
          no-repeat bottom center #c3edda;
        background-size: contain;
		padding: 0;
        .real {
          width: 100%;
          height: 1065px;
          padding-top: 40px;
          .text {
            width: 100%;
            padding: 0 20px;
            .title {
              font-size: 65px;
              line-height: 65px;
            }
            .sub_title {
              font-size: 24px;
              line-height: 24px;
            }
            .context {
              font-size: 16px;
            }
          }
        }
      }
    }
    .life-time-container {
      background: linear-gradient(
        to bottom,
        #ffffff,
        #ffffff 190px,
        #e7f2f7 190px,
        #e7f2f7
      );
      .life-time {
        width: 100%;
        padding: 0;
        .banner {
          background: url('https://images.shaklee.com/meology/marketing/mobile-lifetime-proven.png?v=1')
            no-repeat;
          background-size: cover;
          font-size: 53px;
          line-height: 55px;
          padding: 0 20px;
          height: 190px;
        }
        .text {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(2, auto);
          padding: 0 20px;
          margin-top: 15px;
          .right {
            margin: 30px 0 70px 0;
            .card {
              grid-template-columns: repeat(1, 1fr);
              grid-template-rows: repeat(4, auto);

              .card-item-text {
                width: 218px;
                margin: 13px auto 0;
              }
            }
          }
        }
      }
    }
    .good-stuff-bg-container {
      .good-stuff-container {
        width: 100%;
        padding: 60px 20px 100px 20px;
        .good-stuff-title {
          font-size: 61px;
          line-height: 61px;
          width: 100%;
        }
        .good-stuff-section {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(4, 1fr);
          justify-items: center;
          grid-row-gap: 20px;
          margin-top: 60px;
          &-item {
            display: grid;
            justify-items: center;
            &-context {
              font-size: 19px;
              line-height: normal;
              margin-top: 22px;
            }
            &-img {
              width: 121px;
              height: 121px;
              display: grid;
              align-items: center;
              justify-items: center;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .packaged-container {
      .packaged {
        width: 100%;
        padding: 25px 10px;
        margin-bottom: 0;
        background: url('https://images.shaklee.com/meology/images/adults_marketing_en_mobile_packaged_with_purpose.jpg')
          no-repeat center bottom #d8eadf;
        background-size: contain;
        height: 920px;
        .title {
          font-size: 63px;
          line-height: 65px;
        }
        .sub_title {
          width: 100%;
          padding-bottom: 50px;
        }
      }
      &.spanish {
        .packaged {
          background: url('https://images.shaklee.com/meology/images/adults_marketing_es_mobile_packaged_with_purpose.jpg')
            no-repeat bottom #d8eadf;
          background-size: contain;
          height: 886px;
        }
      }
      &.prenatal {
        padding-top: 60px;
      }
      
      &.kids {
        .packaged {
          height: 850px;
          background-color: #ebebeb;
        }

        &.spanish {
          .packaged {
            background-size: contain;
            height: 885px;
          }
        }
      }
    }
    .safe-proven-container {
      .safe-proven {
        width: 100%;
        height: unset;
        background: url('https://images.shaklee.com/meology/marketing/mobile-difference-mobile.png?v=1')
          no-repeat center top;
        background-size: cover;
        padding: 60px 0 100px 0;

        .title {
          line-height: 1;
          font-size: 50px;
        }
        .safe-proven-text {
          display: flex;
          flex-direction: column;
          padding: 0 20px;

          .section {
            position: static;
            width: calc(100vw - 40px) !important;
            left: 20px !important;
            margin-top: 40px;

            > * {
              margin: 0;
              padding: 0;
            }

            .header {
              font-family: 'SchnyderS-Demi', sans-serif;
              font-size: 38px;
              line-height: 1;
              color: #000000;
            }
            .context {
              font-family: 'Gilmer-Regular', sans-serif;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0.65px;
              color: #2e2e2e;
            }
          }
        }
        .cta-button-wrap4.prenatal {
          .cta-button {
            height: 46px;
          }
        }
      }
    }
    .more-container {
      .more {
        width: 100%;
        .title {
          font-size: 65px;
          line-height: 65px;
          img {
            width: 161px;
            height: 114px;
            right: 20px;
            top: -70px;
          }
        }
      }
    }

    .social-container {
      .social {
        width: 100%;
        .title {
          font-size: 65px;
          line-height: 65px;
        }
        .pic-container {
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(4, 1fr);
          padding: 0 20px;
          &-item {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .yummy-container {
      .yummy {
        width: 100%;
        padding-top: 43px;
        .title {
          width: 100%;
          font-size: 65px;
          line-height: 65px;
          padding: 0 20px;
        }
        .sub_title {
          margin-top: 17px;
          font-size: 18px;
          line-height: 28px;
          padding: 0 20px;
        }
      }
    }
    .stack-container {
      .stack {
        width: 100%;
        .title {
          width: 100%;
          font-size: 65px;
          line-height: 65px;
          margin-top: 61px;
          padding: 0 20px;
        }
        .sub_title {
          margin-top: 17px;
          font-size: 18px;
          line-height: 28px;
          padding: 0 20px;
        }
        .disclaimer {
          margin-top: 20px;
          p {
            font-size: 8px;
            font-family: 'Gilmer-Regular', sans-serif;
            margin: 0 auto;
            width: 95%;
          }
        }
      }
    }
    .difference-container {
      .difference {
        width: 100%;
        height: 1540px;
        background: url('https://images.shaklee.com/meology/marketing/kids_mobile_safe.png')
          no-repeat center bottom;
        background-size: cover;
        padding: 60px 20px 100px 20px;

        .title {
          line-height: 65px;
          font-size: 65px;
        }
        .difference-text {
          display: grid;
          grid-template-rows: repeat(4, auto);
          .section {
            position: relative;
            .header {
              font-family: 'SchnyderS-Demi', sans-serif;
              font-size: 50px;
              line-height: 50px;
              color: #000000;
            }
            .context {
              font-family: 'Gilmer-Regular', sans-serif;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0.65px;
              color: #2e2e2e;
              max-width: 350px;
              margin-top: 10px;
            }
            &:nth-child(1) {
              left: 0;
              justify-self: flex-start;
              top: 0;
            }
            &:nth-child(2) {
              left: 0;
              justify-self: flex-end;
              top: 25px;
            }
            &:nth-child(3) {
              left: 0;
              justify-self: flex-start;
              top: 50px;
            }
            &:nth-child(4) {
              left: 0;
              justify-self: flex-end;
              top: 240px;
              width: 50%;
              word-wrap: break-word;
            }
          }
        }
      }
    }
    .disclaimer-container {
      .disclaimer {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
      }
    }
    .mkt-review-container,
    .mkt-prenatal-step-container,
    .banner-container.prenatal {
      .swiper-button-prev,
      .swiper-button-next {
        &, &::after {
          height: 52px !important;
          width: 52px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .market-container {
    .packaged-container {
      .packaged {
        height: 2200px;
      }
    }
    .banner-container {
      .banner {
        .media {
          height: 430px;
        }
      }
    }
    .banner-container.prenatal {
      .banner {
        .media {
          padding-left: calc(50% - 400px);
        }
      }
    }
    .brand-container {
      .brand {
        padding: 30px;
      }
    }
    .banner-container.kids {
      .all-in-one-container {
        .all-in-one {
          .bg {
            width: 100%;
          }
        }
      }
    }
    .thumbs-container {
      padding: 0 20px;
    }
    .cta-button {
      width: unset;
      min-width: 250px
    }
    .mkt-prenatal-step-container .mkt-content-container .mkt-step-detail-items {
      margin-right: 12px;
    }
    .mkt-signin-container {
      .sign {
        right: 60px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .market-container {
    .mkt-prenatal-step-container .mkt-content-container {
      .mkt-step-detail-pills {
        &::after,
        &::before {
          top: 40%;
        }
      }
      .mkt-step-detail-top {
        padding: 16px 20px 16px 18px;
      }

      .mkt-step-detail > .step-imgs-container > div::after {
        height: 80px;
        width: 80px;
      }

    }
    .thumbs-container {
      flex-flow: column;
      .custom-slider-container {
        height: unset;
        .custom-slider-current {
          width: 100%;
          max-width: unset;
          .curent-item img {
            max-width: 585px;
            max-height: 585px;
            margin: 0 auto;
          }
        }
      }
      .thumbs-text {
        padding-top: 30px;
        .mkt-section-title {
          padding-left: 0;
          margin-top: 16px;
        }
      }
    }

    .mkt-display-container .mkt-display-items.expert-items {
      grid-column-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 30px;
      padding-bottom: 50px;
  }
  }
}
@media only screen and (max-width: 767px) {
  .market-container {
    .brand-container {
      &.brand-container-prenatal {
        background: #173e35;
       .brand .brand-icon {
          img:last-of-type {
            display: inline-block;
          }
          img:first-of-type {
            display: none;
          }
       }
       .brand .btn-container .cta-button {
        height: 34px;
        line-height: 1;
        border-radius: 2px;
        border: solid 1px #fff;
        font-size: 12px;
        padding: 15px 4px;
       }
       .brand .meology-toggle {
          border-color: #fff;
          color: #fff;
      
          li {
            &:nth-child(2) {
              border-color: #fff;
            }
      
            &.active {
              background-color: #fff;
              color: $shaklee_green;
            }
          }
       }
      }
      .brand {
        width: 100%;
        padding: 0;
        height: 50px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        grid-template-rows: 50px;
        grid-template-columns: auto 1fr auto;
        padding: 0 14px;

        .brand-icon {
          width: 24px;
          height: 34px;

          &-white {
            background-image: url('https://images.shaklee.com/meology/prenatal/back-btn.svg');
          }
        }
      }
      .brand-title {
        img {
          width: 102px;
        }
        &.brand-logo-text {
          img:first-of-type {
            display: none;
          }
          img:last-of-type {
            display: inline-block;
          }
          &::before {
            display: none;
          }
          & + .btn-container .cta-button {
            min-width: 100%;
            max-width: 120px;
            margin: 0;
          }
        }
      }
      .btn-container {
        .home-button-1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px !important;
          height: 50px !important;
          line-height: 20px !important;
          margin: 0 !important;
          border-radius: 0px !important;
        }
      }
    }
    .packaged-container.kids {
      .packaged {
        background-image: url('https://images.shaklee.com/meology/images/kids_marketing_en_mobile_packaged_with_purpose.jpg');
        background-size: cover;
      }

      &.spanish {
        .packaged {
          background-image: url('https://images.shaklee.com/meology/images/kids_marketing_es_mobile_packaged_with_purpose.jpg');
        }
      }
    }
    .packaged-container.prenatal.spanish,
    .packaged-container.prenatal {
      height: unset;
      &.spanish {
        .packaged {
          background-image: url('https://images.shaklee.com/meology/images/prenatal_marketing_es_mobile_packaged_with_purpose.jpg');
        }
      }
    }
    .banner-container.kids {
      .all-in-one-container {
        .all-in-one {
          .bg {
            width: 100%;
            bottom: 0;
            left: 0;
            top: auto;
          }
        }
      }
    }
    .banner-container.prenatal {
       background: linear-gradient(to bottom, #FFFFFF 280px, #fff8f2 280px );
       .copy-page-link-marketing-page {
        top: 0
      }
      .brand-logo-text {
        display: block;
        position: relative;
        text-align: center;
        margin-bottom: 90px;
        img {
          height: 54px;
        }
      }
      .brand-logo-text::before {
        content: attr(data-text);
        display: inline-block;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 6px);
        font-family: 'Gilmer-Bold', sans-serif;
        font-size: 16px;
        color: #2e2e2e;
      }
      .banner {
        .media {
          height: auto;
          padding: 0;
          background-size: contain;
          position: relative;
          padding-bottom: calc(50% + 220px);
          background-position: top center;
          .media-detail {
            align-items: flex-start;
            position: absolute;
            height: 100%;
            justify-content: flex-end;

            .prenatal-logo {
              order: 1;
              height: 100px;
              width: 100px;
              margin-left: 24px;
              margin-top: 0;
              position: absolute;
              top: calc((100% - 220px) / 2);
              transform: translateY(-50%);
            }
            p{ 
              order: 2;
              text-align: left;
            }
            .cta-text-button {
              order: 3;
              margin-left: 24px;
              margin-top: 16px;
            }
          }
        }
        .thumbs-container {
          margin-top: 30px;
          flex-flow: column;
          background: #fff;
          padding: 40px 8px;
          .custom-slider-container {
            grid-template-columns: 65px 1fr;
            grid-column-gap: 8px;
            height: unset;
            margin-right: 0;
            .custom-slider-prevew {
              .view-item {
                height: 65px;
                width: 65px;
              }
            }
            .custom-slider-current {
              .curent-item img {
                max-width: 365px;
                max-height: 365px;
                margin: 0 auto;
              }
            }
          }
          .thumbs-text {
            padding-top: 30px;
            .mkt-section-title {
              padding-left: 0;
              margin-top: 16px;
            }
          }
        }
      }
      .expert-container {
        background:#fff url('https://images.shaklee.com/meology/prenatal/mkt_b_3.jpg') no-repeat center center;
        background-size: cover; 
        background-origin: content-box;
        min-height: 550px;
        margin-top: 0;
        display: block;
        height: unset;
        .expert-logo {
          display: none;
        }
        .expert-text {
          width: 100%;
          margin-right: 0;
          padding-top: 270px;
          padding-bottom: 30px;

          p{
            text-align: center;
          }
        } 
      }
    }
    .mkt-prenatal-step-container .mkt-content-container {
      .mkt-step-header {
        p {
          text-align: center;
        }
      }
      .mkt-step-detail {
        margin-top: 30px;
        & > .step-imgs-container {
          position: relative;
          width: 100%;
          max-height: 400px;
          z-index: 1;
          &> div {
            max-height: 400px;
            height: 100%;
            width: 50%;
            align-self: flex-start;
            img:first-of-type {
              display: none;
            }
            img:last-of-type {
              display: inline-block;
              object-fit: contain;
              max-height: 400px;
            }
            &::after {
              right: 0px;
              top: 40%;
              width: 65px;
              height: 65px;
              left: unset;
              transform: unset;
              z-index: -1;
            } 
          }
          & > img:last-of-type {
            display: block;
            position: absolute;
            width: 50%;
            left: 50%;
            top: 60px;
            max-width: 300px;
          }
          & > img:first-of-type {
            display: none;
          }
        }
        .mkt-step-detail-items {
          width: 100%;
          margin-top: 0;
          max-width: unset;
        }
        .mkt-step-detail-top {
          padding: 0px 20px 0 18px;
          .pill-img img {
            object-fit: contain;
          }
        }
        .mkt-step-detail-pills {
          margin: 27px 10px 21px 9px;
          padding: 27px 10px 21px 9px;

          &::after {
            width: 1px;
            height: calc(25% + 150px);
            right: 16%;
            top: 0px;
            background: #2e2e2e;
            left: unset;
            transform: translateY(-100%);
          }
          &::before {
            right: calc(16% - 8px);
            left: unset;
            top: calc(-25% - 150px - 8px);
            transform: unset;
          }
        }
        .mkt-addons {
          flex-flow: column;
          align-items: center;
          .mkt-pill-item-container {
            margin-bottom: 20px;
          }
        }

        .mkt-step-meology {
          .mkt-learn-more-btn + img {
            display: none;
          }
          & > img:last-of-type {
            display: block;
            width: 100%;
          }
        }
      }
    }
    .mkt-display-container .mkt-display-items.expert-items {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 30px;
      padding: 50px 8px;
    }
    .mkt-nutrition-container {
      background: url(https://images.shaklee.com/meology/prenatal/baby_s_home_2.jpg) no-repeat center top ;
      background-size: contain;
      height: auto;
      display: block;
      padding-bottom: 374%;
      padding-top: 0;
      .mkt-section-title {
        position: absolute;
        width: 100%;
        top: 60px;
      }
      
      .text-1 {
        width: 55%;
        left: 12px;
        top: calc(40% + 24px);
      }
      .text-2 {
        right: 8px;
        width: 55%;
        margin-bottom: 0;
        top: 28%;
        transform: unset;
        left: unset;
        padding-left: 24px;
      }
      .text-3 {
        width: 55%;
        top: 75%;
        right: 12px;
        padding: 0;
        left: unset;
      }
      
  
      & + .cta-button {
        width: auto;
        min-width: auto;
        padding: 0 30px;
      }
    }
    .mkt-vitamin-container {
      background: #f7e5d7 url(https://images.shaklee.com/meology/prenatal/mkt_vitamin2.jpg) no-repeat center bottom;
      background-size: contain;
      height: 820px;
      padding-top: 60px;
      align-items: flex-start;
      .mkt-vitamin-text {
        width: 100%;
        padding: 0 12px;
        & > div {
          max-width: unset;
        }
        img {
          max-width: 100%;
        }
      }
    }
    .mkt-review-container {
      .mkt-review-detail {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 50px;

        .left {
          justify-content: center;
        }

        .right {
          img {
            height: 35px;
          }
          p {
            font-size: 18px;
            padding-top: 16px;
          }
        }
      }
    }
    .mkt-signin-container {
      background: url(https://images.shaklee.com/meology/prenatal/mkt_singnin2.jpg?v=01) no-repeat center top;
      background-size: cover;
      padding: 60px 8px 120px;
     
      p {
        line-height: 1.18;
      }

      .sign {
        right: 50%;
        transform: translate(50%,50%);
      }

    }
    .mkt-as-seen-in-container {
      background: #fff8f2 url(https://images.shaklee.com/meology/prenatal/mkt-as-seen-in2.png) no-repeat center top;
      background-size: contain;
      padding: 60px 8px;
      p {
        text-align: center;
        margin-top: 16px;
      }
      .as-seen-in-imgs img:first-of-type {
        margin-right: 20px;
    }
    }
    .mkt-review-container{
      .swiper-button-next,
      .swiper-button-prev {
        top: 30%
      }
    }
    .mkt-prenatal-step-container {
      .swiper-button-next,
      .swiper-button-prev {
        top: 12%;
      }
    }
    .mkt-pill-item-container {
      .pill-img {
        img:first-of-type {
          display: none;
        }
        img:last-of-type {
          display: block;
        }
      } 

      .pill-text {
        padding-left: 0;
      }
    }
  }
}
@media only screen and (max-width: 450px)  {
  .market-container {
    .brand-container {
      .brand.marketing-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 14px;
        
        & > .meology-toggle {
          display: none;
        }
      }
      & + .meology-toggle {
        display: flex;
        margin: 30px 0 0 14px;
      }
    }
    .mkt-prenatal-step-container .mkt-content-container .mkt-step-detail {
      .mkt-step-detail-pills {
        margin: 0 10px 0 9px;
        transform: translateY(-20px);
        &::after {
          right: 30px;
          height: calc(25% + 100px);
        }
        &::before {
          right: 22px;
          top: calc(-25% - 100px - 8px);
        }
      }
      .mkt-step-detail-top {
        transform: translateY(-60px);
        .pill-img img {
          object-position: top;
        }
      }

    }

    .banner-container.prenatal .banner .thumbs-container .custom-slider-container {
      height: auto;
      padding-bottom: calc(100% - 73px);
      position: relative;
      .custom-slider-prevew {
        width: 73px;
        position: absolute;
        height: 100%;
        display: flex;
        flex-flow:column ;
        justify-content: space-between;
        align-items: flex-start;
        left: 0;
        .view-item {
          max-width: 65px;
          max-height: 65px;
          height: 19%;
          margin-bottom: 0;
          width: auto;
          img {
            object-fit: contain;
          }
        }
      }
      .custom-slider-current {
        height: 100%;
        width: calc(100% - 73px);
        position: absolute;
        right: 0;
        .curent-item img {
          object-fit: contain;
        }
      }
    }

    .mkt-nutrition-container  {
      .mkt-section-sub-title {
        & + p {
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }
    
  }
}

.marketing-banner {
  margin-top: 20px;

  &-image {
    position: relative;
    padding-top: 56.25%;

    img {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
    }
  }
}

