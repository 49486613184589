.app-container {
  .header-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: $title;

    &-logo {
      font-size: 24px;
      color: $white;
      cursor: pointer;
    }
  }

  .intro-container {
    background-color: rgba(174, 200, 184, 0.5);
    overflow: hidden;

    .intro-section {
      position: relative;
      display: grid;
      min-height: 800px;
      width: 1200px;
      margin: 0 auto;
      color: $white;
      padding: 79px 0 90px 0;

      &::before {
        background: url('https://images.shaklee.com/meology/prenatal/home_sachet_left_desktop.png') no-repeat;
        width: 520px;
        height: 516px;
        left: -330px;
        top: 0
      }

      &::after {
        background: url('https://images.shaklee.com/meology/prenatal/home_sachet_right_desktop.png') no-repeat;
        width: 520px;
        height: 500px;
        right: -280px;
        top: 20px;
      }

      &::after,
      &::before {
        content: ' ';
        background-size: cover;
        display: block;
        position: absolute;
        z-index: 999;
      }

      .brand-logo {
        width: 290px;
        height: 74px;
      }

      &-card {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        text-align: center;

        &-regular,
        &-prenatal,
        &-kids {
          width: 400px;
          padding: 34px 0;

          .logo {
            height: 62px;
          }

          .title {
            font-family: 'SchnyderS-Demi', sans-serif;
            font-size: 65px;
            color: #050505;
            line-height: 1;
            max-width: 100%;
            overflow: hidden;
            margin: 20px 0;
          }

          .sub_title {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 16px;
            color: #2e2e2e;
            margin-bottom: 10px;
          }

          .btn-container {
            display: grid;
            justify-items: center;

            .home-button-1,
            .home-button-3,
            .home-button-2 {
              font-family: 'Gilmer-Bold', sans-serif;
              font-weight: bold;
              margin: 10px 0;
              border-radius: 3px;
              background-color: #173e35;
              width: 350px;
              height: 60px;
              line-height: 60px;
              color: $white;
              cursor: pointer;
              border: 0;
            }
          }

        }

        &-regular {
          position: relative;
          background-color: rgba(255, 255, 255, 0.9);
          justify-self: flex-end;
        }

        &-kids {
          position: relative;
          background-color: #edfede;
          justify-self: flex-start;

          .logo img {
            width: 160px;
          }
        }

        &-prenatal {
          background-color: #fff8f2
        }
      }

      &-card.two {
        grid-template-columns: repeat(2, 1fr);
        justify-self: center;
        margin-top: -25px;

        .intro-section-card-regular {
          background: none;
          padding: 0;
          height: 130px;
          width: 656px;
          display: inline-block;
          margin: 0;

          .btn-container {
            display: inline-flex;
          }

          .home-button-1,
          .home-button-3 {
            background-color: #fff;
            color: #173e35;
            font-family: 'Gilmer-Bold', sans-serif;
            font-weight: normal;
            font-size: 18px;
            margin-right: 15px;
            width: 305px;
            text-transform: uppercase;
          }

          .learn_more {
            font-family: 'Gilmer-Bold', sans-serif;
            border: solid 2px #fff;
            border-radius: 3px;
            color: #fff;
            padding: 15px 79px 40px;
            height: 30px;
            margin: 10px 15px;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: normal;
            width: 305px;
            display: inline-flex;
          }
        }
      }

      &-text {
        display: grid;
        justify-items: center;
        margin-bottom: 60px;

        &.ca-proveit {
          min-height: 870px;
        }

        &-title {
          @include registered-sign(.6em);
          font-family: 'Gilmer-Bold', sans-serif;
          font-size: 20px;
          text-align: center;
          color: #2e2e2e;
          letter-spacing: 1.17px;
          margin-top: 50px;
        }

        &-header {
          font-family: 'SchnyderS-Demi';
          font-size: 95px;
          line-height: 1;
          margin-top: 20px;
          letter-spacing: -1.9px;
          text-align: center;
          color: #2e2e2e;
          max-width: 922px;

          &-2 {
            font-size: 26px;
            line-height: 37px;
            margin-top: 14px;
            font-family: $font_suisse_light;
          }
        }

        &-context {
          font-size: 24px;
          line-height: 26px;
          font-family: 'Gilmer-Regular';
          max-width: 850px;
          margin-top: 20px;
          line-height: 1.54;
          text-align: center;
          color: #2e2e2e;
        }

        &-info {
          font-size: 26px;
          line-height: 37px;
          border-bottom: solid 1px aliceblue;
          padding-bottom: 37px;
        }

        .home-button-1,
        .home-button-3 {
          border-radius: 2px;
          padding: 15px 0;
          width: 360px;
          cursor: pointer;
          font-size: 20px;
          text-align: center;
          background-color: #fff;
          color: #006a52;
        }

        .home-button-3 {
          margin-top: 20px;
          border: 1px solid;
        }

        .home-button-2 {
          width: 362px;
          border: 1px solid;
          border-radius: 4px;
          background-color: $white;
          padding: 17px 0 24px 20px;
          cursor: pointer;
          display: flex;
          justify-content: flex-start;

          .chart {
            width: 60px;
            margin-right: 20px;
          }

          .chart-text {
            &-1 {
              font-size: 18px;
              color: #000000;
            }

            &-2 {
              font-size: 14px;
              color: #4a4a4a;
              letter-spacing: 0.5px;
              line-height: 22px;
            }

            &-3 {
              font-size: 14px;
              color: #000000;
              letter-spacing: 0.5px;
              line-height: 22px;
            }
          }
        }
      }

      .cannada-disclaimers {
        border: 1px solid #fff;
        max-width: 360px;
        font-size: 14px;
        padding: 20px;
        margin: 35px 0 0 0;

        &.prove-it {
          border: none;
          padding-left: 30px;
          width: 100vw;
          width: 1200px;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }

    &.hide-card {
      position: relative;

      .intro-section {
        min-height: 656px;

        .intro-section-card.one-item {
          grid-template-columns: repeat(1,1fr);
          justify-self: center;
          margin-top: -25px;
          background: transparent;
  
          .intro-section-card-regular {
            background: transparent;

            &::after {
              display: none;
            }
          }
        }
        .intro-section-card.two-items {
          grid-template-columns: repeat(2,1fr);
          grid-column-gap: 20px;
        }
      }
    }
  }

  .intro-container.log-in {
    padding: 38px 0 90px 0;
  }

  .intro-container.no-template {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }

    .intro-section {
      padding: 38px 30px;
      width: 1200px;
      margin: 0 auto;
      grid-template-rows: 80px auto;

      .intro-section-text {
        justify-items: flex-start;
        align-content: flex-start;
        margin-top: 80px;

        .brand-logo {
          width: 260px;
        }

        &-header,
        &-context {
          font-family: 'Gilmer-Regular', sans-serif;
          text-align: left;
        }
      }
    }
  }

  .intro-container.no-template.kids {
    background-color: $white;

    .intro-section-header {
      width: 1200px;
      margin: 0 auto;
      padding: 38px 30px;
    }

    .intro-section-bg {
      background: linear-gradient(to bottom,
          #d6efbe 570px,
          $white 570px,
          $white );

      .media {
        width: calc(1200px - 60px);
        margin: 0 auto;
        height: 681px;
        background: none;
        position: relative;
        padding: 56.25% 0 0 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .cta-container {
    .cta-section {
      display: grid;
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0;

      &-card {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        text-align: center;
        width: 100%;

        &-regular,
        &-prenatal,
        &-kids {
          width: 400px;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 74px 0 43px;

          .logo {
            height: 62px;
          }

          .title {
            font-family: 'SchnyderS-Demi';
            font-size: 65px;
            color: #050505;
            line-height: 90px;
          }

          .sub_title {
            max-width: 280px;
            height: 100px;
            margin: 10px auto;
            font-family: 'Gilmer-Medium', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.56;
            letter-spacing: 0.65px;
            text-align: center;
            color: #2e2e2e;
          }

          .btn-container {
            font-family: 'Gilmer-Bold', sans-serif;
            display: grid;
            justify-items: center;
            font-size: 18px;
            letter-spacing: 0.7px;

            .home-button-1,
            .home-button-3,
            .home-button-2 {
              margin: 10px 0;
              border-radius: 3px;
              background-color: #173e35;
              width: 350px;
              height: 60px;
              line-height: 60px;
              color: $white;
              cursor: pointer;
              border: 0;
            }
          }

        }

        &-regular {
          background: url('https://images.shaklee.com/meology/prenatal/M1.jpg?v=002');
          background-size: cover;
          justify-self: flex-end;
        }

        &-prenatal {
          background: url('https://images.shaklee.com/meology/prenatal/D2.jpg');
          background-size: cover;
          justify-self: flex-end;
        }

        &-kids {
          background: url('https://images.shaklee.com/meology/prenatal/D3.jpg');
          background-size: cover;
          justify-self: flex-start;

          .logo img {
            width: 160px;
          }
        }
      }
      &-card.two-items {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        .cta-section-card-kids,
        .cta-section-card-prenatal {
          justify-self: flex-start;
        }
      }
      &-card.two {
        min-height: 285px;
        .cta-section-card-regular {
          justify-self: left;
          width: 1200px;
          height: 284px;
          background: url('https://images.shaklee.com/meology/images/landing/desktop/action-buttons-bg.png?v=1') no-repeat;
          background-size: cover;
          max-width: unset;
        }
        .cta-section-card-regular.footer {
          background: url(https://images.shaklee.com/meology/images/landing/desktop/food-buttons-bg.png?v=1) center center no-repeat;
          background-size: cover;
          height: 100%;
        }

        .logo {
          margin-top: -10px;

          img {
            width: 250px;
          }
        }

        .btn-group {
          margin-top: 0;
          width: 670px;
          display: inline-block;
          margin: 0 auto;

          .btn-container {
            font-family: 'Gilmer-Bold', sans-serif;
            font-size: 18px;
            margin-right: 15px;
            display: inline-flex;

            .cta-button  {
              margin: 10px 16px;
              width: 305px;
              min-width: unset;
            }

            .home-button-1,
            .home-button-3 {
              width: 305px;
              text-transform: uppercase;
              margin-right: 15px;
              margin-left: 15px;
            }
          }
          .learn_more {
            display: inline-flex;
            font-family: 'Gilmer-Bold', sans-serif;
            border: solid 2px #fff;
            border-radius: 3px;
            color: #fff;
            padding: 17px 79px 40px;
            height: 30px;
            margin: 10px 15px;
            text-transform: uppercase;
            font-size: 18px;
          }
        }
      }
    }
  }

  &.no-quiz {

    ::before,
    ::after {
      background: none;
    }

    .intro-section-text {
      display: block;
    }
  }

  .personal-container {
    background-color: #c4ebd8;

    .personal-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-position: left;
      background-repeat: no-repeat;
      height: 800px;
      margin: 0 auto;
      width: 1200px;
      background-size: contain;
      color: $white;

      &-text {
        width: 508px;

        &-header {
          font-size: 24px;
          color: $title;
          line-height: 58px;
          margin-bottom: 20px;
          font-family: $font_suisse_medium;
        }

        &-context {
          font-size: 18px;
          color: #4a4a4a;
          letter-spacing: 0.45px;
          line-height: 28px;
          font-family: $font_suisse_light;
        }

        .home-button-1 {
          width: fit-content;
          border: 2px solid #3e3e3e;
          background-color: #c4ebd8;
          opacity: 0.85;
          padding: 15px 0;
          width: 360px;
          text-align: center;
          cursor: pointer;
          color: $black;
          margin-top: 35px;
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .how-it-works {
    display: grid;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    justify-items: center;
    padding: 70px 0;

    &-title {
      font-family: 'SchnyderS-Demi';
      font-size: 90px;
      line-height: 90px;
      color: #2e2e2e;
      width: 1000px;
    }

    &-sub_title {
      font-family: 'Gilmer-Medium', sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 80px;
      letter-spacing: normal;
      text-align: center;
      color: #2e2e2e;
      margin: 8px 0 20px 0;
    }

    &-text {
      background-color: $white;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;

      .section {
        color: #333333;
        border: solid 1px #979797;
        padding: 50px 25px;

        .step {
          font-family: 'Gilmer-Regular', sans-serif;
          font-size: 22px;
          line-height: 34px;
          letter-spacing: 0.89px;
          text-transform: uppercase;
          padding-bottom: 10px;
        }

        .num {
          font-family: 'SchnyderS-Demi';
          font-size: 141px;
          line-height: 0.86;
          letter-spacing: -1.99px;
          text-align: center;
        }

        .text {
          font-family: 'SchnyderS-Demi';
          font-size: 51px;
          line-height: 51px;
          position: relative;
          margin-bottom: 20px;

          &::before {
            content: ' ';
            display: block;
            position: absolute;
            width: 100px;
            height: 2px;
            background-color: #979797;
            bottom: -20px;
            left: 50%;
            margin-left: -50px;
          }
        }

        .sub_text {
          margin-top: 40px;
          font-family: 'Gilmer-Medium', sans-serif;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  .safe-proven-container {
    .safe-proven {
      width: 100%;
      height: 90em;
      margin: 0 auto;
      background: url('https://images.shaklee.com/meology/images/landing/desktop/meology-difference.png?v=1') no-repeat center;
      background-size: cover;
      padding: 60px 0 100px 0;

      .title {
        line-height: 90px;
        font-family: SchnyderS-Demi;
        font-size: 90px;
        color: #2e2e2e;
        text-align: center;
      }

      .safe-proven-text {
        position: relative;
        margin-top: 20px;

        .section {
          position: absolute;

          .header {
            font-family: 'SchnyderS-Demi';
            font-size: 50px;
            line-height: 50px;
            color: #000000;
          }

          .context {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.65px;
            color: #2e2e2e;
            max-width: 350px;
            margin-top: 10px;
          }

          &:nth-child(1) {
            right: 60px;
            width: 350px;
            top: 100px;
          }

          &:nth-child(2) {
            left: 200px;
            width: 315px;
            top: 210px;
          }

          &:nth-child(3) {
            right: 50px;
            width: 250px;
            top: 450px;
          }

          &:nth-child(4) {
            left: 100px;
            width: 200px;
            top: 720px;
          }

          &:nth-child(5) {
            left: calc(50% + 100px);
            width: 375px;
            top: 1000px;
          }
        }
      }
    }
  }

  .packages-container {
    background-image: url('https://images.shaklee.com/meology/images/landing_en_desktop_packaged_with_purpose.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-color: #ebebeb;
    background-size: cover;
    width: 1200px;
    height: 1113px;
    margin: 0 auto;

    .packages-text {
      .title {
        font-family: 'SchnyderS-Demi';
        font-size: 90px;
        line-height: 90px;
        color: #2e2e2e;
        text-align: center;
        padding-top: 74px;
      }

      .sub_title {
        font-family: 'Gilmer-Regular', sans-serif;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.27px;
        text-align: center;
        color: #3e3e3e;
        padding-top: 37px;
      }
    }

    &.spanish {
      background-image: url('https://images.shaklee.com/meology/images/landing_es_desktop_packaged_with_purpose.jpg');
    }

    &.canada {
      background: url('https://images.shaklee.com/meology/marketing/recycle.png?v=1') center bottom no-repeat #d8e9df;
      background-size: contain;
      height: 1300px;
    }
  }

  .extra-bg-container {
    background-color: #d8eadf;

    .extra-container {
      width: 1200px;
      padding: 60px 0 160px 0;
      margin: 0 auto;
      justify-items: center;
      display: grid;

      .extra-title {
        width: 750px;
        font-family: 'SchnyderS-Demi', sans-serif;
        font-size: 90px;
        line-height: 90px;
        letter-spacing: -1.27px;
        text-align: center;
        color: #333333;
      }

      .extra-section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        width: 1000px;
        justify-items: center;
        grid-row-gap: 90px;
        margin-top: 60px;

        &-item {
          display: grid;
          justify-items: center;
          max-width: 230px;

          &-img {
            height: 80px;
            display: grid;
            align-items: center;
            justify-items: center;
          }

          &:nth-child(1) {
            .extra-section-item-img {
              img {
                width: 103px;
              }
            }
          }

          &:nth-child(2) {
            .extra-section-item-img {
              img {
                width: 54px;
              }
            }
          }

          &:nth-child(3) {
            .extra-section-item-img {
              img {
                width: 85px;
              }
            }
          }

          &:nth-child(4) {
            .extra-section-item-img {
              img {
                width: 131px;
              }
            }
          }

          &:nth-child(5) {
            .extra-section-item-img {
              img {
                width: 144px;
              }
            }
          }

          &:nth-child(6) {
            .extra-section-item-img {
              img {
                width: 154px;
              }
            }
          }

          &-header {
            font-family: 'Gilmer-Bold', sans-serif;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 1.25px;
            text-align: center;
            color: #000000;
            margin-top: 23px;
            width: 145px;
            text-transform: uppercase;
          }

          &-context {
            font-family: 'Gilmer-Regular', sans-serif;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #3e3e3e;
            margin-top: 14px;
            width: 185px;
          }
        }
      }
    }
  }

  .swiper-science-container {
    margin: 0 auto;
    width: 1200px;

    .slide-pic {
      height: 560px;
      display: grid;
      justify-items: center;
      align-items: center;
      // grid-template-columns: 40% 60%;
      background-color: #eef8e5;

      .img {
        img {
          width: 371px;
        }
      }

      .text {
        .star {
          img {
            height: 50px;
          }
        }

        .description {
          margin-top: 45px;
          font-family: 'Gilmer-Regular', sans-serif;
          font-size: 24px;
          line-height: 40px;
          max-width: 470px;
        }

        .name {
          font-family: 'Gilmer-Bold', sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 50px;
          letter-spacing: 0.71px;
          text-transform: uppercase;
        }
      }
    }
  }

  .swiper-section-container {
    margin: 0 auto;
    width: 1200px;
    .slide-title {
      @include registered-sign;
      position: relative;
      font-family: 'SchnyderS-Demi';
      font-size: 95px;
      line-height: 1;
      margin-top: 80px;
      letter-spacing: -1.9px;
      text-align: center;
      color: #2e2e2e;
    }

    .slide-sub-title {
      font-family: 'Gilmer-Regular';
      font-size: 24px;
      line-height: 1.5;
      color: #2e2e2e;
      text-align: center;
      margin: 20px 0 0;
    }

    .slide-pic {
      width: 100%;

      &-img {
        width: 100%;
      }
    }

    .swiper-pagination-bullet {
      background: #9b9b9b;
      opacity: 1;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #000000;
      opacity: 1;
    }
  }
}

.swiper-button-prev::after {
  content: ' ' !important;
  background-image: url('https://images.shaklee.com/meology/icons/button.svg?v=1');
  transform: rotate(180deg) !important;
  display: block;
  width: 72px;
  height: 72px;
}

.swiper-button-next::after {
  content: ' ' !important;
  background-image: url('https://images.shaklee.com/meology/icons/button.svg?v=1');
  display: block;
  width: 72px;
  height: 72px;
}

.swiper-button-next {
  right: 72px !important;
  width: 72px !important;
  height: 72px !important;
}

.swiper-button-prev {
  left: 72px !important;
  width: 72px !important;
  height: 72px !important;
}

.science-section {
  width: 1200px;
  margin: 0 auto;

  &-header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 45px 0;
    color: #2e2e2e;

    &::after {
      content: '';
      display: block;
      background-image: url('../images/leaf_in_cup.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 384px;
      height: 418.5px;
      position: absolute;
      right: 0;
      top: -115px;
    }
  }

  &-title {
    font-family: 'SchnyderS-Demi';
    font-size: 90px;
    margin: 0 23.28px 0 54px;
    width: 706px;
    line-height: 1;
  }

  &-logo {
    display: block;
    width: 233px;
    height: 233px;
  }

  &-swiper {
    width: 100%;
    text-align: center;
    padding: 71px 0 74px 0;

    .swiper-pagination-bullet {
      background: #9b9b9b;
      opacity: 1;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #000000;
      opacity: 1;
    }

    .text {
      padding-bottom: 50px;

      .description {
        font-size: 14px;
        color: #4a4a4a;
        line-height: 20px;
      }

      .percentage {
        font-size: 66px;
        color: $title;
      }
    }
  }
}

.happy-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $title;
  padding: 79px 0;
  background-size: cover;

  &-context {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    &-header {
      font-family: 'SchnyderS-Demi', sans-serif;
      font-size: 90px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }

    &-text {
      margin-top: 12px;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0.27px;
      text-align: center;
      line-height: 30px;
      font-family: 'Gilmer-Regular', sans-serif;
    }
  }
}

.faqs-link-section {
  color: #026854;
  display: flex;
  flex-wrap: wrap;
  font-family: 'Gilmer-Medium', sans-serif;
  font-size: 22px;
  justify-content: center;
  line-height: 33px;
  margin: 42px 0 40px 0;

  ul {
    list-style-type: none;
    overflow: hidden;
    padding-left: 5px;

    li a {
      display: inline;
      color: #026854;
      text-decoration: underline;
      text-decoration-color: #026854;
    }
  }
}

.faq-footer-divider {
  border: 1px solid darkgray;
}

.faq-accordion-pannel {
  @include accordion-style;

  .faq-accordion {
    .toggle {
      border-bottom: none;
    }
  
    .title {
      color: #1e1e1e;
      font-family: OpenSans-Bold;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin: 24px 200px 10px 155px;
      overflow-wrap: break-word;
    }
  
    .icon {
      margin-right: 175px;
    }
  
    .content {
      color: #1e1e1e;
      font-family: OpenSans;
      font-size: 18px;
      line-height: 28px;
      margin: 24px 242px 24px 155px;
      text-align: left;
  
      a {
        text-decoration: underline;
        color: #006fe8;
      }
    }
  
    .accordion-footer {
      border-top: 1px solid #dddddd;
      margin: 0 155px;
    }
  }
}

/* Begin FAQs section */
.faqs-wrapper {
  .faqs {
    background-color: rgba(233, 232, 232, 0.23);

    .faqs-box {
      background-color: #f2f2f2;
      line-height: 30px;
      letter-spacing: 2px;

      .faqs-nav {
        color: #868686;
        font-family: OpenSans;
        font-size: 12px;
        margin: 0 155px;

        ul {
          background-color: #f2f2f2;
          list-style-type: none;
          margin: 0;
          overflow: hidden;
          padding: 0;
        }

        li {
          display: inline;
        }
      }
    }

    .faqs-header {
      color: #000000;
      font-size: 89px;
      line-height: 89px;
      text-align: center;
      font-family: 'SchnyderS-Bold', sans-serif;
      background-color: $white;
      margin-bottom: 0;
    }

    .faqs-sub-header {
      color: #000000;
      font-family: 'Gilmer-Bold', sans-serif;
      font-size: 26px;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 80px;
      background-color: $white;
    }

    .faqs-toggle-section {
      position: sticky;
      top: 0px;
      z-index: 1999;

      .faqs-toggle-menu {
        font-size: 24px;
        .active {
          background-color: #14362e;
          color: $white;
          width: 100%;
          height: 62px;
          line-height: 62px;
          text-align: left;
          padding-left: 20px;
          &::before,
          &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          &::after {
            height: 24px;
            width: 24px;
            border-radius: 12px;
            right: 20px;
            border: 1px solid #fff;
          }
          &::before {
            display: block;
            height: 10px;
            width: 10px;
            right: 27px;
            content: '';
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            transform: rotate(135deg);
            transform-origin: center center;
            top: 24px;
            transition: all 0.6s ease;
          }
        }
        .active.open {
          &::before {
            transform: rotate(-45deg);
            top: 28px;
          }

        }
        .dropdown-menu.show {
          width: 100%;
          color: #000;
          font-size: 24px;
          background-color: white;
          padding: 0;
          border: none;
          border-radius: 2px;
          .dropdown-item {
            padding-left: 20px;
            background-color: rgba(0, 0, 0, 0.08);
            width: 100%;
            height: 62px;
            line-height: 62px;
            text-align: left;
            border-radius: 2px;
            border-bottom: solid 1px rgba(151,151,151,0.44);
          }
        }
      }

      .faqs-toggle {
        margin: 0 155px 0px 155px;
        padding-inline-start: 0px;
        list-style: none;
        display: flex;
        background-color: white;

        li {
          flex: 1;
          height: 68px;
          line-height: 68px;
          text-align: center;
          cursor: pointer;
          border-radius: 2px;
          font-size: 24px;
          font-family: 'Gilmer-Bold', sans-serif;
          background-color: rgba(0, 0, 0, 0.08);
          color: #000;
          border-right: 1px solid #fff;

          &.active {
            background-color: #14362e;
            color: $white;
          }
        }
      }
    }
    
    .back-to-top {
      color: #468200;
      cursor: pointer;
      font-family: OpenSans-Semibold;
      font-size: 18px;
      margin: 34px 155px 0 0;
      border: 0;
      background-color: transparent;

      &-wrap {
        text-align: right;
      }
    }

    .disclaimer-container {
      background-color: $white;

      .disclaimer {
        max-width: 1140px;
        margin: 34px auto;
        font-family: 'Gilmer', sans-serif;
        font-size: 14px;
        line-height: 1.86;
        letter-spacing: 0.21px;
        color: #2e2e2e;
        text-align: left;
      }
    }
  }

  .page-footer-divider {
    border: 1px solid #dddddd;
    margin-top: 35px;
  }
}

/* Awards */
.award {
  $root: &;

  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;

  * {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }

  &-wrap {
    width: 100%;
    background-color: #F4FAF6;
  }

  &-text {
    font-family: 'SchnyderS-Demi', sans-serif;
    font-size: 58px;
    line-height: 1.034;
    color: #333;
  }

  &-list {
    display: flex;
    align-items: center;

    >li {
      padding: 0 15px;
    }

    img {
      display: block;
    }
  }

  &-biba {
    width: 120px;
  }

  &-ptpa {
    width: 150px;
  }

  &-tnpc {
    width: 160px;
  }

  &-regular {
    #{$root}-biba {
      width: 140px;
    }
  }

  &-kids {
    margin: 60px auto 0;
    max-width: 1140px;

    #{$root} {
      justify-content: center;
    }

    #{$root}-text {
      margin-right: 100px;

      sup {
        line-height: 0;
        font-size: 0.3em;
        vertical-align: super;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {

  .container-fluid,
  .fluid-container {
    .app-header .app-nav {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .app-container {
    .packages-container {
      width: 100%;
      background-size: contain;
      height: 847px;

      .packages-text {
        .title {
          font-size: 65px;
          line-height: 57px;
          padding-top: 25px;
        }
      }

      &.spanish {
        .packages-text {
          .title {
            line-height: 50px;
            padding-top: 20px;
          }

          .sub_title {
            padding-top: 20px;
            line-height: 20px;
          }
        }
      }

      &.canada {
        background: url('https://images.shaklee.com/meology/marketing/recycle_mobile1.png?v=1') no-repeat center bottom #d8eadf;
        background-size: contain;
        height: 920px;
      }
    }

    .swiper-section-container {
      margin: 0 auto;
      width: 100%;
      .slide-title {
        font-size: 65px;
        line-height: 65px;
        max-width: 922px;
        margin: 50px auto 70px;
      }
      .swiper-button-next,
      .swiper-button-prev {
        width: 52px;
        height: 52px;

        &::after {
          width: 52px;
          height: 52px;
          background-size: cover;
          border-radius: 50%;
          opacity: 0.85;
        }
      }

      .swiper-button-prev {
        &::after {
          box-shadow: -1px -1px 5px #888888;
        }

        left: 0 !important;
      }

      .swiper-button-next {
        &::after {
          box-shadow: 1px 1px 5px #888888;
        }

        right: 0 !important;
      }
    }

    .header-section {
      justify-content: flex-start;
      padding: 0 20px;
    }

    .extra-bg-container {
      .extra-container {
        width: 100%;
        padding: 60px 20px 100px 20px;

        .extra-title {
          font-size: 65px;
          line-height: 65px;
          width: 100%;
        }

        .extra-section {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          justify-items: center;
          grid-row-gap: 20px;
          margin-top: 60px;

          &-item {
            display: grid;
            justify-items: center;
            max-width: 230px;

            &-img {
              height: 80px;
              display: grid;
              align-items: center;
              justify-items: center;
            }

            &:nth-child(1) {
              .extra-section-item-img {
                img {
                  width: 82px;
                }
              }
            }

            &:nth-child(2) {
              .extra-section-item-img {
                img {
                  width: 38px;
                }
              }
            }

            &:nth-child(3) {
              .extra-section-item-img {
                img {
                  width: 68px;
                }
              }
            }

            &:nth-child(4) {
              .extra-section-item-img {
                img {
                  width: 103px;
                }
              }
            }

            &:nth-child(5) {
              .extra-section-item-img {
                img {
                  width: 105px;
                }
              }
            }

            &:nth-child(6) {
              .extra-section-item-img {
                img {
                  width: 123px;
                }
              }
            }
          }
        }

        .extra-section-item-context {
          width: 150px;
        }
      }
    }

    .safe-proven-container {
      .safe-proven {
        width: 100%;
        height: 1540px;
        background: url('https://images.shaklee.com/meology/marketing/mobile-difference-mobile.png?v=1') no-repeat center top;
        background-size: cover;
        padding: 60px 20px 100px 20px;

        .title {
          line-height: 65px;
          font-size: 65px;
        }

        .safe-proven-text {
          display: grid;
          grid-template-rows: repeat(5, 1fr);

          .section {
            position: relative;
            height: 180px;

            .header {
              font-family: 'SchnyderS-Demi', sans-serif;
              font-size: 50px;
              line-height: 50px;
              color: #000000;
            }

            .context {
              font-family: 'Gilmer', sans-serif;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0.65px;
              color: #2e2e2e;
              max-width: 350px;
              margin-top: 10px;
            }

            &:nth-child(1) {
              left: 0;
              justify-self: flex-start;
              top: 15px;
              width: 305px;
            }

            &:nth-child(2) {
              left: 0;
              justify-self: flex-end;
              top: 90px;
              width: 292px;
            }

            &:nth-child(3) {
              left: 0;
              justify-self: flex-start;
              top: 90px;
              width: 207px;
            }

            &:nth-child(4) {
              left: 0;
              justify-self: flex-end;
              top: 113px;
              width: 235px;
            }

            &:nth-child(5) {
              left: 0;
              top: 90px;
              justify-self: flex-end;
              width: 237px;
            }
          }
        }
      }
    }

    .intro-container {
      &.hide-card .intro-section .intro-section-card.two-items{
        grid-template-columns: repeat(1, 1fr);
      }
      .intro-section {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        min-height: 600px;
        align-items: flex-end;
        padding: 0 20px 50px 20px;
        width: 100%;

        &-card {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(3, auto);

          &-regular,
          &-prenatal,
          &-kids {
            width: 100%;
            max-width: 415px;
            height: auto;
            padding: 45px 50px;
            position: relative;

            .logo {
              height: 54px;
            }

            .title {
              font-size: 58px;
            }

            .btn-container {

              .home-button-1,
              .home-button-3,
              .home-button-2 {
                font-size: 14px;
                width: 100%;
                height: 47px;
                line-height: 47px;
              }
            }
          }

          &-regular {
            justify-self: center;
            margin-right: 0px;
            &::after {
              content: ' ';
              background-image: url('https://images.shaklee.com/meology/prenatal/mobile_landing_bg_regular.png');
              display: block;
              position: absolute;
              width: 208px;
              height: 450px;
              right: -90px;
              top: -120px;
              background-size: contain;
              z-index: 19;
              background-repeat: no-repeat;
            }        
          }

          &-prenatal {
            justify-self: center;
            margin-top: 37px;

            &::after {
              content: ' ';
              background-image: url('https://images.shaklee.com/meology/prenatal/mobile_landing_bg_prenatal.png');
              display: block;
              position: absolute;
              width: 208px;
              height: 300px;
              right: -110px;
              top: 60px;
              z-index: 19;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }

          &-kids {
            justify-self: center;
            margin-left: 0px;
            margin-top: 37px;           
            &::before {
              content: ' ';
              background-image: url('https://images.shaklee.com/meology/prenatal/mobile_landing_bg_kids.png') ;
              display: block;
              position: absolute;
              width: 208px;
              height: 360px;
              left: -60px;
              top: -60px;
              z-index: 19;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }

          &.two {
            .intro-section-card-regular {
              width: 100%;

              .btn-container {
                display: grid;

                .home-button-1 {
                  height: 60px;
                  line-height: 60px;
                  margin: 0 0 7px 0 !important;
                }

                .home-button-3 {
                  margin: 7px 0 3px !important;
                  height: 59px;
                  padding: 5px 0;
                }
              }
            }
          }
        }

        &-text {
          padding: 85px 0 0;
          text-align: center;
          width: 100%;
          margin: 0 auto;

          .brand-logo {
            width: 300px;
            height: 76px;
          }

          &-header {
            font-size: 65px;
            line-height: 65px;
            margin-top: 18px;
			margin-bottom: 10px;
          }

          &-context {
            font-family: 'Gilmer-Regular';
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.65px;
            margin-bottom: 53px;
          }

          .home-button-1,
          .home-button-3,
          .home-button-2 {
            font-size: 13px;
            width: 100%;
          }
        }

        .cannada-disclaimers {
          max-width: 100%;
          padding: 20px;

          &.prove-it {
            position: static;
            width: 100%;
            padding-left: 20px;
          }
        }
      }

      &.hide-card {

        &::before,
        &::after {
          background: none;
        }

        .intro-section {
          min-height: 940px;
          align-items: flex-start;
          
          &.one-card .intro-section-text-context {
            margin: 20px 90px;

            &::before {
              content: ' ';
              background-image: url('https://images.shaklee.com/meology/images/landing/mobile/canada-right-hand.png?v=1');
              display: block;
              position: absolute;
              width: 119px;
              height: 270px;
              left: 0;
              top: 250px;
              background-size: cover;
              z-index: 19;
            }

            &::after {
              content: ' ';
              background-image: url('https://images.shaklee.com/meology/images/landing/mobile/canada-left-hand.png?v=1');
              display: block;
              position: absolute;
              width: 110px;
              height: 255px;
              right: 0;
              top: 495px;
              background-size: cover;
              z-index: 19;
            }
          }

        }
      }
    }

    .intro-container.no-template {
      .intro-section {
        width: 100%;
        background-size: cover;

        &-text {
          justify-items: center !important;
        }
      }
    }

    .intro-container.no-template.kids {
      .intro-section-header {
        width: 100%;
      }

      .intro-section-text {
        &-context {
          padding: 0 20px;
        }
      }

      .intro-section-bg {
        background: linear-gradient(to bottom,
            #d6efbe 140px,
            $white 140px,
            $white );

        .media {
          width: calc(100% - 40px);
        }
      }
    }

    .personal-container {
      .personal-section {
        background-position: bottom;
        width: 100%;
        height: 760px;
        align-items: flex-start;
        padding: 45px 20px 0 20px;

        &-text {
          margin-right: 0;

          &-header {
            font-size: 22px;
            text-align: left;
            line-height: 30px;
          }

          &-context {
            font-size: 14px;
            text-align: left;
            line-height: 20px;
          }

          .home-button-1 {
            width: 100%;
            font-size: 13px;
          }
        }
      }
    }

    .how-it-works {
      width: 100%;
      padding: 70px 20px;

      &-title {
        font-size: 65px;
        line-height: 65px;
        width: 100%;
      }

      &-text {
        background-color: $white;
        display: grid;
        grid-template-rows: repeat(3, auto);
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 20px;
        width: 100%;
        justify-items: center;

        .section {
          width: 100%;
          max-width: 415px;
        }
      }
    }

    .cta-container {
      .cta-section {
        display: grid;
        width: 100%;
        padding: 50px 20px;
        &-card.two-items,
        &-card {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(3, auto);

          &-regular,
          &-prenatal,
          &-kids {
            width: 100%;
            max-width: 415px;
            padding: 45px 20px;
            height: auto;

            .logo {
              height: 54px;
            }

            .title {
              font-size: 72px;
              line-height: 72px;
            }

            .btn-container {

              .home-button-1,
              .home-button-3,
              .home-button-2 {
                width: 100%;
                height: 47px;
                line-height: 47px;
              }
            }
          }

          &-regular {
            justify-self: center;
            margin-right: 0px;
            background: url('https://images.shaklee.com/meology/prenatal/M1.jpg?v=002') left top no-repeat;
            background-size: cover;
          }

          &-kids {
            justify-self: center !important;
            margin-left: 0px;
            margin-top: 37px;
            background: url('https://images.shaklee.com/meology/prenatal/M3.jpg') right top no-repeat;
            background-size: cover;
          }

          &-prenatal {
            justify-self: center !important;
            margin-top: 37px;
            background: url('https://images.shaklee.com/meology/prenatal/M2.jpg') right top no-repeat;
            background-size: cover;
          }

          &.two {
            .cta-section-card-regular {
              width: 100%;
              padding-left: 0;
              padding-right: 0;
              background: url('https://images.shaklee.com/meology/images/landing/mobile/mobile-buttons-bg.png?v=1') no-repeat;
              background-size: cover;

              .logo {
                margin: 0 0 5px 0;
              }

              .btn-group {
                width: 100%;

                .btn-container {
                  display: grid;
                  margin: 0;

                  .home-button-1,
                  .home-button-3 {
                    width: 285px;
                    margin: 5px;
                    height: 47px;
                  }
                }

                .learn_more {
                  width: 285px;
                  margin: 5px 0;
                  height: 47px;
                  padding: 7px;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    .safe-proven {
      width: 100%;
      padding: 50px 20px;
      flex-direction: column;

      .background {
        width: 100%;
        height: 450px;
        background-size: cover;
        margin-top: 40px;
      }

      .safe-proven-text {
        width: 100%;
        padding: 0;

        .section {
          margin-bottom: 30px;

          .header {
            font-size: 16px;
          }

          .context {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.35px;
          }
        }
      }
    }
  }

  .science-section {
    width: 100%;
    padding: 0;

    &-header {
      padding-right: 233px;
    }

    &-swiper {
      .swiper-science-container {
        width: 100%;

        .swiper-button-next,
        .swiper-button-prev {
          &::after {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-size: cover;
            opacity: 0.85;
          }
        }

        .swiper-button-prev {
          &::after {
            box-shadow: -1px -1px 5px #888888;
          }

          left: 0 !important;
        }

        .swiper-button-next {
          &::after {
            box-shadow: 1px 1px 5px #888888;
          }

          right: 0 !important;
        }

        .slide-pic {
          grid-template-columns: repeat(1, 1fr);
          height: auto;
          padding: 64px 20px;

          .img {
            img {
              width: 186px;
            }
          }

          .text {
            .star {
              img {
                height: 35px;
                margin-top: 20px;
              }
            }

            .description {
              font-size: 16px;
              line-height: 27px;
              max-width: 100%;
            }

            .name {
              font-size: 16px;
              line-height: 27px;
            }
          }
        }
      }
    }
  }

  .happy-section {
    padding: 48px 20px;

    &-context {
      width: 100%;

      &-header {
        font-size: 65px;
        line-height: 65px;
      }

      &-text {
        margin-top: 22px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  /** FAQs link Mobile Section **/
  .faqs-link-section {
    font-size: 18px;
    line-height: 20px;
    margin: 42px 22px 40px 22px;

    ul {
      padding: 0;
    }
  }

  .faq-accordion-pannel {
    .faq-accordion {
      .title {
        margin: 0 71px 0 20px;
        text-align: left;
      }
    
      .icon {
        margin: 0 20px 0 0;
      }
    
      .content {
        margin: 8px 38px 24px 21px;
        text-align: left;
      }
    
      .accordion-footer {
        margin: 0 20px;
      }
    }   
  }

  /* Begin mobile FAQs section */
  .faqs-wrapper {
    .faqs {
      .faqs-toggle-section .faqs-toggle {
        margin: 0 ;
      }
      .faqs-header {
        margin: 32px 0 0 20px;
      }

      .faqs-header-divider {
        margin: 30px 20px 0 24px;
      }

      .back-to-top {
        margin: 34px 22px 0 0;
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  /* Begin FAQs section */
  .faqs-wrapper {
    .faqs {
      .faqs-header {
        font-size: 60px;
        line-height: 60px;
        margin: 0;
        padding-top: 54px;
      }

      .faqs-sub-header {
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 54px;
      }

      .faqs-toggle-section {
        .faqs-toggle {
          margin: 0;

          li {
            height: 62px;
            line-height: 62px;
            font-size: 20px;
          }
        }
      }

      .disclaimer-container {
        .disclaimer {
          margin: 0 20px 0 20px;
          width: initial;
        }
      }
    }
  }

  .app-container {
     overflow: hidden;
    .intro-container.no-template.kids {
      .intro-section-bg {
        .media {
          height: 281px;
        }
      }
    }

    .packages-container {
      height: 990px;
      background-image: url('https://images.shaklee.com/meology/images/landing_en_mobile_packaged_with_purpose.jpg');
      background-size: cover;

      &.spanish {
        background-image: url('https://images.shaklee.com/meology/images/landing_es_mobile_packaged_with_purpose.jpg');
      }
    }

    .safe-proven-container {
      .safe-proven {
        .safe-proven-text {
          .section:nth-child(2) {
            top: 30px;
          }
        }
      }
    }
  }
  .science-section {
    &-header {
      display: block;
      padding: 70px 0 135px 0;
      overflow-x: clip;

      &::after {
        width: 332px;
        height: 362px;
        top: -94px;
        right: -80px;
      }
    }

    &-title {
      margin-left: 0;
      padding-left: 25px;
      font-size: 55px;
      width: 100%;
    }

    &-logo {
      width: 150px;
      height: 150px;
      position: absolute;
      right: 25px;
      bottom: 35px;
    }

    &-swiper {
      padding-top: 0;
    }
  }

  .award {
    $root: &;

    flex-direction: column;
    padding: 20px;

    &-text {
      font-size: 35px;

    }

    &-list {
      >li {
        padding: 20px 8px 0;
      }
    }

    &-biba {
      width: 80px;
    }

    &-ptpa {
      width: 104px;
    }

    &-tnpc {
      width: 110px;
    }

    &-regular {
      #{$root} {
        flex-direction: row;
      }

      #{$root}-biba {
        width: 100px;
      }
    }

    &-kids {
      #{$root}-text {
        margin-right: 0;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .app-container {
    .cta-container {
      .cta-section-card.two {
        .cta-section-card-regular {
          max-width: 758px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .app-container .swiper-section-container .slide-title::before {
    bottom: -54px;
  }
}
