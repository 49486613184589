// Header styles
@import 'Variables.scss';
.sherpa {
  min-width: fit-content;
  position: fixed;
  z-index: 1000;
  bottom: 100px;
  right: 0;
  background-color: #f8f8f8;
  color: #3e3e3e;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 17px 0 0 17px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  padding-right: 10px;
  transition: all 0.5s ease;
  border-radius: 19px 0 0 19px;

  &.big {
    padding: 10px;
    border-radius: 5px 0 0 5px;
    max-width: 240px;
  }

  &-avatar {
    @at-root .big & {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 0;
    }

    &-img,
    &-text {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 2px solid #fff;
    }

    &-img {
      display: block;
    }

    &-text {
      font-size: 20px;
      color: #fff;
      background-color: $shaklee_green;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-name {
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-text1 {
    flex: 1;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;

    @at-root .big & {
      order: -1;
    }
  }

  &-toggle {
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @at-root .big & {
      order: -1;
    }

    img {
      width: 16px;
      height: 16px;
      transition: transform 0.5s ease;
      transform: rotate(-225deg);

      @at-root .big & {
        transform: rotate(0);
      }
    }
  }

  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    padding-left: 2px;
    width: 100%;

    > li {
      display: flex;
      align-items: center;
      padding: 10px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
      }

      img {
        width: 12px;
        min-width: 12px;
        height: 12px;
        margin-right: 5px;
      }

      a {
        color: #3e3e3e;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: 100%;
      }

      &:last-child {
        align-items: flex-start;
        img {
          margin-top: 3px;
        }
      }
    }

    &-link {
      flex: 1;
      min-width: 0;
      max-height: 100px;
      overflow-y: auto;
      > li {
        height: 20px;
      }
    }
  }
}

.app-header {
  text-transform: uppercase;
  box-shadow: 0 2px 4px 0 rgba(209,209,209,0.5);
  .sherpa {
    text-transform: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &-top {
    font-family: 'SuisseIntl-Regular', sans-serif;
    height: 30px;
    font-size: 11px;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    max-width: 1200px;
    margin: 0 auto;
    &-align {
      background-color: $shaklee_green;
    }
    .app-header-log {
      > li {
        margin: 0;
        > a {
          padding: 0 15px;
          &:hover {
            background-color: #006a5b;
          }
        }
      }
    }

    .dropdown {
      font-size: 11px;
      .nav-link {
        padding: 0;
        height: 100%;
        min-width: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        &:hover {
          background-color: #006a5b;
        }

        img {
          margin-right: 5px;
        }
      }
      &.hide-dropdown {
        .nav-link {
          &:hover {
            background-color: transparent;
          }
          &::after {
            display: none;
          }
        }
      }

      .dropdown-toggle {
        &::after {
          @include glyphicons;
          content: '\e259';
          border: 0;
          margin-left: 8px;
        }
      }

      .dropdown-menu {
        margin: 0;
        min-width: 95px;
        border-radius: 0;
        padding: 0;
        border-top: 0;
      }

      .dropdown-item {
        display: flex;
        align-items: center;
        padding: 0 17px;
        font-size: 12px;
        height: 30px;

        &.disabled {
          img {
            opacity: 0.5;
          }
        }
        img {
          margin-right: 5px;
        }
      }
    }

    .dropdown-lang {
      .dropdown-item {
        padding: 0 8px;
        &::before {
          @include glyphicons;
          content: '';
          display: block;
          width: 20px;
        }
        &.disabled {
          &::before {
            content: '\e013';
          }
        }
      }
    }
  }

  &-bottom {
    font-family: 'SuisseIntl-Book', sans-serif;
    background-color: #fff;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    max-width: 1200px;
    margin: 0 auto;

    .changed & {
      padding: 0 5px;
      height: 55px;
      width: 100%;
    }

    &-left {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }

  &-log {
    display: flex;
    > li {
      margin-left: 18px;
      > a {
        color: #fff;
        display: flex;
        align-items: center;
        height: 100%;

        .changed & {
          color: $shaklee_green;
          font-size: 14px;
        }

        &:hover {
          text-decoration: none;
        }

        &::before {
          display: block;
          margin-right: 5px;

          .changed & {
            font-size: 20px;
          }
        }
      }
    }
  }

  &-logo {
    display: block;
    width: 162px;
    height: 51.65px;
    line-height: 99em;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .changed & {
      width: 90px;
      height: 25.46px;
    }
  }

  &-nav {
    display: flex;
    font-size: 14px;

    > li {
      margin-left: 26px;
      > a {
        color: #2e2e2e;
		font-weight: bold;
        &:hover {
          text-decoration: none;
        }
      }
    }

    &-fixed {
      font-family: 'SuisseIntl-Light', sans-serif;
      > li {
        padding: 10px 20px;
        font-size: 16px;
        border-bottom: 1px solid #d9d9d9;

        > a {
          color: #1e1e1e;

          &:hover {
            color: #000;
            font-family: 'SuisseIntl-Book', sans-serif;
          }
        }
      }
    }
  }

  &-toggle {
    width: 38px;
    height: 34px;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $shaklee_green;
    background-color: transparent;

    &::before {
      @include glyphicons;
      content: '\e055';
      display: block;
      font-size: 20px;
    }
  }

  &-menu {
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    transform: translateX(-100%);
    will-change: transform;
    transition: transform 0.3s ease;
    text-transform: none;

    &.is-open {
      transform: translateX(0);
    }

    &-top {
      font-family: 'SuisseIntl-Book', sans-serif;
      padding: 0 20px;
      height: 55px;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;
      background-color: #f2f2f2;
      border-bottom: 1px solid $shaklee_green;
    }

    &-close {
      font-size: 20px;
      color: $shaklee_green;
      background-color: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      height: 100%;
    }

    &-country {
      padding: 10px 20px;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      color: #1e1e1e;
      display: flex;
      align-items: center;
      font-family: 'SuisseIntl-Light', sans-serif;

      img {
        margin-right: 5px;
      }
    }

    &-lang {
      font-family: 'SuisseIntl-Light', sans-serif;
      > li {
        padding: 10px 20px;
        font-size: 16px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        justify-content: space-between;

        &::after {
          @include glyphicons;
          content: '\e080';
          color: $shaklee_green;
        }
      }
    }
  }

  &-submenu {
    position: absolute;
    left: 0;
    top: 55px;
    width: 100%;
    height: calc(100% - 55px);
    background-color: #fff;

    &-head {
      background-color: #f2f2f2;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 0 20px;
      border-bottom: 1px solid #d9d9d9;
      color: #333;

      &::before {
        @include glyphicons;
        content: '\e079';
        margin-right: 5px;
      }
    }

    &-list {
      font-family: 'SuisseIntl-Light', sans-serif;
      > li {
        padding: 10px 20px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        &.disabled {
          opacity: 0.5;
        }

        img {
          display: block;
          margin-right: 5px;
        }
      }
    }
  }
}
.managing-wrap {
  background: #3e3e3e;
  width: 100vw;
}
.managing {
  font-family: "Gilmer Regular", "Montserrat", "Open Sans", Helvetica, Arial, sans-serif;
  text-transform: UPPERCASE;
  color: #ffffff;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  &-left {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-button {
    border: none;
    display: block;
    padding: 10px 15px;
    font-size: 16px;
    background: #e04545;
    color: #ffffff;

    &:hover {
      color: currentColor;
      background-color: #cf2323;
    }
  }
}
.promotion-banner-wrap {
  width: 100%;
  background-color: #A3CBA9;
  color: #173e35;
  text-transform: none;
  border-top: 0.5px solid #173e35;
  * {
    margin: 0;
    padding: 0;
  }

  @at-root .promotion-banner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 30px;
    text-align: center;

    &-link {
      margin-left: 10px;
      text-decoration: underline;
      font-weight: bold;
      color: #173e35;

      &:hover {
        color: #173e35;
      }
    }
  }
}

//mobile style
@media only screen and (max-width: 768px) {
  .managing {
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;

    &-left {
      font-size: 12px;
      margin-bottom: 5px;
      text-align: center;
      max-width: 100%;
    }

    &-right {
      padding: 5px 10px;
      font-size: 12px;
      max-width: 100%;
    }
  }
}
