.cart-btn {
  $root: &;
  display: flex;

  &-item {
    position: relative;
    margin-left: 10px;
    &.auto {
      width: 312px;
      button {
        width: 100%;

      }
    }
    button {
      all: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fff;
      color: #006b54;
      font-size: 14px;
      line-height: 16px;
      min-width: 170px;
      min-height: 50px;
      overflow: hidden;
      font-family: 'Shaklee-Verlag-Black', sans-serif;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #024436;
      }

      &:disabled {
        color: #bbb;
        cursor: not-allowed;
      }

      @include focus-indicator();
    }
  }

  &-tips {
    position: absolute;
    width: 100%;

    &.is-on-summary {
      top: 100%;
    }

    &.is-on-fixed {
      bottom: 100%;
      padding-bottom: 14px;
    }

    &.open {
      .dropdown-menu {
        display: block;
        padding: 0px;
        margin: 0px;
      }
    }

    .dropdown-menu {
      width: 100%;
      overflow-y: auto;
      max-height: 300px;
      list-style-type: none;
      background-color: #fff;
      text-align: left;
      border-radius:0;
      z-index: 999;

      li {
        clear: both;
        font-weight: 400;
        color: #2e2e2e;
        white-space: nowrap;
        padding: 0px 20px;
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }

        @include focus-indicator();

        .autoship-details {
          padding: 20px 0px;
          border-bottom: 1px solid #d9d9d9;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
}

// mobile
@media (max-width: 768px) {
  .cart-btn {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-item {
      button {
        margin: 0;
        min-width: 160px;
        min-height: 44px;
        margin-top: 10px;
      }
    }

    &.is-on-fixed {
      padding-top: 10px;
    }

    &.is-one-btn {
      justify-content: flex-end;
    }
  }
}
