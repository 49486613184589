/* Learn-more parent */
%pdp-bg-block {
  content: '';
  display: block;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
}

.modal-box {
  font-family: 'Shaklee-Verlag-Book';
  padding: 0 !important;
  .modal-dialog {
    height: auto;
    margin: 0;
    padding: 0;
  }
  .modal-content {
    .modal-container {
      .pill-header-info {
        background-color: $light_brown;
        width: 100%;
      }
      // .recommended-title {
      //     // color: black;
      // }
      .pill-reason-info {
        background-color: $light_brown;
        color: blue;
      }
      hr {
        border: 1px solid green;
      }
    }
    .modal-container {
      .brand {
        position: relative;
        display: block;
        padding: 30px 0;
        justify-content: center;
        background: #f2ebe0;
        &-icon {
          position: absolute;
          left: 40px;
          cursor: pointer;

          span {
            padding-left: 8px;
            font-family: Gilmer-Medium;
            font-size: 18px;
            letter-spacing: 0.48px;
            font-weight: 500;
            vertical-align: middle;
          }
        }
        &-title {
          max-width: 1200px;
          padding: 0 30px;
          margin: 0 auto;
          text-align: end;

          img {
            width: 130px;
          }
        }
        &.fixed{
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 2;
          box-shadow: 0 2px 4px 0 rgba(203, 201, 201, 0.5);
          transition: all ease-in-out .3s;
        }
      }
      .content {
        background: #f2ebe0;
        padding: 0 237px 5px 237px;
        &-left {
          width: 50%;
          &-title {
            font-size: 36px;
            color: #000000;
            line-height: 48px;
          }
          &-subTitle {
            font-size: 26px;
            color: #000000;
            line-height: 31px;
          }
          &-desc {
            font-family: 'Shaklee-Verlag-Light';
            font-size: 20px;
            color: #000000;
            letter-spacing: 0.3px;
            line-height: 26px;
            margin-top: 58px;
          }
          &-tag {
            .tag-list {
              list-style-type: none;
              padding: 20px 0;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              &-text {
                font-size: 15px;
                color: #3e3e3e;
                letter-spacing: 0.28px;
                line-height: 21px;
              }
            }
            .tag-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 88px;
              text-align: center;
              img {
                display: block;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                margin-bottom: 10px;
              }
            }
          }
        }
        &-right {
          width: 50%;
          text-align: right;
          &-picture {
            width: 375px;
            position: relative;
            top: -55px;
          }
        }
        &-inner {
          display: flex;
        }
      }
    }
    .recommend-title {
      padding: 22px 237px;
      font-size: 20px;
      color: #000000;
      letter-spacing: 0.3px;
      line-height: 26px;
    }
    .recommend-content {
      background: #f9f7f5;
      padding: 0 237px 84px 237px;
      &-item {
        padding: 50px 0;
        border-bottom: 1px solid #000000;
        &-header {
          display: flex;
          &-img {
            width: 50px;
            height: 50px;
            display: flex;
            display: block;
            border-radius: 50%;
            margin-bottom: 10px;
          }
          &-title {
            font-size: 26px;
            color: #000000;
            line-height: 50px;
            margin-left: 20px;
          }
        }
        &-desc {
          font-size: 20px;
          color: #3e3e3e;
          letter-spacing: 0.3px;
          line-height: 26px;
          margin-top: 10px;
        }
      }
    }
    .because-text {
      display: grid;
      padding: 30px;
    }
    .reasons {
      padding: 30px;
      background-color: $light_brown;
      .reason-list {
        display: inline;
        list-style: none;
      }
    }
    /* pill-title */
    .pill-title {
      .pill-header-wrapper {
        color: $color_1;
        font-family: $font_family_1;
        margin: 20px;
        padding: 10px 20px;
      }
      .pill-info {
        margin: 0;
        padding: 0;
      }
      .pill-header-title {
        font-size: 30px;
        padding: 0 200px 0 0;
      }
      .pill-header-title-img {
        justify-content: center;
        align-items: center;
      }
      .pill-header-subtitle {
        color: $color_2;
        font-size: 18px;
        font-family: $font_family_2;
        padding: 10px 0 20px 0;
      }
      .leaf-solid-wrapper {
        margin: 0;
        padding: 0 0 90px 0;
        .leaf-solid {
          float: left;
          height: 60px;
          width: 60px;
          background-color: rgba(0, 0, 0, 0.2);
          padding: 5px;
          margin: 5px;
          border-radius: 50%;
          border: 2px solid green;
        }
        .img-caption {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          padding: 5px;
        }
      }
    }
    /* END pill-title */
    /* pill-forumlation */
    .pill-formulation {
      .pill-title-code {
        color: black;
        font-size: 12px;
        margin: 20px;
        padding: 10px 60px 20px 20px;
      }
      .recommend {
        color: black;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20px;
        margin: 10px;
        padding: 10px;
      }
      .leaf-solid {
        float: left;
        height: 60px;
        width: 60px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 5px;
        margin: 5px;
        border-radius: 50%;
        border: 2px solid #5d9732;
      }
      .code {
        color: black;
        font-size: 28px;
        margin: 20px;
        padding: 20px;
      }
    }
    /* pill-reasons */
    @mixin accordion-style {
      /* accordion style section */
      .accordion {
        .toggle {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0);
          border-bottom: 1px solid black;
        }
        .accordion-footer {
          height: 20px;
          display: block;
        }
        .accordion-title {
          font-size: 36px;
          color: #000000;
          line-height: 48px;
          position: relative;
          padding: 35px 0;
          transition: all 0.4s ease;
          .title {
            cursor: pointer;
            width: fit-content;
          }
          .icon {
            cursor: pointer;
            position: absolute;
            display: block;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-image: url('https://images.shaklee.com/meology/images/plus-solid.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: transparent;
            transition: all 0.6s ease;
            border: 0;
          }
        }
        .accordion-content {
          color: #000;
          /* Fallback color for older browsers */
          color: rgba(0, 0, 0, 0.5);
          overflow-y: hidden;
          transition: all 0.4s ease;
          .table-container {
            box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.25);
          }
          .table-title,
          .table-footer,
          .table-otherIngredients {
            background: white;
            color: #3e3e3e;
            p {
              margin-bottom: 0;
            }
            p:first-child {
              font-size: 24px;
              color: #000000;
              line-height: 31px;
            }
          }
          .table-title {
            padding: 40px 20px 20px 20px;
          }
          .table-footer {
            padding: 20px;
          }
          .table-otherIngredients {
            padding: 20px 20px 40px 20px;
            .title {
              font-weight: bold;
            }
          }
          .table-responsive {
            background: #ffffff;
            border-radius: 4px;
            padding: 0 20px;
            .table thead th {
              border-top: 0;
            }
            .table {
              border-top: 1px solid black;
              border-bottom: 1px solid black;
            }
          }
        }

        .accordion-content.Labels {
          .nav-link {
            font-size: 20px;
            color: #2e2e2e;
            letter-spacing: 0.3px;
            line-height: 26px;
            text-decoration: underline;
            padding: 0 0 50px 0;
          }
        }

        .accordion-content.Directions,
        .accordion-content.Cautions {
          color: #2e2e2e;
          letter-spacing: 0.3px;
          line-height: 26px;
        }

        .toggle.open .accordion-title {
          .icon {
            transform: translateY(-50%) rotate(180deg);
            background-image: url('https://images.shaklee.com/meology/images/minus-solid.svg');
          }
        }
      }
    }
    .pill-reasons {
      @include accordion-style;
    }
    /* why-we-love */
    .why-we-love {
      padding: 62px 237px;
      .title {
        font-size: 36px;
        color: #000000;
        line-height: 48px;
      }
      .desc {
        font-size: 20px;
        color: #2e2e2e;
        letter-spacing: 0.3px;
        line-height: 26px;
        margin: 10px 0;
        p {
          display: inline;
        }
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .list li {
        background: url('https://images.shaklee.com/meology/images/check-circle-regular.svg')
          no-repeat left top;
        height: 5px;
        margin: 20px 0;
        padding: 10px 20px 20px 40px;
        font-size: 24px;
        line-height: 14px;
        color: #000000;
      }
    }
    /* Supplement Facts */
    .supplement-n-ingredients {
      @include accordion-style;
      background: #f9f7f5;
      padding: 0 237px 50px 237px;
      .dagger-container {
        padding: 45px 0px;
        color: #3e3e3e;
      }
      .warning {
        padding-top: 10px;
        padding-bottom: 30px;
        .title {
          font-family: Verlag-Book;
          font-size: 24px;
          color: #000000;
          line-height: 31px;
          padding: 15px 0;
        }
        .item {
          font-size: 16px;
          color: #3e3e3e;
          letter-spacing: 0.3px;
          line-height: 21px;
          margin: 10px 0;
        }
      }
    }
    .directions {
      padding: 15px 0 10px 0;
      .title {
        font-family: Verlag-Book;
        font-size: 24px;
        color: #000000;
        line-height: 31px;
        padding: 15px 0;
      }
      .item {
        font-size: 16px;
        color: #3e3e3e;
        letter-spacing: 0.3px;
        line-height: 21px;
        margin: 10px 0;
      }
    }
    /* supplement-facts */
    .supplement-facts {
      color: #000;
      /* Fallback color for older browsers */
      color: #3e3e3e;
      text-align: left;
      th:nth-child(2) {
        color: black;
        text-align: center;
        white-space: nowrap;
      }
      th:nth-child(3) {
        color: black;
        text-align: right;
        white-space: nowrap;
      }
      td:nth-child(2) {
        text-align: center;
        white-space: nowrap;
      }
      td:nth-child(3) {
        text-align: right;
        white-space: nowrap;
      }
    }

    /* disclaimer */
    .disclaimer {
      display: flex;
      justify-content: center;
      margin: 40px;
      p {
        text-align: justify;
        margin: 10px;
        padding: 20px;
        background-color: #ededed;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  // Meology Kids product detail pages
  &.kids-pdp {
    font-family: 'Gilmer-Regular', sans-serif;

    &-pill {
      .modal-content {
        .app-header {
          position: relative;
          z-index: 10;
        }

        .modal-body {
          &.pill-1 {
            .brand {
              background-color: #ffecb5;
            }
            .content {
              background: url('https://images.shaklee.com/meology/banner/desktop/Omega_Gelly_PDP_Desktop.png')
                center no-repeat #ffecb5;
              background-size: cover;
            }
            .recommend-title,
            .recommend-content,
            .supplement-n-ingredients {
              background-color: #fff7e1;
            }
          }
          &.pill-2 {
            .brand {
              background-color: #eee7f0;
            }
            .content {
              background: url('https://images.shaklee.com/meology/banner/desktop/Immunity_PDP_Desktop.png')
                0 10px no-repeat #eee7f0;
              background-size: cover;
            }
            .recommend-title,
            .recommend-content,
            .supplement-n-ingredients {
              background-color: #f6f3f7;
            }
          }
          &.pill-3 {
            .brand {
              background-color: #fde2e2;
            }
            .content {
              background: url('https://images.shaklee.com/meology/marketing/kids/SuperVitamin_PDP_Desktop.png') -30px
                no-repeat #fde2e2;
              background-size: cover;
            }
            .recommend-title,
            .recommend-content,
            .supplement-n-ingredients {
              background-color: #fef3f3;
            }
          }
          .modal-container {
            .content {
              height: 381px;
              &-inner {
                position: relative;
              }

              &-left {
                flex-grow: 1;

                &-title {
                  line-height: 1.2;
                }

                &-subTitle {
                  margin-top: 10px;
                }

                &-desc {
                  margin-top: 40px;
                }
              }

              &-right {
                position: relative;
                flex-basis: 400px;
              }
            }
          }
        }
      }
    }

    .modal-content {
      .modal-container {
        .brand,
        .content {
          background-color: #fff5c9;
        }

        .brand {
          padding: 6px 0;
          &-icon {
            line-height: 84px;
          }

          &-title {
            img {
              width: 167px;
            }
          }
        }

        .content {
          padding: 0;
          height: 381px;

          &-inner {
            width: 100%;
            display: flex;
            margin: 0 auto;
            padding: 0 237px;
          }
        }

        .recommend-title,
        .recommend-content {
          background-color: #ffffeb;
        }
      }

      .supplement-n-ingredients {
        background-color: #ffffeb;
      }
    }
  }

  &.prenatal-pdp {
    .modal-content .modal-container { 
      .content-left-title {
        padding-bottom: 8px;
      }
      .content-left-subTitle {
        padding-bottom: 100px;
      }

      .rsw {
        .content-left {
          padding-bottom: 46px;
        }
        .content-right {
          margin-bottom: 30px;
        }
        .content-left-subTitle {
          padding-bottom: 16px;
        }

      }
      .rsw-reco-title {
        padding-top: 46px;
        padding-bottom: 0;
        background-color: #f9f7f5;
      }

      .content {
        padding-right: 0;
      }
      .content-right {
        margin-top: -100px;
        padding-right: calc(50% - 482px);
      }
      .content-right-picture {
        object-fit: contain;
        top: 0;
        &.addon {
          top: 30px
        }
      }

    }
  }
  @media only screen and (max-width: 1200px) {
    &.prenatal-pdp {
      .modal-content .modal-container {
        .content-right {
          padding-right: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 !important;
    .modal-content {
      .modal-container {
        .brand {
          padding: 20px 0;
          &-icon {
            left: 15px;
            img {
              width: 20px;
            }
            span {
              font-size: 15px;
              letter-spacing: 0.4px;
            }
          }
          &-title {
            padding: 0 12px;
            img {
              width: 93px;
            }
          }
        }
        .content {
          flex-direction: column-reverse;
          &-left {
            width: 100%;
            &-title {
              font-size: 28px;
              line-height: 30px;
            }
            &-subTitle {
              font-size: 18px;
              line-height: 26px;
            }
            &-desc {
              font-size: 16px;
              line-height: 20px;
              margin-top: 35px;
            }
            &-tag {
              .tag-list {
                padding: 26px 0 0 0;
                justify-content: flex-start;
                .tag-item {
                  width: 20%;
                  padding-bottom: 20px;
                  img {
                    width: 80%;
                    height: inherit;
                  }
                }
                &-text {
                  font-size: 14px;
                  letter-spacing: 0.3px;
                  line-height: 21px;
                }
              }
            }
          }
          &-right {
            width: 100px;
            height: 100px;
            &-picture {
              display: block;
              max-width: 100%;
              max-height: 100%;
              position: static;
            }
          }
        }
      }
      .recommend-title {
        padding: 18px 25px;
        font-size: 18px;
        line-height: 26px;
      }
      .recommend-content {
        padding: 0;
        &-item {
          padding: 40px 0px;
          &-header {
            &-img {
              width: 40px;
              height: 40px;
            }
            &-title {
              font-size: 22px;
              line-height: 27px;
            }
          }
          &-desc {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      /* why-we-love */
      .why-we-love {
        padding: 40px 25px;
        .title {
          font-size: 28px;
          line-height: 30px;
          padding-bottom: 20px;
        }
        .desc {
          font-size: 16px;
          line-height: 20px;
        }
        .list li {
          padding: 5px 20px 20px 40px;
          font-size: 18px;
        }
      }
      /* Supplement Facts */
      .supplement-n-ingredients {
        .accordion {
          .accordion-content.Labels {
            border: 0;
          }
          .accordion-title {
            font-size: 28px;
            // line-height: 30px;
            padding-bottom: 50px;
          }
          .accordion-footer {
            display: none;
          }
        }
        padding: 0 25px;
        .border {
          border-bottom: 1px solid black;
          border-top: 1px solid black;
        }
        .warning {
          border-bottom: 0;
          padding-bottom: 30px;
          .title {
            font-family: Verlag-Book;
            font-size: 24px;
            color: #000000;
            line-height: 31px;
            padding: 15px 0;
          }
          .item {
            font-size: 16px;
            color: #3e3e3e;
            letter-spacing: 0.3px;
            line-height: 21px;
            margin: 10px 0;
          }
        }
      }
      .table-container {
        font-size: 12px;
      }
      /* supplement-facts */
      .supplement-facts {
        color: #000;
        /* Fallback color for older browsers */
        color: #3e3e3e;
        text-align: left;
        th:nth-child(2) {
          color: black;
          text-align: center;
          white-space: nowrap;
        }
        th:nth-child(3) {
          color: black;
          text-align: right;
          white-space: nowrap;
        }
        td:nth-child(2) {
          text-align: center;
          white-space: nowrap;
        }
        td:nth-child(3) {
          text-align: right;
          white-space: nowrap;
        }
      }
      /* disclaimer */
      .disclaimer {
        display: flex;
        justify-content: center;
        margin: 40px;
        p {
          text-align: justify;
          margin: 10px;
          padding: 20px;
          background-color: #ededed;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    // Meology Kids product detail pages
    &.kids-pdp {
      &-pill {
        .modal-content {
          .modal-body {
            &.pill-1 {
              .content {
                background: url('https://images.shaklee.com/meology/images/OmegaGelly_PDP_mobile.png')
                  0 -20px no-repeat #ffecb5;
                background-size: cover;
              }
            }
            &.pill-2 {
              .content {
                background: url('https://images.shaklee.com/meology/images/SuperVitamin_PDP_mobile.png')
                  0 -20px no-repeat #fde2e2;
                background-size: cover;
              }
            }
            &.pill-3 {
              .content {
                background: url('https://images.shaklee.com/meology/marketing/kids/Immunity_PDP_mobile.png')
                  0 -20px no-repeat #eee7f0;
                background-size: cover;
              }
            }
            .modal-container {
              .content {
                height: auto;
                background-size: contain;
                padding: 0;
                &-inner {
                  padding: 200px 25px 20px !important;

                  &:before {
                    top: 32px;
                    width: 230px;
                    height: 129px;
                    transform: translateX(-115px);
                  }
                }

                &-right {
                  position: static;
                  flex-basis: 0;
                  width: 0;
                  height: 0;

                  // bg hands
                  &:before {
                    width: 280px;
                    height: 162px;
                    top: 0;
                    right: 0;
                    left: inherit;
                    transform: translateX(40px);
                  }

                  // bg cherry
                  &:after {
                    right: 150px;
                    width: 109px;
                    height: 110px;
                    transform: translate(-10%, -110%);
                  }
                }
              }
            }
          }
        }
      }

      .modal-content {
        .modal-container {
          .brand {
            &-icon {
              line-height: 55px;
            }
            &-title {
              img {
                width: 110px;
              }
            }
          }
          .content {
            padding: 0 10% 5px 10%;
            height: 200px;
            .content-inner {
              padding: 0;
            }
          }
        }
      }
    }
    &.prenatal-pdp {
      .modal-content .modal-container {
        .content {
          padding-right: 10%;
        }
        .content-inner{
          flex-flow:column ;
          &.rsw {
            flex-flow: column-reverse;

            .content-right {
              margin-bottom: 0;
            }

            .content-left {
              padding-top: 0;
              
              .content-left-subTitle {
                padding-bottom: 16px;
              }
            }
          } 
          .content-left {
            padding-top: 30px;
            .content-left-subTitle{
              padding-bottom: 0;
            }
          }                
          .content-right {
            width: 100%;
            height: 100%;
            margin-top: 0;

            .content-right-picture {
              margin: 0 auto;
              max-width: 375px;
              height: 100%;
              width: 100%;
              top: 0
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1024px) {
    .modal-content {
      .modal-container {
        .content {
          padding: 0 10% 5px 10%;
        }

        .recommend-title {
          padding: 22px 10%;
        }

        .recommend-content {
          padding: 0 10% 84px 10%;
        }
      }

      .why-we-love {
        padding: 62px 10%;
      }

      .supplement-n-ingredients {
        padding: 0 10% 50px 10%;
      }
    }
  }
}
