.provelt-modal {
  font-family: 'Gilmer-Regular', sans-serif;

  .modal-dialog {
    height: auto;

    .modal-content {
      padding: 50px;
      align-items: center;
      box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #f9f7f5;
    }
  }

  &-title {
    font-family: 'SchnyderS-Bold';
    font-size: 42px;
    width: 100%;
    text-align: center;
  }

  &-intro {
    font-size: 18px;
    width: 1164px;
    padding: 10px 0;
    text-align: center;
    color: #1e1e1e;
  }

  .item-container-title1,.item-container-title2{
    font-size: 30px;
    letter-spacing: 0.67px;
    line-height: 50px;
    border-bottom: solid 2px #2e2e2e;
    text-align: left;
    width: 1148px;
    margin-bottom: 30px;
	img {
		margin-right: 20px;
	}
  }
  .item-container-title2 {
	margin-top: 80px;
  }
  .item-container {
    display: flex;
    flex-wrap: wrap;
    width: 1164px;
    .provelt-modal-item {
      width: 372px;
      border-radius: 5px;
      border: solid 1px rgba(151, 151, 151, 0.33);
      margin: 8px;
      text-align: center;
      padding: 35px;
      &-name {
        font-family: 'SchnyderS-Bold';
        font-size: 28px;
        height: 126px;
      }
      &-why {
        .product-attributes-list {
          margin: 0;
          padding: 16px 0 20px 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          &-item {
            padding-right: 8px;
            img {
              width: 40px;
              height: 40px;
              display: block;
            }
          }
        }
      }
      &-picture {
        width: 100%;
      }

      &-desc-title {
        font-size: 14px;
        font-family: 'Gilmer-Medium';
      }

      &-desc {
        font-size: 14px;
        font-family: 'Gilmer-Regular';
        margin-top: 10px;
        p {
          a {
            color: #000;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .provelt-modal {
    font-family: 'Gilmer-Regular', sans-serif;

    .modal-dialog {
      padding: 0;
    }

    &-intro {
      width: 100%;
    }

    &-img {
      margin-bottom: 20px;
    }
    .item-container-title1,.item-container-title2 {
      width: 100%;
    }
    .item-container {
      width: 100%;
      .provelt-modal-item {
        width: calc(100% / 3 - 16px);
        &-why {
          .product-attributes-list {
            margin: 0;
            padding: 16px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .provelt-modal {
    font-family: 'Gilmer-Regular', sans-serif;

    .modal-dialog {
      padding: 0;

      .modal-content {
        padding: 60px 10px;
      }
    }

    &-intro {
      width: 100%;
    }

    &-img {
      margin-bottom: 20px;
    }
    .item-container-title1,.item-container-title2{
      width: 100%;
    }
    .item-container {
      width: 100%;
      .provelt-modal-item {
        width: 100%;
        margin: 8px 0;
        .provelt-modal-item {
          &-name {
            height: initial;
          }
        }

        &-why {
          .product-attributes-list {
            margin: 0;
            padding: 16px 0;
          }
        }
      }
    }
  }
}
