.kids-sachet {
  $root: &;
  background-image: url('https://images.shaklee.com/meology/images/Meology_Sachet_Blank_NoBG-1.png?v=1');
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 250px;
  height: 292px;
  margin: 0 5px;
  flex-shrink: 0;
  padding: 20px 0 10px 10px;
  position: relative;

  &-wrapper {
    padding: 35px 35px 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-name {
    font-size: 28px;
    color: #1e1e1e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 55%;
  }

  &-icon {
    width: 38%;
    height: 38%;
  }

  &-logo {
    width: 38%;
  }

  &-indicator {
    @include kids-indicator();
    border: 0;

    #{$root}.show-indicator & {
      visibility: visible;
    }
  }
}

// mobile
@media (max-width: 768px) {
  .kids-sachet {
    width: 168px;
    height: 197px;
    margin: 0;

    &-wrapper {
      padding: 5px;
    }

    &-name {
      font-size: 18px;
    }
  }
}
